import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import localization, { type LT } from '#utils/localization'
import { logout } from '#utils/token'
import { execFunc } from '#utils/functions'
import { Routes } from '#utils/router'
import useFetchState from '#repositories/models'
import { setPassword } from '#repositories/apis/user'
import { Language, Profile, SignInPopup, SignInPopupType } from '#repositories/models/recoil-state'
import { MessageType, MMessage } from '#components/hint'
import Icon from '#components/icon'
import Logo from '#components/logo'
import { passwordRule, type SetPasswordFieldType, rePasswordRule, wrapperCol, type SignInProps } from './model'
import { ReactComponent as Lock } from '#assets/svg/lock.svg'

const { Item } = Form

const Main: React.FC<SignInProps> = ({ popup, callback }) => {
    const profile = new Profile()
    const signInPopup = new SignInPopup()
    const history = useHistory()
    const { key } = useParams<{ key: string }>()

    const lang = new Language()
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // 本地化
    const [ LangDict, setLangDict ] = useFetchState<LT>({})
    // 是否显示图形验证码

    const submit = (values: SetPasswordFieldType): void => {
        setLoading(true)
        void setPassword<any>({
            password: values.password,
            key: decodeURIComponent(key)
        }).then(
            () => {
                MMessage({
                    message: LangDict.setPasswordSuccess,
                    type: MessageType.success
                })

                logout({ locationSign: false })
                profile.set(null)
                execFunc(callback)
                if (popup) {
                    signInPopup.set(SignInPopupType.login)
                } else {
                    history.push(Routes.homeLogin)
                }
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'setPassword',
                        'pwdPlaceholder',
                        'passwordFormRuleMsg',
                        'rePasswordFormRuleMsg',
                        'confirm',
                        'setPasswordSuccess',
                        'rePwdPlaceholder'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <div className={ popup ? 'popup-center-box' : 'wh100 relative' }>
        <div className={ 'fade-in signin-box' + (popup ? '' : ' abs-center') }>
            <Logo />
            <Form
                name="basic"
                initialValues={ { remember: true } }
                onFinish={ submit }
                autoComplete="on"
            >
                <h3 className="font-center header">{ LangDict.setPassword }</h3>
                <Item<SetPasswordFieldType>
                    name="password"
                    rules={ passwordRule(LangDict.passwordFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input.Password
                        className="password"
                        prefix={ <Icon><Lock /></Icon> }
                        visibilityToggle={ { visible: false } }
                        placeholder={ LangDict.pwdPlaceholder }
                    />
                </Item>
                <Item<SetPasswordFieldType>
                    name="rePassword"
                    rules={ rePasswordRule(LangDict.rePasswordFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input.Password
                        className="password"
                        prefix={ <Icon><Lock /></Icon> }
                        visibilityToggle={ { visible: false } }
                        placeholder={ LangDict.rePwdPlaceholder }
                    />
                </Item>
                <Item wrapperCol={ wrapperCol } className="mb0">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="submit background-button"
                        loading={ loading }
                    >{ LangDict.confirm }</Button>
                </Item>
            </Form>
        </div>
    </div>
}

export default Main