import React from 'react'
import { Col, Row } from 'antd'
import { ReactComponent as ArrowLeft } from '#assets/svg/arrow-left.svg'
import { type LT, pickSevLangKey } from '#utils/localization'
import { copyToClipboard, extractUrl } from '#utils/functions'
import { type NetworkDisk } from '#repositories/apis/orders'
import { type Language, NetworkDiskMapConfig } from '#repositories/models/recoil-state'
import Icon from '#components/icon'
import ConstraintBox from '#components/constraint-box'
import { MessageType, MMessage } from '#components/hint'
import Copy from '#components/copy'
import Location from '#components/location'

interface MainProps {
    networkDisk: NetworkDisk[]
    callback?: () => void
    langDict: LT
    title?: string
    lang: Language
    simple?: boolean
}

const Main: React.FC<MainProps> = (
    {
        networkDisk,
        callback,
        langDict,
        title,
        simple,
        lang
    }
) => {
    const networkDiskMapConfig = new NetworkDiskMapConfig()

    return <div className="box">
        {
            simple === true
                ? ''
                : <>
                    <div className="cursor-pointer go-back" onClick={ callback }>
                        <Icon tap className="i-4x"><ArrowLeft /></Icon>
                        <span className="font">{ langDict.goBack }</span>
                    </div>
                    <Row className="item">
                        <Col span={ 2 }></Col>
                        <Col span={ 20 } className="content">{ title }</Col>
                    </Row>
                </>
        }
        <Row className="item">
            {
                simple === true
                    ? ''
                    : <Col span={ 2 }></Col>
            }
            <Col span={ simple === true ? 24 : 20 } className="content network-disk">
                {
                    networkDisk.map(
                        item => ({
                            ...item,
                            url: item.url,
                            code: item.code.replace(
                                '解压码', ''
                            ).replace(
                                '激活码', ''
                            ).replace(
                                '：', ''
                            ),
                            decryption: item.decryption.replace(
                                '解压码', ''
                            ).replace(
                                '激活码', ''
                            ).replace(
                                '：', ''
                            )
                        })
                    ).map(
                        (item, key) => <ConstraintBox
                            key={ key }
                            title={ pickSevLangKey(lang.state, (networkDiskMapConfig.state[ item.type ])) }
                        >
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.networkDiskTitle }:</Col>
                                <Col span={ 20 } className="content cursor-pointer">
                                    <Location to={ extractUrl(item.url) } target="_blank" className="blue">
                                        { extractUrl(item.url) }
                                    </Location>
                                </Col>
                            </Row>
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.networkCodeTitle }:</Col>
                                <Col
                                    span={ 20 }
                                    className="content cursor-pointer"
                                    onClick={
                                        () => {
                                            copyToClipboard(item.code)
                                            MMessage({
                                                message: langDict.copySuccess,
                                                type: MessageType.success
                                            })
                                        }
                                    }
                                ><Copy text={ langDict.clickToCopy }>{ item.code }</Copy></Col>
                            </Row>
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.installDecompressionCodeTitle }:</Col>
                                <Col
                                    span={ 20 }
                                    className="content cursor-pointer"
                                    onClick={
                                        () => {
                                            copyToClipboard(item.decryption)
                                            MMessage({
                                                message: langDict.copySuccess,
                                                type: MessageType.success
                                            })
                                        }
                                    }
                                ><Copy text={ langDict.clickToCopy }>{ item.decryption }</Copy></Col>
                            </Row>
                        </ConstraintBox>
                    )
                }
            </Col>
        </Row>
    </div>
}

export default Main