import React from 'react'
import { Carousel } from 'antd'
import { type Notice } from '#repositories/apis/base'
import { pickLangKey } from '#utils/localization'
import { Language } from '#repositories/models/recoil-state'
import { ReactComponent as Broadcast } from '#assets/svg/broadcast.svg'
import Icon from '#components/icon'
import Location from '#components/location'

interface MainProps {
    list: Notice[]
}

const Main: React.FC<MainProps> = ({ list }) => {
    const lang = new Language()

    return <>
        <div className="notice-icon-box">
            <Icon className="notice-icon i-3x"><Broadcast /></Icon>
            <span>网站动态</span>
        </div>
        <div className="notice-carousel">
            <Carousel effect="fade" autoplay dots={ false }>{
                list.map(
                    (item, key) => <Location
                        to={ item.url }
                        target={ item.target }
                        key={ key }
                        className="ellipsis-1 item"
                        title={ pickLangKey(lang.state, item.content) }
                    >{ pickLangKey(lang.state, item.content) }</Location>
                )
            }</Carousel>
        </div>
    </>
}

export default Main
