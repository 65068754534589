import Icon from '#components/icon'
import React, { type ReactElement, useEffect } from 'react'
import { Profile, SignInPopupVisible } from '#repositories/models/recoil-state'
import { ReactComponent as IconThumbsUp } from '#assets/svg/thumbs-up.svg'
import { ReactComponent as IconStar } from '#assets/svg/star.svg'
import { ReactComponent as Reload } from '#assets/svg/reload.svg'
import useFetchState from '#repositories/models'
import { type ListItem, setColumn } from '#repositories/apis/documents'
import { execFunc, isEmpty, stopDefaultEvent } from '#utils/functions'

export enum ColumnType {
    likes = 'likes',
    favorites = 'favorites'
}

export interface ParamsProps {
    text?: ReactElement
    type: ColumnType
    item: ListItem
    className?: string
    callback: (val: number[]) => void
    readonly?: boolean
}

export const BehaviorComponent: React.FC<ParamsProps> = ({ readonly, item, callback, type, text, className }) => {
    const profile = new Profile()
    const signInVisible = new SignInPopupVisible()
    const [ loading, setLoading ] = useFetchState(false)
    const [ active, setActive ] = useFetchState(false)
    const _className = className !== undefined && !isEmpty(className) ? (' ' + className) : ''
    const loadingParams = {
        className: 'i-2x rotating-ele'
    }
    const defParams = {
        tap: true,
        onClick: (e: Event) => {
            stopDefaultEvent(e)
            if (profile.state === null) {
                signInVisible.set(true)
                return
            }

            setLoading(true)
            setParams(loadingParams)
            let data = 1
            let idList: number[] = []
            let active = true
            if (type === ColumnType.favorites) {
                if (item.extension.favorites !== null) {
                    if (item.extension.favorites.some(v => v === profile.state.id)) {
                        // 取消
                        data = 0
                        item.extension.favorites = item.extension.favorites.filter(
                            item => profile.state.id !== item
                        )
                        active = false
                    } else {
                        item.extension.favorites.push(profile.state.id)
                    }
                } else {
                    item.extension.favorites = []
                }

                idList = item.extension.favorites
            } else {
                if (item.extension.likes !== null) {
                    if (item.extension.likes.some(v => v === profile.state.id)) {
                        // 取消
                        data = 0
                        item.extension.likes = item.extension.likes.filter(
                            item => profile.state.id !== item
                        )
                        active = false
                    } else {
                        item.extension.likes.push(profile.state.id)
                    }
                } else {
                    item.extension.likes = []
                }

                idList = item.extension.likes
            }

            void setColumn(type, data, item.uniqueId).then(
                () => {
                    execFunc(callback, idList)
                }
            ).finally(
                () => {
                    setTimeout(
                        () => {
                            setLoading(false)
                            setParams(defParams)
                            setActive(active)
                        },
                        1000
                    )
                }
            )
        },
        className: 'i-2x'
    }
    if (readonly !== undefined && readonly) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        defParams.onClick = undefined
    }

    const [ params, setParams ] = useFetchState<Record<string, any>>(defParams)
    const setActiveFunc = (): void => {
        if (profile.state === null) {
            return
        }

        const favorites = isEmpty(item.extension.favorites) ? [] : item.extension.favorites
        const likes = isEmpty(item.extension.likes) ? [] : item.extension.likes
        let active = false
        if (type === ColumnType.favorites && favorites.some(v => v === profile.state.id)) {
            active = true
        }

        if (type === ColumnType.likes && likes.some(v => v === profile.state.id)) {
            active = true
        }

        setActive(active)
    }

    useEffect(
        setActiveFunc,
        [ item.extension ]
    )

    return <>{
        text === undefined
            ? <Icon { ...{ ...params, className: params.className + (active ? ' active' : '') } }>
                { loading ? <Reload /> : <>{ type === ColumnType.favorites ? <IconStar /> : <IconThumbsUp /> }</> }
            </Icon>
            : <span
                className={ `flex${ params.onClick === undefined ? '' : ' cursor-pointer' }${ _className }` }
                onClick={
                    e => {
                        execFunc(params.onClick, e)
                    }
                }
            >
                { text }
                <Icon { ...{ ...params, className: params.className + (active ? ' active' : '') } }>
                    { loading ? <Reload /> : <>{ type === ColumnType.favorites ? <IconStar /> : <IconThumbsUp /> }</> }
                </Icon>
            </span>
    }</>
}