import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Divider, Row } from 'antd'
import Location from '#components/location'
import Image from '#components/image'
import Icon, { IconText } from '#components/icon'
import { BehaviorComponent, ColumnType } from '#components/behavior'
import { Alert, Confirm, MessageType, MMessage } from '#components/hint'
import { execFunc, isEmpty, numberFormat, timestampFormat } from '#utils/functions'
import localization, { type LT, pickLangKey, pickSevLangKey } from '#utils/localization'
import { placeholder, placeholder1 } from '#utils/localization/dictionary'
import { orderConfirmationRoutePath, productDetailRoutePath } from '#utils/router'
import { documentDownloadLimit, type ListItem } from '#repositories/apis/documents'
import useFetchState from '#repositories/models'
import { AllGameClass, Configs, Language, Profile, SignInPopupVisible, UserLevelConfig } from '#repositories/models/recoil-state'
import { ReactComponent as IconTrendingUp } from '#assets/svg/trending-up.svg'
import { ReactComponent as IconEye } from '#assets/svg/eye.svg'
import { ReactComponent as IconClock } from '#assets/svg/clock.svg'
import { dateFormatYmd, pickColor, pickUserLevelType, UserLevelType, variables } from '#constants/appoint'

const highlightKeywords = (originalString: string, keyword: string): string => {
    const keywords = keyword.split('')
    return originalString.split('').map(
        item => keywords.some(v => v === item) ? `<span class="highlight">${ item }</span>` : item
    ).join('')

    // let highlightedString = originalString
    // keyword.split('').forEach(
    //     item => {
    //         highlightedString = highlightedString.replace(
    //             // new RegExp(item, 'gi'),
    //             /<span[^>]*>([^>]*)<\/b[^>]*>/ig,
    //             // new RegExp(`([^<span]*)(${ item })([^>]*)(?![^<]*>)`, 'gi'),
    //             match => `<span class="highlight">${ match }</span>`
    //         )
    //     }
    // )
    //
    // return highlightedString
}

interface Layout {
    left: number
    right: number
}

interface MainProps {
    setVisible?: (val: boolean) => void
    item: ListItem
    layout: Layout
    className?: string
    hideDate?: boolean
    hideDivider?: boolean
    keyword?: string
}

const Main: React.FC<MainProps> = (
    {
        setVisible,
        item,
        layout,
        hideDate,
        className,
        hideDivider,
        keyword
    }
) => {
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ data, setData ] = useFetchState<ListItem | null>(null)

    useEffect(
        () => {
            setData(item)
        },
        []
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'published',
                        'vipExclusive',
                        'permanentExclusive',
                        'freeExclusive'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return data === null
        ? <></>
        : <Row
            key={ item.uniqueId }
            className={ 'list-item' + (className !== undefined ? ' ' + className : '') }
        >
            <Col span={ layout.left } className="flex-center featured-image">
                {
                    item.exclusiveType === UserLevelType.free && item.price > 0
                        ? ''
                        : <div className="exclusive-type">
                            {
                                item.exclusiveType === UserLevelType.pro
                                    ? langDict.vipExclusive
                                    : (
                                        item.exclusiveType === UserLevelType.permanent
                                            ? langDict.permanentExclusive
                                            : (item.price <= 0 ? langDict.freeExclusive : '')
                                    )
                            }
                        </div>
                }
                <Location onClick={ (): void => { execFunc(setVisible, false) } } className="wh100" to={ productDetailRoutePath(item.uniqueId) }>
                    <Image product={ true } className="fill" src={ item.featuredImage } alt="" />
                </Location>
            </Col>
            <Col span={ layout.right } className="detail">
                <p className="title">
                    <Location onClick={ (): void => { execFunc(setVisible, false) } } className="w100" to={ productDetailRoutePath(item.uniqueId) } title={ item.title }>
                        <span className="ellipsis-1" dangerouslySetInnerHTML={{ __html: highlightKeywords(item.title.replace(' ', ''), keyword ?? '') }} />
                    </Location>
                </p>
                <div className="controls">
                    { hideDate !== undefined && hideDate ? '' : <div className="createdAt">{ langDict.published }: { timestampFormat(item.createdAt, dateFormatYmd) }</div> }
                    <BehaviorComponent
                        className="row-reverse sp"
                        text={ <span>{ numberFormat(item.extension.favorites.length) }</span> }
                        type={ ColumnType.favorites }
                        item={ item }
                        callback={
                            val => {
                                setData({
                                    ...data,
                                    extension: { ...data.extension, favorites: val }
                                })
                            }
                        }
                    />
                    <BehaviorComponent
                        className="row-reverse sp"
                        text={ <span>{ numberFormat(item.extension.likes.length) }</span> }
                        type={ ColumnType.likes }
                        item={ item }
                        callback={
                            val => {
                                setData({
                                    ...data,
                                    extension: { ...data.extension, likes: val }
                                })
                            }
                        }
                    />
                    <IconText icon={ <Icon tap><IconTrendingUp /></Icon> } text={ numberFormat(item.extension.views) } />
                    <IconText icon={ <Icon tap><IconEye /></Icon> } text={ numberFormat(item.extension.sales) } />
                </div>
            </Col>
            {
                hideDivider !== undefined && hideDivider
                    ? ''
                    : <Col span={ 24 }>
                        <div className="divider" />
                    </Col>
            }
        </Row>
}

export default Main

export enum ProductItemSize {
    small
}

interface ProductItemProps {
    setVisible?: (val: boolean) => void
    item: ListItem
    className?: string
    keyword?: string
    hideFooter?: boolean
    size?: ProductItemSize
}

export const ProductItem: React.FC<ProductItemProps> = (
    {
        setVisible,
        item,
        className,
        hideFooter,
        keyword,
        size
    }
) => {
    const lang = new Language()
    const ref = useRef(null)
    const [ data, setData ] = useFetchState<ListItem>(item)
    // 游戏分类
    const gameClass = new AllGameClass()
    const gameClassMaps = gameClass.shared()
    const realLabels = (labelIds: number[]): number[] => {
        return labelIds.filter(
            item => !isEmpty(gameClassMaps[ item ])
        )
    }
    const labelIds = realLabels(data.labelIds)
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    let createdAt = ''
    if (item !== null) {
        if (size === ProductItemSize.small) {
            const date = new Date(item.createdAt * 1000)
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            createdAt = `${ month }-${ day }`
        } else {
            createdAt = timestampFormat(item.createdAt, dateFormatYmd)
        }
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'headerMenuAllGames',
                        'permanentExclusive',
                        'downloadLimitTitle',
                        'freeExclusive'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return item === null
        ? ''
        : <Location
            target="_blank"
            onClick={ (): void => { execFunc(setVisible, false) } }
            to={ productDetailRoutePath(item.uniqueId) }
            className={ `old item cursor-pointer${ className !== undefined ? ' ' + className : '' }` }
        >
            <div className="w100 featured-image-box" ref={ ref }><Image product={ true } className="fill" src={ item.featuredImage } /></div>
            <div className="detail">
                <div className="labels flex">
                    <span>
                        <em style={ { background: pickColor(0) } } />
                        { langDict.headerMenuAllGames }
                    </span>
                    {
                        labelIds.filter(
                            (item, index) => !(data.exclusiveType === UserLevelType.permanent && index !== 0)
                        ).map(
                            (item, key) => isEmpty(gameClassMaps[ item ])
                                ? ''
                                : <span key={ key }>
                                    <em style={ { background: pickColor(key + 1) } } />
                                    { pickLangKey(lang.state, gameClassMaps[ item ].title ?? {}) }
                                </span>
                        )
                    }
                    {
                        data.exclusiveType === UserLevelType.permanent
                            ? <span>
                                <em style={ { background: pickColor(labelIds.length + 1) } } />
                                { langDict.permanentExclusive }
                            </span>
                            : ''
                    }
                </div>
                <p className="title ellipsis-1" dangerouslySetInnerHTML={{ __html: highlightKeywords(item.title.replace(' ', ''), keyword ?? '') }} />
            </div>
            {
                hideFooter === true
                    ? ''
                    : <>
                        <Divider className="divider" plain />
                        <div className="label">
                            <BehaviorComponent
                                className="row-reverse sp"
                                text={ <span>{ numberFormat(item.extension.favorites.length) }</span> }
                                type={ ColumnType.favorites }
                                item={ item }
                                readonly={ true }
                                callback={
                                    val => {
                                        setData({
                                            ...data,
                                            extension: { ...data.extension, favorites: val }
                                        })
                                    }
                                }
                            />
                            <IconText icon={ <Icon tap><IconEye /></Icon> } text={ numberFormat(item.extension.views) } />
                            <IconText
                                className="createdAt-box"
                                icon={ <Icon tap><IconClock /></Icon> }
                                text={ <div className="createdAt">{ createdAt }</div> }
                            />
                        </div>
                    </>
            }
        </Location>
}

interface ProductRowBuyButtonPropsType {
    className: string
    uniqueId: string
    price: number
    exclusiveType: UserLevelType
    userLevel: UserLevelType
    // 是否可以免登录购买
    guestCheckout: boolean
    // 订单数量
    orderCnt: number
}

export const ProductRowBuyButton: React.FC<ProductRowBuyButtonPropsType> = (
    {
        className,
        uniqueId,
        price,
        exclusiveType,
        userLevel,
        guestCheckout,
        orderCnt
    }
) => {
    const userLevelConfig = new UserLevelConfig()
    const configs = new Configs()
    const history = useHistory()
    const profile = new Profile()
    const lang = new Language()
    const downloadLimitState = configs.shared()?.downloadLimitState ?? false
    const externalLinkState = configs.shared()?.externalLinkState ?? false
    const externalLinkGoods = configs.shared()?.externalLinkGoods ?? ''
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const signInVisible = new SignInPopupVisible()
    const login = (): void => {
        variables.orderPreConfirmationId = uniqueId
        signInVisible.set(true, _ => {
            MMessage({
                message: langDict.loginSuccessWidth,
                type: MessageType.success
            })
        })
    }
    const params = {
        className,
        onClick: () => {
            variables.orderPreConfirmationId = uniqueId
            history.push(orderConfirmationRoutePath(uniqueId))
        }
    }

    const upgradeParams = {
        className,
        onClick: () => {
            variables.orderPreConfirmationId = uniqueId
            Alert({
                width: 300,
                message: langDict.membershipRestrictionTips
            })
            // history.push(upgradeRoutePath())
        }
    }

    const download = (): void => {
        // 检测下载次数
        if (downloadLimitState) {
            void documentDownloadLimit().then(
                res => {
                    const state = res.data as boolean
                    if (state) {
                        variables.orderPreConfirmationId = uniqueId
                        history.push(orderConfirmationRoutePath(uniqueId))
                        return
                    }

                    let num = configs.state.downloadLimitFree
                    if (userLevel === UserLevelType.pro) {
                        num = configs.state.downloadLimitVip
                    } else if (userLevel === UserLevelType.permanent) {
                        num = configs.state.downloadLimitPermanent
                    }

                    Confirm({
                        title: langDict.downloadLimitTitle,
                        content: langDict.downloadLimit.replace(
                            placeholder,
                            pickSevLangKey(lang.state, userLevelConfig.state[ pickUserLevelType(userLevel) ])
                        ).replace(placeholder1, String(num ?? 0)),
                        success() {
                            variables.orderPreConfirmationId = uniqueId
                            history.push(orderConfirmationRoutePath(uniqueId))
                        }
                    })
                }
            )
            return
        }

        variables.orderPreConfirmationId = uniqueId
        history.push(orderConfirmationRoutePath(uniqueId))
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'downloadGame',
                        'downloadLimit',
                        'vipExclusive',
                        'permanentExclusive',
                        'loginWithFreeGet',
                        'guestCheckoutWithBuy',
                        'loginSuccessWidth',
                        'membershipRestrictionTips',
                        'buy'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    // 已购买
    if (orderCnt > 0) {
        return <div { ...params }>{ langDict.downloadGame }</div>
    }

    // 限定游戏
    if (exclusiveType === UserLevelType.permanent) {
        if (userLevel === UserLevelType.permanent) {
            // 免费下载
            return <div { ...params } onClick={ download }>{ langDict.downloadGame }</div>
        }
        // 显示限定游戏
        return <div { ...upgradeParams }>{ langDict.permanentExclusive }</div>
    }

    // vip限定游戏
    if (exclusiveType === UserLevelType.pro) {
        if (userLevel === UserLevelType.pro) {
            // 免费下载
            return <div { ...params } onClick={ download }>{ langDict.downloadGame }</div>
        }
        // 显示vip限定
        return <div { ...upgradeParams }>{ langDict.vipExclusive }</div>
    }

    if (profile.state === null) {
        if (guestCheckout && price > 0) {
            // 免登录购买
            return <div { ...params }>{ langDict.guestCheckoutWithBuy }</div>
        }

        // 登录后免费获取
        return <div className={ className } onClick={ login }>{ langDict.loginWithFreeGet }</div>
    }

    // 已登录后免费获取
    if (price <= 0) {
        return <div { ...params } onClick={ download }>{ langDict.downloadGame }</div>
    }

    // 会员免费
    if (userLevel === UserLevelType.permanent || userLevel === UserLevelType.pro) {
        return <div { ...params } onClick={ download }>{ langDict.downloadGame }</div>
    }

    return <>
        <div { ...params }>{ langDict.buy }</div>
        {
            externalLinkState && externalLinkGoods !== ''
                // ? <a href={ externalLinkGoods } target="_blank" className="buy-button-link font-center">平台购买</a>
                ? <Location to={ externalLinkGoods } className="buy-button-link font-center" target="_blank">平台购买</Location>
                : ''
        }
    </>
}
