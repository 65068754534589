import Wallet from '#pages/profile/wallet'
import GameBox from '#pages/profile/game-box'
import ExpenseRecords from '#pages/profile/expense-records'
import Membership from '#pages/profile/membership'
import PurchaseHistory from '#pages/profile/purchase-history'
import Favorites from '#pages/profile/favorites'
import Information from '#pages/profile/information'
import AccountBinding from '#pages/profile/account-binding'
import UnbindingCode from '#pages/profile/unbinding-code'
import PasswordSettings from '#pages/profile/password-settings'
import Withdraw from '#pages/profile/withdraw'
import Substation from '#pages/profile/substation'
import ActivationCodes from '#pages/profile/activation-codes'
import { type RouteConfig } from 'react-router-config'
import { RouteProfile } from '#utils/router'

const routes: RouteConfig = [
    {
        path: RouteProfile.expenseRecords,
        exact: true,
        component: ExpenseRecords
    },
    {
        path: RouteProfile.wallet + '/:page?/:limit?/:id?',
        exact: true,
        component: Wallet
    },
    {
        path: RouteProfile.membership,
        exact: true,
        component: Membership
    },
    {
        path: RouteProfile.gameBox + '/:page?/:limit?/:uniqueId?',
        exact: true,
        component: GameBox
    },
    {
        path: RouteProfile.purchaseHistory + '/:page?/:limit?/:uniqueId?',
        exact: true,
        component: PurchaseHistory
    },
    {
        path: RouteProfile.favorites + '/:page?/:limit?/:uniqueId?',
        exact: true,
        component: Favorites
    },
    {
        path: RouteProfile.information,
        exact: true,
        component: Information
    },
    {
        path: RouteProfile.unbindingCode,
        exact: true,
        component: UnbindingCode
    },
    {
        path: RouteProfile.accountBinding,
        exact: true,
        component: AccountBinding
    },
    {
        path: RouteProfile.passwordSettings,
        exact: true,
        component: PasswordSettings
    },
    {
        path: RouteProfile.withdraw + '/:page?/:limit?/:uniqueId?',
        exact: true,
        component: Withdraw
    },
    {
        path: RouteProfile.substation,
        exact: true,
        component: Substation
    },
    {
        path: RouteProfile.activationCodes,
        exact: true,
        component: ActivationCodes
    },
    {
        path: '*',
        component: Information
    }
]

export default routes
