import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'
import { renderRoutes, type RouteConfig } from 'react-router-config'
import { Layout, ConfigProvider } from 'antd'
import enUS from 'antd/locale/en_US'
import zhCN from 'antd/locale/zh_CN'
import { conf } from '#utils/configs'
import { isEmpty, md5Str } from '#utils/functions'
import { ProductLabelTypes, variables } from '#constants/appoint'
import { Modals, type ModalsProps } from '#components/hint'
import SitePopupTmp from '#components/site-popup'
import { AllGameClass, Configs, Dictionary, GameClass, Language, NetworkDiskMapConfig, SignInPopupVisible, SitePopup, UserLevelConfig } from '#repositories/models/recoil-state'
import { type Dictionaries, dictionaries as dictionariesApi, type DictionaryItem } from '#repositories/apis/dictionaries'
import { type SevConfLangMaps } from '#repositories/types/lang'
import useFetchState from '#repositories/models'
import Header from './header'
import Footer from './footer'
import { SitePopupRule } from '#repositories/apis/base'

interface MainProps {
    routes: RouteConfig[]
}

const Main: React.FC<MainProps> = ({ routes }) => {
    const signInVisible = new SignInPopupVisible()
    const sitePopup = new SitePopup()
    const sitePopupState = sitePopup.shared()
    const languageState = new Language()
    const configs = new Configs()
    const dictionary = new Dictionary()
    const gameClass = new GameClass()
    const allGameClass = new AllGameClass()
    const userLevel = new UserLevelConfig()
    const networkDiskMapConfig = new NetworkDiskMapConfig()
    const [ popupVisible, setPopupVisible ] = useFetchState(false)
    const popupModalProps: ModalsProps = {
        className: 'site-popup',
        visible: popupVisible,
        setVisible: setPopupVisible,
        width: 560,
        content: <SitePopupTmp />,
        maskClosable: true,
        centered: true,
        footer: null
    }

    useEffect(
        () => {
            const data = configs.state
            if (data === null || isEmpty(data.userLevelTypes)) {
                return
            }

            const userLevelMaps: SevConfLangMaps = {}
            for (let i = 0; i < data.userLevelTypes.length; i++) {
                const item = data.userLevelTypes[ i ]
                userLevelMaps[ item.id ] = item
            }
            userLevel.set(userLevelMaps)

            const networkDiskMapMaps: SevConfLangMaps = {}
            for (let i = 0; i < data.networkDiskTypes.length; i++) {
                const item = data.networkDiskTypes[ i ]
                networkDiskMapMaps[ item.id ] = item
            }
            networkDiskMapConfig.set(networkDiskMapMaps)

            if (signInVisible.state.visible === true) {
                return
            }

            if (data.sitePopup.zhSimplified !== '') {
                if (sitePopupState !== null) {
                    if (
                        data.sitePopupRule === SitePopupRule.day &&
                        new Date(variables.timestamp * 1000).getDate() === new Date(sitePopupState.timestamp * 1000).getDate()
                    ) {
                        // 每天弹出
                        return
                    }

                    if (data.sitePopupRule === SitePopupRule.equipment && sitePopupState.uniqueId === md5Str(data.sitePopup.zhSimplified)) {
                        // 按照设备弹出
                        return
                    }
                }

                setPopupVisible(true)
            }
        },
        [ configs.state ]
    )

    useEffect(
        () => {
            // 系统配置
            conf(data => {
                configs.set(data)
            })

            // 字典
            void dictionariesApi([
                ProductLabelTypes.userLevel,
                ProductLabelTypes.gameClass,
                ProductLabelTypes.siteGuide
            ]).then(
                res => {
                    if (res.data === undefined || res.data === null) {
                        return
                    }
                    const data = res.data as Dictionaries
                    dictionary.set(data)

                    if (data.gameClass !== undefined && data.gameClass !== null && data.gameClass?.children.length >= 0) {
                        const allGameClassMaps: Record<number, DictionaryItem> = {}
                        const gameClassMaps: Record<number, DictionaryItem> = {}
                        data.gameClass.children.forEach(
                            item => {
                                allGameClassMaps[ item.id ] = item
                                if (item.digitalVal === 1) {
                                    gameClassMaps[ item.id ] = item
                                }
                            }
                        )

                        gameClass.set(gameClassMaps)
                        allGameClass.set(allGameClassMaps)
                    }
                }
            )
        },
        []
    )

    return <ConfigProvider locale={ languageState.shared() === 'zh' ? zhCN : enUS }>
        <Layout>
            { popupVisible ? <Modals { ...popupModalProps } /> : '' }
            <Header />
            <Layout className="layout-container">
                <Switch>{ renderRoutes(routes) }</Switch>
            </Layout>
            <Footer />
        </Layout>
    </ConfigProvider>
}

export default Main