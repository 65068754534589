import React, { type ReactElement, useEffect } from 'react'
import { documentTitle } from '#utils/configs'
import useFetchState from '#repositories/models'
import { articleRow, type Item } from '#repositories/apis/documents'
import { Routes } from '#utils/router'
import { useHistory } from 'react-router-dom'
import Loading from '#components/loading'

interface RowProps {
    uniqueId: string
    header?: ReactElement
}

const Main: React.FC<RowProps> = ({ uniqueId, header }) => {
    const history = useHistory()
    const [ loading, setLoading ] = useFetchState(true)
    const [ data, setData ] = useFetchState<Item | null>(null)

    useEffect(
        () => {
            documentTitle()

            if (uniqueId === '') {
                return
            }

            void articleRow(uniqueId).then(
                res => {
                    const data = res.data as Item
                    setData(data)
                    documentTitle(data.title)
                }
            ).catch(
                () => {
                    history.push(Routes.questionsAndAnswers)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        },
        []
    )

    return <div className="article-container sp column">
        <div className="content-box">
            { header }
            {
                loading || data === null
                    ? <Loading />
                    : <div
                        className="content indented"
                        dangerouslySetInnerHTML={{ __html: data.content }}
                    />
            }
        </div>
    </div>
}

export default Main