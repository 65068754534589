import React, { useEffect, useRef } from 'react'
import { Col, Row, Divider, Input, Button } from 'antd'
import { Language, Userinfo } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { bindEmail, makeUserItem, type UserItem } from '#repositories/apis/user'
import { CaptchaType, email as captchaEmailApi } from '#repositories/apis/cptcha'
import { ReactComponent as Message } from '#assets/svg/message.svg'
import { ReactComponent as Email } from '#assets/svg/email.svg'
import localization, { type LT } from '#utils/localization'
import { textOnChange } from '#utils/events'
import { isEmail } from '#utils/patterns'
import { Countdown } from '#utils/countdown'
import { placeholder } from '#utils/localization/dictionary'
import { isEmpty } from '#utils/functions'
import { MessageType, MMessage } from '#components/hint'
import Copy from '#components/copy'
import Icon from '#components/icon'

const Main: React.FC = () => {
    const keyRef = useRef<string>('')
    const langDictRef = useRef<LT>({})
    const userinfo = new Userinfo()
    const lang = new Language()
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // 本地化
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // data
    const [ data, setData ] = useFetchState<UserItem>(makeUserItem())
    // email
    const [ email, setEmail ] = useFetchState('')
    // code
    const [ code, setCode ] = useFetchState('')
    // 获取验证码加载
    const [ codeLoading, setCodeLoading ] = useFetchState(false)
    // code button text
    const [ codeButtonTips, setCodeButtonTips ] = useFetchState('')
    // code button text
    const [ codeButtonDisabled, setCodeButtonDisabled ] = useFetchState(false)
    // 倒计时验证码
    const countdownFunc = (init: boolean = false): void => {
        const instance = new Countdown(
            'bind-email-countdown',
            (countdown: number): void => {
                if (countdown <= 0) {
                    setCodeButtonTips(langDictRef.current.getCaptchaCode)
                    setCodeButtonDisabled(false)
                    return
                }
                setCodeButtonTips(
                    langDictRef.current.getCaptchaCodeCountdown.replace(
                        placeholder,
                        String(countdown)
                    )
                )

                setCodeButtonDisabled(true)
            }
        )

        if (init && !instance.check()) {
            return
        }

        instance.do()
    }

    // 获取邮箱验证码
    const getCode = (): void => {
        if (!isEmail(email)) {
            MMessage({
                message: langDict.emailFormRuleMsg,
                type: MessageType.warning
            })
            return
        }

        if (data.email !== '' && email === data.email) {
            MMessage({
                message: langDict.sameEmailMsg,
                type: MessageType.warning
            })
            return
        }

        setCodeLoading(true)
        void captchaEmailApi<any>(email, CaptchaType.bind).then(
            res => {
                MMessage({
                    message: langDict.emailCaptchaSendSuccess,
                    type: MessageType.success
                })

                keyRef.current = res.data.encrypt ?? ''
                countdownFunc()
            }
        ).finally(
            () => {
                setTimeout(
                    () => {
                        setCodeLoading(false)
                    },
                    1000
                )
            }
        )
    }

    const submit = (): void => {
        if (!isEmail(email)) {
            MMessage({
                message: langDict.emailFormRuleMsg,
                type: MessageType.error
            })
            return
        }

        if (code === '') {
            MMessage({
                message: langDict.codeFormRuleMsg,
                type: MessageType.error
            })
            return
        }

        setLoading(true)
        void bindEmail(email, code, keyRef.current).then(
            () => {
                MMessage({
                    message: langDict.updateSuccessMsg,
                    type: MessageType.success
                })

                data.email = email
                userinfo.set(data)
            }
        ).finally(
            () => {
                setTimeout(
                    () => {
                        setLoading(false)
                    },
                    1000
                )
            }
        )
    }

    useEffect(
        () => {
            if (userinfo.state === null) {
                return
            }
            setData(userinfo.state)
        },
        [ userinfo.state ]
    )

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'codeFormRuleMsg',
                    'sameEmailMsg',
                    'emailFormRuleMsg',
                    'updateSuccessMsg',
                    'buttonSave',
                    'email',
                    'emailPlaceholder',
                    'codePlaceholder',
                    'captchaCode',
                    'emailCaptchaSendSuccess',
                    'getCaptchaCode',
                    'getCaptchaCodeCountdown',
                    'currentEmail'
                ]
            })
            setLangDict(langDict)
            langDictRef.current = langDict

            setCodeButtonTips(langDict.getCaptchaCode)
            if (!isEmpty(langDict.getCaptchaCodeCountdown)) {
                countdownFunc(true)
            }
        },
        [ lang.state ]
    )

    return <div className="bind-container">
        <Row className="form-columns">
            <Col span="24">
                <p className="identity">
                    { langDict.currentEmail }:
                    <Copy text={ data.email }>
                        <> [ <span>{ data.email }</span> ]</>
                    </Copy>
                </p>
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.email }: </Col>
                    <Col span={ 15 }>
                        <Input
                            className="email"
                            allowClear
                            value={ email }
                            maxLength={ 50 }
                            prefix={ <Icon tap><Email /></Icon> }
                            placeholder={ langDict.emailPlaceholder }
                            onChange={
                                textOnChange(value => {
                                    setEmail(value.trim())
                                })
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.captchaCode }: </Col>
                    <Col span={ 10 }>
                        <Input
                            allowClear
                            value={ code }
                            prefix={ <Icon tap><Message /></Icon> }
                            placeholder={ langDict.codePlaceholder }
                            onChange={
                                textOnChange(value => {
                                    setCode(value.trim())
                                })
                            }
                        />
                    </Col>
                    <Col span={ 4 } offset={ 1 } className="flex-end">
                        <Button
                            htmlType="button"
                            disabled={ codeButtonDisabled }
                            className="submit background-button color-white"
                            loading={ codeLoading }
                            onClick={ getCode }
                        >{ codeLoading ? '' : codeButtonTips }</Button>
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col offset={ 5 } span={ 15 }>
                        <Button
                            type="primary"
                            className="submit background-button"
                            loading={ loading }
                            onClick={ submit }
                        >{ langDict.buttonSave }</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export default Main