import React from 'react'
import Location from '#components/location'
import { Configs } from '#repositories/models/recoil-state'
import Image from '#components/image'
import { Routes } from '#utils/router'

const Main: React.FC = () => {
    const configs = new Configs()

    return <div className="logo-box">
        <Location to={ Routes.home } className="flex-center cursor-pointer">
            {
                configs.state !== null
                    ? <Image product={ true } original className="logo" src={ configs.state.siteLogo.xxl } alt="" />
                    : <Image product={ true } original className="logo" src="/assets/images/logo/xl.png" alt="" />
            }
        </Location>
    </div>
}

export default Main