import React, { useEffect, useRef } from 'react'
import { Switch, useHistory } from 'react-router-dom'
import { renderRoutes, type RouteConfig } from 'react-router-config'
import { Col, Row } from 'antd'
import { RouteProfile, Routes } from '#utils/router'
import localization, { pickLangKey } from '#utils/localization'
import { documentTitle } from '#utils/configs'
import Location from '#components/location'
import Icon from '#components/icon'
import Loading from '#components/loading'
import { Alert, MessageType } from '#components/hint'
import { Configs, Language, Profile, Userinfo } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { row, type UserItem } from '#repositories/apis/user'
import profile from '#routers/profile'
import { type MenuGroups } from '#pages/profile/model'
import { ReactComponent as Wallet } from '#assets/svg/wallet.svg'
import { ReactComponent as IconDatabase } from '#assets/svg/database.svg'
import { ReactComponent as IconDiamond } from '#assets/svg/diamond.svg'
import { ReactComponent as IconBasket } from '#assets/svg/basket.svg'
import { ReactComponent as IconStar } from '#assets/svg/star.svg'
import { ReactComponent as IconId } from '#assets/svg/id.svg'
import { ReactComponent as IconLink } from '#assets/svg/link.svg'
import { ReactComponent as IconShield } from '#assets/svg/shield.svg'
import { ReactComponent as IconSubstation } from '#assets/svg/substation.svg'
import { ReactComponent as IconWithdraw } from '#assets/svg/withdraw.svg'
import { ReactComponent as IconCreditCard } from '#assets/svg/credit-card.svg'
import { ReactComponent as GameBox } from '#assets/svg/game-box-1.svg'
import { authorization } from '#utils/token'

const routes = profile as RouteConfig[]

const Main: React.FC = () => {
    const fetchState = useRef(false)
    const configs = new Configs().shared()
    const history = useHistory()
    const lang = new Language()
    const profile = new Profile()
    const userinfo = new Userinfo()
    const [ loading, setLoading ] = useFetchState(false)

    // 左侧菜单
    const [ menus, setMenus ] = useFetchState<MenuGroups>([])

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'profileExpenseRecords',
                    'profileMemberCenter',
                    'profileGameBox',
                    'profileAccountInformation',
                    'profileBalance',
                    'profileMembership',
                    'profilePurchaseHistory',
                    'profileFavorites',
                    'profileInformation',
                    'profileEmailBinding',
                    'profileEmailUpdate',
                    'unbindingCode',
                    'profileWithdraw',
                    'profileSubstation',
                    'activationCodes',
                    'profilePasswordSettings'
                ]
            })

            const menus = [
                {
                    title: langDict.profileMemberCenter,
                    children: [
                        {
                            path: RouteProfile.expenseRecords,
                            title: langDict.profileExpenseRecords,
                            checkActive: (path: string, routePath: string): boolean => routePath.indexOf(path) === 0,
                            icon: <Wallet />
                        },
                        {
                            path: RouteProfile.wallet,
                            title: langDict.profileBalance,
                            icon: <IconDatabase />
                        },
                        {
                            path: RouteProfile.membership,
                            title: langDict.profileMembership,
                            icon: <IconDiamond />
                        },
                        {
                            path: RouteProfile.gameBox,
                            title: langDict.profileGameBox,
                            icon: <GameBox />
                        },
                        {
                            path: RouteProfile.purchaseHistory,
                            title: langDict.profilePurchaseHistory,
                            checkActive: (path: string, routePath: string): boolean => routePath.indexOf(path) === 0,
                            icon: <IconBasket />
                        },
                        {
                            path: RouteProfile.favorites,
                            title: langDict.profileFavorites,
                            checkActive: (path: string, routePath: string): boolean => routePath.indexOf(path) === 0,
                            icon: <IconStar />
                        },
                        {
                            path: RouteProfile.withdraw,
                            title: langDict.profileWithdraw,
                            checkActive: (path: string, routePath: string): boolean => routePath.indexOf(path) === 0,
                            icon: <IconWithdraw />
                        },
                        {
                            path: RouteProfile.substation,
                            title: langDict.profileSubstation,
                            icon: <IconSubstation />
                        },
                        {
                            path: RouteProfile.activationCodes,
                            title: langDict.activationCodes,
                            icon: <IconCreditCard />
                        },
                        {
                            path: RouteProfile.unbindingCode,
                            title: langDict.unbindingCode,
                            icon: <GameBox />
                        }
                    ]
                    // .filter(
                    //     item => {
                    //         if (RouteProfile.gameBox === item.path) {
                    //             if (configs !== null) {
                    //                 return configs.desktopAppWebChannelState
                    //             }
                    //
                    //             return false
                    //         }
                    //
                    //         return true
                    //     }
                    // )
                },
                {
                    title: langDict.profileAccountInformation,
                    children: [
                        {
                            path: RouteProfile.information,
                            title: langDict.profileInformation,
                            icon: <IconId />
                        },
                        {
                            path: RouteProfile.accountBinding,
                            title: userinfo.state !== null && userinfo.state.email !== ''
                                ? langDict.profileEmailUpdate
                                : langDict.profileEmailBinding,
                            icon: <IconLink />
                        },
                        {
                            path: RouteProfile.passwordSettings,
                            title: langDict.profilePasswordSettings,
                            icon: <IconShield />
                        }
                    ]
                }
            ]
            setMenus(menus)

            menus.forEach(
                item => {
                    item.children.forEach(
                        value => {
                            if (history.location.pathname === value.path) {
                                documentTitle(value.title)
                            }
                        }
                    )
                }
            )
        },
        [ lang.state, history.location.pathname, userinfo.state ]
    )

    useEffect(
        () => {
            if (configs === null || fetchState.current) {
                return
            }

            fetchState.current = true
            void row().then(
                res => {
                    const data = res.data as UserItem
                    if (data.state === 1) {
                        Alert({
                            type: MessageType.error,
                            message: <div className="weight">{ pickLangKey(lang.state, configs.memberBannedHint) }</div>,
                            success: (): void => {
                                history.push(Routes.home)
                            }
                        })
                        return
                    }

                    // 用户信息
                    userinfo.set(data)
                    // 更新本地缓存
                    if (profile.state !== null) {
                        const profileState = { ...profile.state }
                        profileState.level = data.level

                        const cache = authorization('get')
                        if (cache !== null) {
                            cache.level = data.level
                            void authorization('set', cache)
                        }

                        profile.set(profileState)
                    }

                    setLoading(false)
                }
            )
        },
        [ configs ]
    )

    return <Row className="profile-container">
        <Col span={ 5 } className="menus">{
            menus.map(
                (item, key) => <div key={ key } className="group">
                    <span className="title">{ item.title }</span>
                    <ul>{
                        item.children.map(
                            (value, k) => <li key={ k }>
                                {
                                    history.location.pathname === value.path || (
                                        typeof value.checkActive === 'function'
                                            ? value.checkActive(value.path, history.location.pathname)
                                            : false
                                    )
                                        ? <div className="active">
                                            { value.icon !== undefined ? <Icon tap>{ value.icon }</Icon> : '' }
                                            <span>{ value.title }</span>
                                        </div>
                                        : <Location to={ value.path }>
                                            { value.icon !== undefined ? <Icon tap>{ value.icon }</Icon> : '' }
                                            <span>{ value.title }</span>
                                        </Location>
                                }
                            </li>
                        )
                    }</ul>
                </div>
            )
        }</Col>
        <Col offset={ 1 } span={ 18 } className="container overflow-y-auto">
            { loading ? <Loading /> : <Switch>{ renderRoutes(routes) }</Switch> }
        </Col>
    </Row>
}

export default Main