import React, { useEffect, useRef } from 'react'
import { Carousel } from 'antd'
import $ from 'jquery'
import OwlCarousel from 'react-owl-carousel'
import { type CarouselRef } from 'antd/es/carousel'
import { pickLangKey } from '#utils/localization'
import { type ProductsFilterParams } from '#utils/router'
import { isEmpty, throttle } from '#utils/functions'
import Location from '#components/location'
import { type SliderItem } from '#repositories/types/foundation'
import { Language } from '#repositories/models/recoil-state'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import useFetchState from '#repositories/models'
import Icon from '#components/icon'
import { ReactComponent as ArrowLeft } from '#assets/svg/arrow-left.svg'
import { ReactComponent as ArrowRight } from '#assets/svg/arrow-right.svg'

interface PropsType {
    list: SliderItem[]
    disabledAutoplay?: boolean
}

interface ItemPropsType {
    item: SliderItem
}

const Item: React.FC<ItemPropsType> = ({ item }) => {
    const style = {
        backgroundImage: `url("${ item.image }")`
    }

    let inner = <></>
    if (item.title !== '') {
        inner = <div className="mask">
            <p className="title">{ item.title }</p>
            <p className="description ellipsis-5">{ item.description }</p>
        </div>
    }

    if (item.url !== '') {
        return <Location
            className="item"
            style={ style }
            to={ item.url ?? '' }
        >{ inner }</Location>
    }

    return <div className="item" style={ style }>{ inner }</div>
}

const Main: React.FC<PropsType> = props => {
    const carousel = useRef<CarouselRef>(null)
    const onSwitch = (type: string): void => {
        if (carousel.current === null) {
            return
        }

        if (type === 'left') {
            carousel.current.prev()
            return
        }

        carousel.current.next()
    }

    return <div className="carousel-box">
        <div
            className="switch-left"
            onClick={
                e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onSwitch('left')
                }
            }
        >
            <Icon tap><ArrowLeft /></Icon>
        </div>
        <div
            className="switch-right"
            onClick={
                e => {
                    e.stopPropagation()
                    e.preventDefault()
                    onSwitch('right')
                }
            }
        >
            <Icon tap><ArrowRight /></Icon>
        </div>
        <Carousel autoplay={ props.disabledAutoplay ?? true } ref={ carousel }>{
            props.list.map(
                (item, key) => item.component !== undefined ? item.component : <Item key={ key } item={ item } />
            )
        }</Carousel>
    </div>
}

export default React.memo(Main)

interface GameCategoriesProps {
    list: DictionaryItem[]
    routerFilterParams: ProductsFilterParams
    doSetRouterFilterParams: (data: ProductsFilterParams) => void
}

// <GamesCategories
//     list={ categories }
//     routerFilterParams={ routerFilterParams }
//     doSetRouterFilterParams={ doSetRouterFilterParams }
// />
// https://owlcarousel2.github.io/OwlCarousel2/index.html
const GamesCategoriesCom: React.FC<GameCategoriesProps> = props => {
    const lang = new Language()
    const [ scrollInitialized, setScrollInitialized ] = useFetchState(false)
    const [ categoryId, setCategoryId ] = useFetchState(0)
    const bindOwlCarousel = (key?: number): void => {
        const ele = $('.categories-owl-carousel')
        if (!scrollInitialized) {
            setScrollInitialized(true)
            // 滚动到指定位置
            if (key !== undefined && !isEmpty(key)) {
                ele.trigger('to.owl.carousel', [ key, 0, true ])
            } else {
                props.list.forEach(
                    (item, key) => {
                        if (props.routerFilterParams.categoryId === item.id) {
                            ele.trigger('to.owl.carousel', [ key, 0, true ])
                        }
                    }
                )
            }

            // 绑定鼠标滚轮事件
            ele.on('mousewheel', '.owl-stage', e => {
                const { originalEvent } = e
                if (originalEvent === undefined) {
                    return
                }

                if ((originalEvent as any).deltaY > 0) {
                    ele.trigger('next.owl')
                } else {
                    ele.trigger('prev.owl')
                }
                e.stopPropagation()
                e.preventDefault()
            })
        }
    }

    useEffect(
        () => {
            throttle(
                bindOwlCarousel,
                200,
                'product-categories'
            )
        },
        []
    )

    useEffect(
        () => {
            setCategoryId(props.routerFilterParams.categoryId ?? 0)
        },
        [ props.routerFilterParams.categoryId ]
    )

    return <div className="categories-box">
        {
            props.list.length > 5
                ? <>
                    <div
                        className="switch-left"
                        onClick={
                            e => {
                                e.stopPropagation()
                                e.preventDefault()
                                $('.categories-owl-carousel').trigger('prev.owl')
                            }
                        }
                    ><Icon tap><ArrowLeft /></Icon></div>
                    <div
                        className="switch-right"
                        onClick={
                            e => {
                                e.stopPropagation()
                                e.preventDefault()
                                $('.categories-owl-carousel').trigger('next.owl')
                            }
                        }
                    ><Icon tap><ArrowRight /></Icon></div>
                </>
                : ''
        }
        <OwlCarousel key={ categoryId } className="categories-owl-carousel" dots={ false } items={ 12 } margin={ 10 }>{
            props.list.map(
                (item, key) => <div
                    key={ key }
                    className={ 'category-item cursor-pointer' + (categoryId === item.id ? ' active' : '') }
                    onClick={
                        () => {
                            setCategoryId(item.id)
                            props.doSetRouterFilterParams({
                                ...props.routerFilterParams,
                                categoryId: item.id
                            })
                            bindOwlCarousel()
                            $('.categories-owl-carousel').trigger('to.owl.carousel', [ key, 0, true ])
                        }
                    }
                >{ pickLangKey(lang.state, item.title) }</div>
            )
        }</OwlCarousel>
    </div>
}

export const GamesCategories = React.memo(
    GamesCategoriesCom,
    (prevProps, nextProps): boolean => {
        if (prevProps.list.length === 0 || nextProps.list.length === 0) {
            return false
        }

        return prevProps.list.length === nextProps.list.length
    }
)

interface CategoryTabsProps {
    list: DictionaryItem[]
    callback: (item: DictionaryItem) => void
}

// https://owlcarousel2.github.io/OwlCarousel2/index.html
const CategoryTabsCom: React.FC<CategoryTabsProps> = props => {
    const lang = new Language()
    const [ scrollInitialized, setScrollInitialized ] = useFetchState(false)
    const bindOwlCarousel = (key?: number): void => {
        const ele = $('.category-tab-owl-carousel')
        if (!scrollInitialized) {
            setScrollInitialized(true)

            // 绑定鼠标滚轮事件
            ele.on('mousewheel', '.owl-stage', e => {
                const { originalEvent } = e
                if (originalEvent === undefined) {
                    return
                }

                if ((originalEvent as any).deltaY > 0) {
                    ele.trigger('next.owl')
                } else {
                    ele.trigger('prev.owl')
                }
                e.stopPropagation()
                e.preventDefault()
            })
        }
    }

    useEffect(
        () => {
            throttle(
                bindOwlCarousel,
                200,
                'product-categories-tab'
            )
        },
        []
    )

    return <div className="categories-box">
        {
            props.list.length > 8
                ? <>
                    <div
                        className="switch-left"
                        onClick={
                            e => {
                                e.stopPropagation()
                                e.preventDefault()
                                $('.category-tab-owl-carousel').trigger('prev.owl')
                            }
                        }
                    ><Icon tap><ArrowLeft /></Icon></div>
                    <div
                        className="switch-right"
                        onClick={
                            e => {
                                e.stopPropagation()
                                e.preventDefault()
                                $('.category-tab-owl-carousel').trigger('next.owl')
                            }
                        }
                    ><Icon tap><ArrowRight /></Icon></div>
                </>
                : ''
        }
        <OwlCarousel className="category-tab-owl-carousel" dots={ false } items={ 8 } margin={ 10 }>{
            props.list.map(
                (item, key) => <div
                    key={ key }
                    data-id={ item.id }
                    className={ 'category-item cb cursor-pointer' + (key === 0 ? ' active' : '') }
                    onClick={
                        () => {
                            bindOwlCarousel()
                            props.callback(item)
                            $('.category-item.cb').removeClass('active')
                            $(`.category-item.cb[data-id="${ item.id }"]`).addClass('active')
                        }
                    }
                ><span className="char">{ pickLangKey(lang.state, item.title) }</span></div>
            )
        }</OwlCarousel>
    </div>
}

export const CategoryTabs = React.memo(
    CategoryTabsCom,
    (prevProps, nextProps): boolean => {
        if (prevProps.list.length === 0 || nextProps.list.length === 0) {
            return false
        }

        return prevProps.list.length === nextProps.list.length
    }
)