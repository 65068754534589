import { dateFormat, DEV_ENV } from '#constants/appoint'
import dayjs from 'dayjs'
import { Md5 } from 'ts-md5'

/**
 * 动态创建link
 * @param url
 * @param id
 */
export function createStyleLink(url: string, id?: string): void {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.type = 'text/css'
    link.href = url
    if (id !== undefined && id !== '') {
        link.setAttribute('id', id)
    }

    document.head.appendChild(link)
}

/**
 * exec function
 * @param func
 * @param params
 */
export function execFunc(func: any, ...params: any[]): any {
    return typeof func === 'function' && func(...params)
}

/**
 * 阻止默认事件
 * @param e
 * @param type
 */
export function stopDefaultEvent(e: Event, type = 0): void {
    if (type === 1) {
        e.stopPropagation()
    } else if (type === 2) {
        e.preventDefault()
    } else {
        e.stopPropagation()
        e.preventDefault()
    }
}

/**
 * 是否为空
 * @param data
 */
export function isEmpty(data: any): boolean {
    return data === null ||
        data === '' ||
        data === undefined ||
        data.length === 0
}

/**
 * 转数字
 * @param data
 * @param def
 */
export function toNumber(data: any, def?: number): number {
    const value = data * 1
    if (isNaN(value)) {
        if (def !== undefined && !isEmpty(def)) {
            return def
        }

        return 0
    }

    return value
}

/**
 * 是否是数组
 * @param object
 */
export function isArray(object: any): boolean {
    return Object.prototype.toString.call(object) === '[object Array]'
}

/**
 * 是否是对象
 * @param object
 */
export function isObject(object: any): boolean {
    return Object.prototype.toString.call(object) === '[object Object]'
}

/**
 * 是否是数组或对象
 * @param data
 */
export function isArrOrObj(data: any): boolean {
    return isArray(data) || isObject(data)
}

export function isUndefined(data: any): boolean {
    return typeof data === 'undefined'
}

export function getQuery(key: string): string {
    return new URLSearchParams(window.location.search).get(key) ?? ''
}

export function d(): boolean {
    return process.env.REACT_APP_ENV === DEV_ENV ||
        (
            getQuery(process.env.REACT_APP_HEADER_DEV_KEY) === DEV_ENV &&
            process.env.REACT_APP_HEADER_DEV_KEY !== '' &&
            process.env.REACT_APP_HEADER_DEV_VAL !== ''
        )
}

type timer = ReturnType<typeof setTimeout>
const timerMaps: Record<string, timer> = {}

/**
 * throttle 节流
 * @param call
 * @param ms
 * @param unique
 * @param onlyUnique
 */
export function throttle<T extends (...args: any[]) => void>(
    call: T,
    ms = 500,
    unique = '',
    onlyUnique = false
): void {
    if (!onlyUnique) {
        unique = JSON.stringify([ call, ms, unique ])
    }

    if (timerMaps[ unique ] !== null) {
        clearTimeout(timerMaps[ unique ])
    }

    timerMaps[ unique ] = setTimeout(() => {
        execFunc(call)
    }, ms)
}

export function timestampFormat(timestamp: number, format: string = dateFormat): string {
    if (timestamp <= 0) {
        return '-'
    }

    return dayjs.unix(timestamp).format(format)
    // return new Date(timestamp * 1000).toISOString()
}

export function trim(str: string, char?: RegExp, replace?: string): string {
    return str.replace(char ?? /(^\s*)|(\s*$)/g, replace ?? '')
}

export function numberFormat(n: number): string {
    if (n >= 10000000) {
        const num = n / 10000000
        return `${ num.toFixed(1) }kw`
    }

    if (n >= 10000) {
        const num = n / 10000
        return `${ num.toFixed(1) }w`
    }

    if (n >= 1000 && n < 10000) {
        const num = n / 1000
        return `${ num.toFixed(1) }k`
    }

    return n.toString()
}

export function isTimestamp(timestamp: number): boolean {
    const regex = /^\d{10}$/
    if (regex.test(timestamp.toString())) {
        const date = new Date(timestamp * 1000)
        return !isNaN(date.getTime())
    }
    return false
}

// 复制到剪贴板
export function copyToClipboard(text: string, callback?: () => void): void {
    const el = document.createElement('input')
    el.setAttribute('value', text)
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    // return navigator.clipboard.writeText(text)
    execFunc(callback)
}

export function isURL(input: string): boolean {
    return /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(input)
}

export const extractUrl = (content: string): string => {
    const data = content.match(/(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g)
    if (data === null) {
        return content
    }

    return data.length > 0 ? data[ 0 ] : ''
}

export const GetYearMonth = (timestampInSeconds: number, sep = '-', sep1 = ''): string => {
    const date = new Date(timestampInSeconds * 1000)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return `${ year }${ sep }${ month < 10 ? '0' : '' }${ month }${ sep1 }`
}

export const md5Str = (content: string): string => {
    return Md5.hashStr(content).toString()
}

export function isChrome87OrEarlier(): boolean {
    const userAgent: string = window.navigator.userAgent.toLowerCase()

    const isChrome: boolean = userAgent.includes('chrome')
    if (isChrome) {
        const versionMatch: RegExpMatchArray | null = /chrome\/(\d+)/.exec(userAgent)
        if (versionMatch !== null) {
            const chromeVersion: number = parseInt(versionMatch[ 1 ], 10)
            return chromeVersion <= 87
        }
    }

    return false
}
