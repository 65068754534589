import React, { useEffect } from 'react'
import { PayType } from '#constants/appoint'
import { Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import localization, { type LT } from '#utils/localization'

interface MainProps {
    type: PayType
}

const Main: React.FC<MainProps> = ({ type }) => {
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ content, setContent ] = useFetchState('-')

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'payMethodAlipay',
                        'payMethodBalance'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            if (type === PayType.ali) {
                setContent(langDict.payMethodAlipay)
            } else if (type === PayType.balance) {
                setContent(langDict.payMethodBalance)
            }
        },
        [ type, langDict ]
    )

    return <>{ content }</>
}

export default Main