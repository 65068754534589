import { type Rule } from 'rc-field-form/lib/interface'
import { execFunc, isEmpty, throttle } from '#utils/functions'
import { type LoginRespType, UserColumns, userinfoComplete } from '#repositories/apis/user'
import { bothEndsSpace, isEmail } from '#utils/patterns'
import { conf } from '#utils/configs'
import { type Configs, type Profile } from '#repositories/models/recoil-state'
import { type TokenType } from '#repositories/types/local-store'
import { authorization } from '#utils/token'
import { LastRoute } from '#repositories/cache/location'

export enum PageType {
    login,
    register,
    findPassword,
    setPassword
}

export interface SigninType {
    type: PageType
}

export interface LoginFieldType {
    username: string
    password: string
    remember?: boolean
}

export interface RegisterFieldType {
    username: string
    password: string
    rePassword: string
    email: string
    privacy: boolean
}

export interface SetPasswordFieldType {
    password: string
    rePassword: string
}

export interface FindPasswordFieldType {
    email: string
    code: string
}

export interface SignInProps {
    popup: boolean
    callback?: () => void
}

export const usernameRule = (message: string): Rule[] => [
    { required: true, message }
]

export const codeRule = (message: string): Rule[] => [
    { required: true, message }
]

export const usernameRegisterRule = (message: string, messageValid: string, completeMessage: string): Rule[] => [
    { required: true, message },
    () => ({
        validator: async(_, value) => {
            if (isEmpty(value)) {
                return
            }

            const username = value as string
            await new Promise<any>(
                (resolve, reject) => {
                    if (value === '') {
                        reject(message)
                        return
                    }

                    if (bothEndsSpace(value) || value.length > 20) {
                        reject(messageValid)
                        return
                    }

                    throttle(
                        (): void => {
                            void userinfoComplete({
                                column: UserColumns.username,
                                val: username
                            }).then(
                                res => {
                                    if (res.data as boolean) {
                                        reject(completeMessage)
                                        return
                                    }
                                    resolve(true)
                                }
                            )
                        },
                        500,
                        'usernameComplete'
                    )
                }
            )
        }
    })
]

export const emailRegRule = (message: string, completeMessage: string, checkExists: boolean = false): Rule[] => [
    { required: true, message },
    () => ({
        validator: async(_, value) => {
            if (isEmpty(value)) {
                return
            }

            const email = value as string
            if (!isEmail(email)) {
                return await Promise.reject(new Error(message))
            }
            await new Promise<any>(
                (resolve, reject) => {
                    if (value === '') {
                        reject(message)
                        return
                    }

                    throttle(
                        (): void => {
                            void userinfoComplete({
                                column: UserColumns.email,
                                val: email
                            }).then(
                                res => {
                                    if (checkExists) {
                                        // 检查是否存在
                                        if (res.data as boolean) {
                                            resolve(true)
                                            return
                                        }
                                        reject(completeMessage)
                                        return
                                    }

                                    // 检查不存在
                                    if (res.data as boolean) {
                                        reject(completeMessage)
                                        return
                                    }
                                    resolve(true)
                                }
                            )
                        },
                        500,
                        'emailComplete'
                    )
                }
            )
        }
    })
]

export const passwordRule = (message: string): Rule[] => [
    { required: true, message }
]

export const rePasswordRule = (message: string): Rule[] => [
    ({ getFieldValue }) => ({
        validator: async(_, value) => {
            if ((value as string) !== '' && getFieldValue(UserColumns.password) === value) {
                await Promise.resolve()
                return
            }
            return await Promise.reject(new Error(message))
        }
    })
]

export const privacyRule = (message: string): Rule[] => [
    {
        validator: async(_, value) => {
            const val = value as boolean
            if (val) {
                await Promise.resolve()
                return
            }

            return await Promise.reject(new Error(message))
        }
    }
]

export const wrapperCol = { offset: 3, span: 18 }
export const wrapperXLCol = { offset: 2, span: 20 }

export const getConfigs = (configs: Configs): void => {
    conf(data => {
        configs.set(data)
    })
}

interface loginCallParams {
    history: any
    popup: boolean
    data: LoginRespType
    profile: Profile
    callback?: () => void
}
export const loginCall = (
    {
        history,
        popup,
        data,
        profile,
        callback
    }: loginCallParams
): void => {
    const state: TokenType = {
        token: data.token,
        expires: data.expires,
        avatar: data.userInfo.avatar,
        nickname: data.userInfo.nickname,
        email: data.userInfo.email,
        level: data.userInfo.level,
        id: data.userInfo.id
    }
    void authorization('set', state)
    profile.set(state)
    execFunc(callback, state)
    if (!popup) {
        history.push(LastRoute('get') ?? '/')
    }
}