import { atom, type RecoilState } from 'recoil'
import State from '#utils/recoil'
import { Lang } from '#utils/localization'
import { type ConfigsType } from '#repositories/apis/base'
import store from '#utils/store'
import { authorization } from '#utils/token'
import { type TokenType } from '#repositories/types/local-store'
import { type Dictionaries, type DictionaryItem } from '#repositories/apis/dictionaries'
import { type UserItem } from '#repositories/apis/user'
import { type SevConfLangMaps } from '#repositories/types/lang'
import { md5Str } from '#utils/functions'
import { type LevelPriceDifferenceResp } from '#repositories/types/response'
import { type StatisticsResp } from '#repositories/apis/wallet'

export class Language extends State {
    static readonly cacheKey = 'language'

    static cache(): Lang {
        const cache = store.get(Language.cacheKey)
        if (cache === Lang.en) {
            return Lang.en
        }

        if (cache === Lang.zhTW) {
            return Lang.zhTW
        }

        return Lang.zh
    }

    static lang = atom({
        key: 'language',
        default: Language.cache()
    })

    constructor() {
        super(Language.lang)
    }

    shared(): string {
        return super.shared() as string
    }

    set(v: Lang): void {
        super.setState(v)
        store.set(Language.cacheKey, v)
    }
}

export class Configs extends State {
    static data = atom({
        key: 'configs',
        default: null
    })

    constructor() {
        super(Configs.data)
    }

    shared(): ConfigsType | null {
        return super.shared() as ConfigsType
    }

    set(v: ConfigsType): void {
        super.setState(v)
    }
}

export class UserLevelConfig extends State {
    static data = atom({
        key: 'userLevelConfig',
        default: {}
    })

    constructor() {
        super(UserLevelConfig.data)
    }

    shared(): SevConfLangMaps {
        return super.shared() as SevConfLangMaps
    }

    set(v: SevConfLangMaps): void {
        super.setState(v)
    }
}

export class NetworkDiskMapConfig extends State {
    static data = atom({
        key: 'networkDiskMapConfig',
        default: {}
    })

    constructor() {
        super(NetworkDiskMapConfig.data)
    }

    shared(): SevConfLangMaps {
        return super.shared() as SevConfLangMaps
    }

    set(v: SevConfLangMaps): void {
        super.setState(v)
    }
}

export class Theme extends State {
    static readonly cacheKey = 'theme'
    static readonly dark = 'dark'
    static readonly light = 'light'

    static theme = atom({
        key: 'theme',
        default: Theme.cache()
    })

    static cache(): string {
        if (store.get(Theme.cacheKey) === Theme.dark) {
            return Theme.dark
        }

        return Theme.light
    }

    constructor() {
        super(Theme.theme)
    }

    shared(): string {
        return super.shared() as string
    }

    set(callback: (theme: string) => void): void {
        if (this.state === Theme.light) {
            store.set(Theme.cacheKey, Theme.dark)
            super.setState(Theme.dark)
            callback(Theme.dark)
            return
        }

        store.set(Theme.cacheKey, Theme.light)
        super.setState(Theme.light)
        callback(Theme.light)
    }
}

export const profileCheck = {
    intervalState: false,
    neededUpdate: false
}
export class Profile extends State {
    static profile = atom({
        key: 'profile',
        default: authorization('get')
    })

    constructor() {
        if (!profileCheck.intervalState) {
            profileCheck.intervalState = true
            setInterval(
                () => {
                    if (profileCheck.neededUpdate) {
                        setTimeout(
                            () => {
                                if (!profileCheck.neededUpdate) {
                                    return
                                }
                                profileCheck.neededUpdate = false
                                super.setState(authorization('get'))
                            },
                            1000
                        )
                    }
                },
                500
            )
        }

        super(Profile.profile)
    }

    shared(): TokenType | null {
        return super.shared() as TokenType
    }

    set(profile: TokenType | null): void {
        super.setState(profile)
    }
}

export enum SignInPopupType {
    login,
    register,
    findPassword,
    setPassword,
}

// 登录弹窗类型
export class SignInPopup extends State {
    static type = atom({
        key: 'signInType',
        default: SignInPopupType.login
    })

    constructor() {
        super(SignInPopup.type)
    }

    shared(): SignInPopupType {
        return super.shared() as SignInPopupType
    }

    set(profile: SignInPopupType): void {
        super.setState(profile)
    }
}

interface SignInPopupVisibleState {
    callback?: (data: any) => void
    visible: boolean
}

const signInPopupVisibleState: SignInPopupVisibleState = {
    callback: (_: any): void => {},
    visible: false
}
// 登录弹窗显示状态
export class SignInPopupVisible extends State {
    static data: RecoilState<SignInPopupVisibleState> = atom({
        key: 'SignInPopupVisible',
        default: signInPopupVisibleState
    })

    constructor() {
        super(SignInPopupVisible.data)
    }

    shared(): SignInPopupVisibleState {
        return super.shared() as SignInPopupVisibleState
    }

    set(visible: boolean, callback?: (data: any) => void): void {
        const signInPopupVisibleState: SignInPopupVisibleState = { callback, visible }
        super.setState(signInPopupVisibleState)
    }
}

export class Dictionary extends State {
    static data = atom({
        key: 'Dictionary',
        default: null
    })

    constructor() {
        super(Dictionary.data)
    }

    shared(): Dictionaries | null {
        return super.shared() as Dictionaries
    }

    set(v: Dictionaries): void {
        super.setState(v)
    }
}

export class GameClass extends State {
    static data = atom({
        key: 'gameClass',
        default: {}
    })

    constructor() {
        super(GameClass.data)
    }

    shared(): Record<number, DictionaryItem> {
        return super.shared() as Record<number, DictionaryItem>
    }

    set(v: Record<number, DictionaryItem>): void {
        super.setState(v)
    }
}

export class AllGameClass extends State {
    static data = atom({
        key: 'AllGameClass',
        default: {}
    })

    constructor() {
        super(AllGameClass.data)
    }

    shared(): Record<number, DictionaryItem> {
        return super.shared() as Record<number, DictionaryItem>
    }

    set(v: Record<number, DictionaryItem>): void {
        super.setState(v)
    }
}

export class Userinfo extends State {
    static data = atom({
        key: 'userinfo',
        default: null
    })

    constructor() {
        super(Userinfo.data)
    }

    shared(): UserItem | null {
        return super.shared() as UserItem
    }

    set(data: UserItem): void {
        super.setState(data)
    }
}

interface SitePopupStore {
    // 上一次弹出的时间戳
    timestamp: number
    // 内容唯一值
    uniqueId: string
}
export class SitePopup extends State {
    static readonly cacheKey = 'site-popup'

    static sitePopup = atom({
        key: 'sitePopup',
        default: SitePopup.cache()
    })

    static cache(): SitePopupStore | null {
        return store.get(SitePopup.cacheKey) as SitePopupStore
    }

    constructor() {
        super(SitePopup.sitePopup)
    }

    shared(): SitePopupStore | null {
        return super.shared() as SitePopupStore
    }

    set(content: string, timestamp: number): void {
        const data = {
            timestamp,
            uniqueId: md5Str(content)
        }
        store.set(SitePopup.cacheKey, data)
        super.setState(data)
    }
}

export class LevelPriceDifference extends State {
    static data = atom({
        key: 'levelPriceDifference',
        default: null
    })

    constructor() {
        super(LevelPriceDifference.data)
    }

    shared(): LevelPriceDifferenceResp | null {
        return super.shared() as LevelPriceDifferenceResp
    }

    set(data: LevelPriceDifferenceResp): void {
        super.setState(data)
    }
}

// 钱包统计
export class WalletStatistics extends State {
    static data = atom({
        key: 'walletStatistics',
        default: null
    })

    constructor() {
        super(WalletStatistics.data)
    }

    shared(): StatisticsResp | null {
        return super.shared() as StatisticsResp
    }

    set(data: StatisticsResp | null): void {
        super.setState(data)
    }
}