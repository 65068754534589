import React from 'react'
import { type RouteConfig, type RouteConfigComponentProps } from 'react-router-config'
import sites from './sites'
import signin from './signin'
import Layout from '#components/layout'

const routes: RouteConfig = [
    ...signin as RouteConfig[],
    {
        title: 'cmdw',
        name: 'inside',
        path: '/',
        render: (props: RouteConfigComponentProps<any>): React.ReactNode => <Layout
            { ...props }
            routes={ sites as RouteConfig[] }
        />
    }
]

export default routes
