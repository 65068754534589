import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Breadcrumb, Divider } from 'antd'
import { Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { Routes } from '#utils/router'
import localization, { type LT } from '#utils/localization'
import { type RowProps } from '#pages/orders/model'
import Row from './row'

const Main: React.FC<RowProps> = ({ match: { params: { uniqueId } } }) => {
    const history = useHistory()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'headerMenuQuestionsAndAnswers',
                        'detailTitle'
                    ]
                })
            )
        },
        [ lang.state ]
    )
    return <Row
        uniqueId={ uniqueId }
        header={
            <>
                <Breadcrumb
                    separator="/"
                    items={
                        [
                            {
                                className: 'cursor-pointer blue',
                                title: langDict.headerMenuQuestionsAndAnswers,
                                onClick: () => {
                                    history.push(Routes.questionsAndAnswers)
                                }
                            },
                            { title: langDict.detailTitle }
                        ]
                    }
                />
                <Divider className="divider" plain />
            </>
        }
    />
}

export default Main