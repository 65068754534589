import React, { useEffect, useRef } from 'react'
import { Button, Col, Row } from 'antd'
import { type CoordinatesList } from '#repositories/types/foundation'
import localization, { type LT } from '#utils/localization'
import useFetchState from '#repositories/models'
import { Language } from '#repositories/models/recoil-state'
import Loading from './loading'
import { image as imageCapthaApi } from '#repositories/apis/cptcha'
import { getElementOffset } from '#utils/events'
import { MessageType, MMessage } from '#components/hint'
import { d, isEmpty } from '#utils/functions'
import Icon from '#components/icon'
import { ReactComponent as Reload } from '#assets/svg/reload.svg'
import { ReactComponent as Close } from '#assets/svg/x.svg'

interface PropsType {
    completion: (dots: CoordinatesList, key: string) => void
    visible: boolean
}

interface dotItem {
    Dx: number
    Dy: number
    Width: number
    Height: number
}

interface CaptchaImageResp {
    chars: string[]
    image: string
    key: string
    dots: Record<number, dotItem>
}

const Main: React.FC<PropsType> = ({ completion, visible }) => {
    // image ref
    const imageRef = useRef(null)
    // localization
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const lang = new Language()
    const [ loading, setLoading ] = useFetchState(true)
    // 刷新验证码
    const [ refresh, setRefresh ] = useFetchState(false)
    // 接口返回数据
    const [ data, setData ] = useFetchState<CaptchaImageResp | null>(null)
    // 已选择的点
    const [ dots, setDots ] = useFetchState<CoordinatesList>([])
    // 原始点显示隐藏
    const [ originDots, setOriginDots ] = useFetchState(false)

    // 获取验证码
    const getImageCaptcha = (): void => {
        void imageCapthaApi().then(
            res => {
                setData(res.data as CaptchaImageResp)
                setLoading(false)
                setRefresh(false)
                setDots([])
            }
        )
    }

    // 点击图片文字获取坐标点
    const imageClick = (e: React.MouseEvent<HTMLImageElement>): void => {
        if (data === null) {
            return
        }

        if (dots.length >= data.chars.length) {
            return
        }

        const { current } = imageRef
        if (current === null) {
            return
        }

        const pos = getElementOffset(current as HTMLImageElement)
        setDots([
            ...dots,
            {
                x: e.clientX - pos.x,
                y: e.clientY - pos.y
            }
        ])
    }

    // 刷新验证码
    const refreshImage = (): void => {
        setRefresh(!refresh)
        getImageCaptcha()
    }

    // 清理已选择的点
    const clearDots = (): void => {
        setDots([])
    }

    // 提交
    const submit = (): void => {
        if (data === null) {
            return
        }

        if (dots.length !== data.chars.length) {
            MMessage({
                message: langDict.imageCaptchaBad,
                type: MessageType.warning
            })
            return
        }

        completion(dots, data.key)
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'imageCaptchaTitle',
                        'imageCaptchaBad',
                        'buttonContent'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            getImageCaptcha()
        },
        [ visible ]
    )

    return <div className="captcha-image">{
        loading
            ? <Loading />
            : <Row gutter={ [ 0, 16 ] }>
                <Col span={ 24 } className="flex-start c-item title">
                    { langDict.imageCaptchaTitle }:
                    {
                        data === null
                            ? ''
                            : data.chars.map(
                                (item, key) => <b key={ key }>{ item }</b>
                            )
                    }
                </Col>
                <Col span={ 24 } className="captcha-image-box relative">
                    <img
                        ref={ imageRef }
                        src={ data === null ? '' : data.image }
                        onClick={ imageClick }
                        alt=""
                    />
                    {
                        dots.map(
                            (item, key) => <span
                                key={ key }
                                className="dot fade-in"
                                style={
                                    {
                                        left: item.x - 10,
                                        top: item.y - 20
                                    }
                                }
                            >{ key + 1 }</span>
                        )
                    }
                    {
                        data === null || !originDots
                            ? ''
                            : (
                                isEmpty(data.dots)
                                    ? ''
                                    : (Object.values(data.dots)).map(
                                        (item, key) => <span
                                            key={ key }
                                            style={
                                                {
                                                    width: item.Width + 'px',
                                                    height: item.Height + 'px',
                                                    display: 'block',
                                                    position: 'absolute',
                                                    top: (item.Dy - item.Height) + 'px',
                                                    left: (item.Dx) + 'px',
                                                    background: 'rgba(0,0,0,0.2)'
                                                }
                                            }
                                        />
                                    )
                            )
                    }
                </Col>
                <Col span={ 24 } className="flex-end">
                    <Col span={ 12 } className="flex-start c-item sp">
                        <Icon tap onClick={ clearDots }><Close /></Icon>
                        <Icon tap className={ `refresh${ refresh ? ' rotating-ele' : '' }` } onClick={ refreshImage }><Reload /></Icon>
                    </Col>
                    <Col span={ 12 } className="flex-end c-item">
                        {
                            d()
                                ? <Button
                                    onClick={
                                        () => {
                                            setOriginDots(!originDots)
                                        }
                                    }
                                    type="primary"
                                    className="submit origin-dots-button"
                                >Origin Dots</Button>
                                : ''
                        }
                        <Button
                            onClick={ submit }
                            type="primary"
                            className="submit background-button"
                        >{ langDict.buttonContent }</Button>
                    </Col>
                </Col>
            </Row>
    }</div>
}

export default Main