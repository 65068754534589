import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { type AxiosResponse } from 'axios'
import { Col, Input, type InputRef, Row, Button } from 'antd'
import { productDetailRoutePath, Routes, upgradeRoutePath } from '#utils/router'
import localization, { type LT } from '#utils/localization'
import { isEmail } from '#utils/patterns'
import { documentTitle } from '#utils/configs'
import { isEmpty } from '#utils/functions'
import { row, type RowResp } from '#repositories/apis/documents'
import useFetchState from '#repositories/models'
import { Configs, Language, Profile, SignInPopupVisible } from '#repositories/models/recoil-state'
import { makeOrder, makeOrderGuestCheckout, type MakerOrderResp, type NetworkDisk } from '#repositories/apis/orders'
import Loading from '#components/loading'
import { MessageType, MMessage } from '#components/hint'
import Icon from '#components/icon'
import { OrderType, PayType, UserLevelType, variables } from '#constants/appoint'
import { ReactComponent as ArrowLeft } from '#assets/svg/arrow-left.svg'
import { ReactComponent as Email } from '#assets/svg/email.svg'
import { ReactComponent as Balance } from '#assets/svg/balance.svg'
import { ReactComponent as Alipay } from '#assets/svg/alipay.svg'
import { type RowProps } from './model'
import NetworkDiskTmp from './network-disk'

const Main: React.FC<RowProps> = props => {
    const configs = new Configs()
    const configState = configs.shared()
    const history = useHistory()
    const signInVisible = new SignInPopupVisible()
    const profile = new Profile()
    const [ networkDiskStep, setNetworkDiskStep ] = useFetchState(0)
    const [ networkDisk, setNetworkDisk ] = useFetchState<NetworkDisk[]>([])
    const [ buyLoading, setBuyLoading ] = useFetchState(false)
    const [ disabled, setDisabled ] = useFetchState(false)
    const [ loading, setLoading ] = useFetchState(true)
    // 产品详情
    const [ data, setData ] = useFetchState<RowResp | null>(null)
    // 语言
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 邮箱地址
    const emailRef = useRef<InputRef>(null)
    const [ email, setEmail ] = useFetchState(profile.shared()?.email ?? '')
    // 支付方式
    const [ payMethod, setPayMethod ] = useFetchState<PayType>(PayType.ali)
    const onPay = (): void => {
        const guestCheckout = data?.row.extension.guestCheckout ?? false
        // 创建订单
        const makeOrderCall = (): void => {
            if (disabled) {
                return
            }

            if (email !== '' && !isEmail(email)) {
                MMessage({
                    type: MessageType.error,
                    message: langDict.emailFormRuleMsg
                })

                if (emailRef.current !== null) {
                    emailRef.current.focus()
                }
                return
            }

            setBuyLoading(true)
            const params = {
                payType: payMethod,
                type: OrderType.product,
                uniqueId: data?.row.uniqueId ?? '',
                email
            }
            const then = (res: AxiosResponse): void => {
                setDisabled(true)
                const data = res.data as MakerOrderResp
                if (data.url !== '' && !isEmpty(data.url)) {
                    window.location.href = data.url
                    return
                }

                if (data.networkDisk !== undefined && data.networkDisk.length > 0) {
                    setNetworkDisk(data.networkDisk)
                    setDisabled(false)
                }
            }
            const finallyCall = (): void => {
                setBuyLoading(false)
            }

            if (profile.state === null && guestCheckout) {
                void makeOrderGuestCheckout(params).then(then).finally(finallyCall)
                return
            }

            void makeOrder(params).then(then).finally(finallyCall)
        }

        // 检测登录状态
        if (profile.state === null && !guestCheckout) {
            signInVisible.set(true, _ => {
                MMessage({
                    message: langDict.loginSuccessWidth,
                    type: MessageType.success
                })
            })
            return
        }

        makeOrderCall()
    }
    // 购买按钮
    const makeButton = (data: RowResp): React.ReactNode => {
        const login = (): void => {
            signInVisible.set(true, _ => {
                MMessage({
                    message: langDict.loginSuccessWidth,
                    type: MessageType.success
                })
            })
        }
        const params = {
            className: 'cursor-pointer font-center buy-button',
            disabled,
            loading: buyLoading,
            onClick: onPay
        }
        const ExclusiveParams = {
            className: 'cursor-pointer font-center buy-button',
            onClick: (): void => {
                history.push(upgradeRoutePath())
            }
        }

        // 登录后免费获取
        if (data.row.price <= 0 && profile.state === null) {
            return <Button
                className="cursor-pointer font-center buy-button"
                onClick={ login }
            >{ langDict.loginWithFreeGet }</Button>
        }

        // 永久会员限定
        if (data.row.exclusiveType === UserLevelType.permanent && data.userLevel !== UserLevelType.permanent) {
            return <Button { ...ExclusiveParams }>{ langDict.permanentExclusive }</Button>
        }

        // vip会员限定
        if (data.row.exclusiveType === UserLevelType.pro && data.userLevel !== UserLevelType.pro) {
            return <Button { ...ExclusiveParams }>{ langDict.vipExclusive }</Button>
        }

        // 免登录购买
        if (data.row.price > 0 && profile.state === null && data.row.extension.guestCheckout) {
            return <Button { ...params }>{ langDict.guestCheckoutWithBuy }</Button>
        }

        return <Button { ...params }>{ langDict.buy }</Button>
    }

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'orderConfirm',
                    'loginWithFreeGet',
                    'networkTitle',
                    'clickToCopy',
                    'copySuccess',
                    'networkCodeTitle',
                    'decryptionCodeTitle',
                    'installDecompressionCodeTitle',
                    'networkDiskTitle',
                    'goodsName',
                    'amount',
                    'buyExplainTitle',
                    'buyExplainContent',
                    'payMethod',
                    'emailFormRuleMsg',
                    'vipExclusive',
                    'permanentExclusive',
                    'orderEmailPlaceholder',
                    'loginSuccessWidth',
                    'buy',
                    'gold',
                    'success',
                    'goBack',
                    'loginWithBuy',
                    'guestCheckoutWithBuy',
                    'payMethodAlipay',
                    'payMethodBalance',
                    'email'
                ]
            })
            setLangDict(langDict)
            documentTitle(langDict.orderConfirm)
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            if (configState?.downloadLimitState === true && variables.orderPreConfirmationId !== props.match.params.uniqueId) {
                history.push(productDetailRoutePath(props.match.params.uniqueId))
            }
        },
        [ configState ]
    )

    useEffect(
        () => {
            void row(props.match.params.uniqueId, '').then(
                res => {
                    const data = res.data as RowResp
                    setData(data)
                    if (data.row.extension.networkDisk !== undefined && !isEmpty(data.row.extension.networkDisk) && data.row.extension.networkDisk.length > 0) {
                        setNetworkDisk(data.row.extension.networkDisk)
                        setNetworkDiskStep(1)
                    }
                }
            ).catch(
                err => {
                    console.log(err)
                    history.push(Routes.products)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        },
        [ props.match.params.uniqueId ]
    )

    useEffect(
        () => {
            setEmail(profile.state?.email ?? '')
        },
        [ profile.state ]
    )

    return <div className="order-confirmation-container">
        {
            loading || data === null
                ? <Loading />
                : <>{
                    networkDisk.length > 0
                        ? <NetworkDiskTmp
                            lang={ lang }
                            langDict={ langDict }
                            networkDisk={ networkDisk }
                            title={ data.row.title }
                            callback={
                                () => {
                                    if (networkDiskStep === 1) {
                                        history.goBack()
                                    }

                                    setNetworkDisk([])
                                }
                            }
                        />
                        : <div className="box">
                            <div
                                className="cursor-pointer go-back"
                                onClick={
                                    () => {
                                        history.goBack()
                                    }
                                }
                            >
                                <Icon tap className="i-4x"><ArrowLeft /></Icon>
                                <span className="font">{ langDict.goBack }</span>
                            </div>
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.goodsName }:</Col>
                                <Col span={ 20 } className="content goods-name">{ data.row.title }</Col>
                            </Row>
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.amount }:</Col>
                                <Col span={ 20 } className="content amount">
                                    {
                                        data.row.extension !== undefined && data.row.extension.promoEndDate > variables.timestamp
                                            ? <span className="promo-price"><s>{ data.row.price }</s>{ data.row.extension.promoPrice }</span>
                                            : <span>{ data.row.price }</span>
                                    }
                                    { langDict.gold }
                                </Col>
                            </Row>
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.buyExplainTitle }:</Col>
                                <Col span={ 20 } className="content">{ langDict.buyExplainContent }</Col>
                            </Row>
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.payMethod }:</Col>
                                <Col span={ 20 } className="content pay-method">
                                    <div
                                        className={ `alipay${ payMethod === PayType.ali ? ' active' : '' }` }
                                        onClick={ () => { setPayMethod(PayType.ali) } }
                                    >
                                        <Icon><Alipay /></Icon>
                                        <span>{ langDict.payMethodAlipay }</span>
                                    </div>
                                    {
                                        data.balance > 0 && profile.state !== null
                                            ? (
                                                data.balance >= data.row.price
                                                    ? <div
                                                        className={ `balance${ payMethod === PayType.balance ? ' active' : '' }` }
                                                        onClick={ () => { setPayMethod(PayType.balance) } }
                                                    >
                                                        <Icon><Balance /></Icon>
                                                        <span className="font">
                                                            { langDict.payMethodBalance }
                                                            <em>{ data.balance } { langDict.gold }</em>
                                                        </span>
                                                    </div>
                                                    : <div className="balance cursor-disabled">
                                                        <Icon><Balance /></Icon>
                                                        <span className="font">
                                                            { langDict.payMethodBalance }
                                                            <em>{ data.balance } { langDict.gold }</em>
                                                        </span>
                                                    </div>
                                            )
                                            : ''
                                    }
                                </Col>
                            </Row>
                            <Row className="item">
                                <Col span={ 4 } className="title">{ langDict.email }:</Col>
                                <Col span={ 14 } className="content">
                                    <Input
                                        ref={ emailRef }
                                        className="email"
                                        value={ email }
                                        allowClear
                                        prefix={ <Icon tap><Email /></Icon> }
                                        placeholder={ langDict.orderEmailPlaceholder }
                                        onChange={
                                            e => {
                                                setEmail(e.target.value.trim())
                                            }
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row className="item buy">
                                <Col offset={ 4 } span={ 14 } className="content">{ makeButton(data) }</Col>
                            </Row>
                        </div>
                }</>
        }
    </div>
}

export default Main