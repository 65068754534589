import { type AxiosResponse } from 'axios'
import { service, proxy, route } from '#utils/axios'

export enum CaptchaType {
    findPwd = 'findPwd',
    bind = 'bind'
}

export const image = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.tool, '/v1/captcha/image'),
        method: 'get'
    })
}

export const email = async <T>(email: string, type: CaptchaType): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.tool, '/v1/captcha/email'),
        method: 'post',
        data: { email, type }
    })
}
