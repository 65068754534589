import React from 'react'
import { type SigninType, PageType } from '#components/signin/model'
import Login from '#components/signin/login'
import Register from '#components/signin/register'
import FindPassword from '#components/signin/find-password'
import SetPassword from '#components/signin/set-password'
import Image from '#components/image'
import BackgroundImage from '#assets/images/background.jpg'

const Background = <div className="background">
    <Image original src={ BackgroundImage } className="fill" alt="" />
</div>

const Main: React.FC<SigninType> = ({ type }) => {
    switch (type) {
        case PageType.register:
            return <div className="sign-in relative">
                <Register popup={ false } />
                { Background }
            </div>
        case PageType.findPassword:
            return <div className="sign-in relative">
                <FindPassword popup={ false } />
                { Background }
            </div>
        case PageType.setPassword:
            return <div className="sign-in relative">
                <SetPassword popup={ false } />
                { Background }
            </div>
        // case PageType.login:
        default:
            return <div className="sign-in relative">
                <Login popup={ false } />
                { Background }
            </div>
    }
}

export default Main