import { type OrderType } from '#constants/appoint'

interface errors {
    code: number
    message?: string
}

export interface ResponseType {
    // 错误码
    code: number
    // 服务器时间戳
    timestamp: number
    // data
    data?: any
    // 续期token
    token?: string
    // 提示信息
    message?: string
    errors?: errors
    node?: string
    version?: number
    logout?: boolean
}

export enum LevelPriceDifferenceType {
    permanent = 'permanent',
    year = 'year'
}

export interface LevelPriceDifferenceResp {
    type: OrderType
    price: Record<LevelPriceDifferenceType, number>
}