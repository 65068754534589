import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Input, Row, Checkbox, Form } from 'antd'
import localization, { type LT } from '#utils/localization'
import { type CoordinatesList } from '#repositories/types/foundation'
import useFetchState from '#repositories/models'
import { Configs, Language, Profile, SignInPopup, SignInPopupType } from '#repositories/models/recoil-state'
import { type LoginRespType, register } from '#repositories/apis/user'
import { CModals } from '#components/popup'
import CaptchaImage from '#components/captcha-image'
import Location from '#components/location'
import { MessageType, MMessage } from '#components/hint'
import Icon from '#components/icon'
import Logo from '#components/logo'
import { type RegisterFieldType, passwordRule, wrapperCol, privacyRule, rePasswordRule, usernameRegisterRule, emailRegRule, type SignInProps, getConfigs, loginCall } from './model'
import { ReactComponent as User } from '#assets/svg/user.svg'
import { ReactComponent as Lock } from '#assets/svg/lock.svg'
import { ReactComponent as Email } from '#assets/svg/email.svg'
import Share from '#utils/share'
import { articleDetailRoutePath } from '#utils/router'

const { Item } = Form

const Main: React.FC<SignInProps> = ({ popup, callback }) => {
    const profile = new Profile()
    const signInPopup = new SignInPopup()
    const history = useHistory()
    const formRef = useRef<RegisterFieldType>()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 是否显示图形验证码
    const [ visible, setVisible ] = useFetchState(false)
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    const configs = new Configs()
    const submit = (values: RegisterFieldType): void => {
        formRef.current = values
        setVisible(true)
    }

    const captchaImageCompletion = (dots: CoordinatesList, key: string): void => {
        setVisible(false)
        setLoading(true)
        void register<any>({
            username: formRef.current?.username ?? '',
            password: formRef.current?.password ?? '',
            email: formRef.current?.email ?? '',
            dots,
            key,
            shareOriginal: new Share().getSecret()
        }).then(
            res => {
                MMessage({
                    message: langDict.registerSuccess,
                    type: MessageType.success
                })

                const data = res.data as LoginRespType
                loginCall({ history, popup, data, profile, callback })
                new Share().clearSecret()
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    useEffect(
        () => {
            getConfigs(configs)
        },
        []
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'usernamePlaceholder',
                        'registerSuccess',
                        'emailPlaceholder',
                        'pwdPlaceholder',
                        'usernameFormRuleMsg',
                        'usernameFormRuleValidFailedMsg',
                        'passwordFormRuleMsg',
                        'rePasswordFormRuleMsg',
                        'usernameCompleteFormRuleMsg',
                        'loginLinkTipsPrefix',
                        'emailCompleteFormRuleMsg',
                        'emailFormRuleMsg',
                        'privacyFormRuleMsg',
                        'rePwdPlaceholder',
                        'register',
                        'registerJoinUs',
                        'loginLinkTips',
                        'privacy'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <div className={ popup ? 'popup-center-box' : 'wh100 relative' }>
        {
            !visible
                ? ''
                : <CModals
                    visible={ visible }
                    setVisible={ setVisible }
                    footer={ null }
                    width={ 374 }
                    content={
                        <CaptchaImage visible={ visible } completion={ captchaImageCompletion }/>
                    }
                />
        }
        <div className={ 'fade-in signin-box register' + (popup ? '' : ' abs-center') }>
            <Logo />
            <Row>
                <Col span={ 18 } offset={ 3 } className="header">
                    <Col span={ 22 } className="h1">
                        <h3 className="font-center h1">{ langDict.register }</h3>
                    </Col>
                </Col>
            </Row>
            <Form name="basic" initialValues={ { remember: true } } onFinish={ submit } autoComplete="off">
                <Item<RegisterFieldType>
                    name="username"
                    rules={ usernameRegisterRule(langDict.usernameFormRuleMsg, langDict.usernameFormRuleValidFailedMsg, langDict.usernameCompleteFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input
                        className="username"
                        allowClear
                        prefix={ <Icon tap><User /></Icon> }
                        placeholder={ langDict.usernamePlaceholder }
                    />
                </Item>
                <Item<RegisterFieldType>
                    name="password"
                    rules={ passwordRule(langDict.passwordFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input.Password
                        className="password"
                        prefix={ <Icon><Lock /></Icon> }
                        visibilityToggle={ { visible: false } }
                        placeholder={ langDict.pwdPlaceholder }
                    />
                </Item>
                <Item<RegisterFieldType>
                    name="rePassword"
                    rules={ rePasswordRule(langDict.rePasswordFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input.Password
                        className="password"
                        prefix={ <Icon><Lock /></Icon> }
                        visibilityToggle={ { visible: false } }
                        placeholder={ langDict.rePwdPlaceholder }
                    />
                </Item>
                <Item<RegisterFieldType>
                    name="email"
                    rules={ emailRegRule(langDict.emailFormRuleMsg, langDict.emailCompleteFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input
                        className="email"
                        allowClear
                        prefix={<Icon><Email /></Icon> }
                        placeholder={ langDict.emailPlaceholder }
                    />
                </Item>
                <Item wrapperCol={ wrapperCol } className="mb0">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="submit background-button"
                        loading={ loading }
                    >{ langDict.register }</Button>
                </Item>
                <Item<RegisterFieldType>
                    className="mb0 convenient-ext"
                    name="privacy"
                    wrapperCol={ wrapperCol }
                    rules={ privacyRule(langDict.privacyFormRuleMsg) }
                >
                    <div className="wh100 flex">
                        <span>{ langDict.loginLinkTipsPrefix }</span>
                        {
                            popup
                                ? <span
                                    onClick={
                                        () => {
                                            signInPopup.set(SignInPopupType.login)
                                        }
                                    }
                                    className="link-color"
                                >{ langDict.loginLinkTips }</span>
                                : <Location to="/login" className="link-color">{ langDict.loginLinkTips }</Location>
                        }
                        <Checkbox className="color-cb privacy">
                            <Location
                                target="_blank"
                                to={ articleDetailRoutePath('doc110') }
                            >{ langDict.privacy }</Location>
                        </Checkbox>
                    </div>
                </Item>
            </Form>
        </div>
    </div>
}

export default Main