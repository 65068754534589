import React from 'react'
import { type CSSStyle } from '#repositories/types/foundation'
import { Image } from 'antd'
import useFetchState from '#repositories/models'
import { d } from '#utils/functions'

interface PropsType {
    className?: string
    src: string
    alt?: string
    style?: CSSStyle
    preview?: boolean
    product?: boolean
    original?: boolean
}

const Main: React.FC<PropsType> = props => {
    const [ className, setClassName ] = useFetchState('')
    const onError = (): void => {
        setClassName('image-err')
    }

    const src = (): string => {
        if (props.original !== undefined && props.original) {
            return props.src
        }

        if (props.src.indexOf('http') !== 0 && props.product === true) {
            if (props.src.includes('/')) {
                return process.env.REACT_APP_OSS_FILE_URL + '/' + props.src.replace(/^\\|\\$/g, '')
            }

            // 未分目录的文件(旧项目创建的文件) oss文件转移到服务器
            if (d()) {
                return process.env.REACT_APP_OSS_FILE_URL_CLONED + process.env.REACT_APP_OLD_OSS_DIR + props.src
            }
            return process.env.REACT_APP_OLD_OSS_DIR + props.src
            // return process.env.REACT_APP_OSS_FILE_URL + process.env.REACT_APP_OLD_OSS_DIR + props.src
        }

        return props.src
    }

    return <div className={ 'wh100 image-box' + (className !== '' ? ' ' + className : '') }>
        <Image
            className={ props.className }
            style={ props.style ?? {} }
            src={ src() }
            alt={ props.alt }
            preview={ props.preview ?? false }
            onError={ onError }
            fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wDAAwAB/luBmQAAAABJRU5ErkJggg=="
        />
    </div>
}

export default Main