import React, { useEffect } from 'react'
import { Button, Form, Input } from 'antd'
import { Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { substationApplyFor, type SubstationApplyForFields } from '#repositories/apis/user'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { ReactComponent as User } from '#assets/svg/user.svg'
import { ReactComponent as SmartPhone } from '#assets/svg/smart-phone.svg'
import { mobileRegRule, wrapperCol } from '#pages/profile/model'
import { MessageType, MMessage } from '#components/hint'
import Icon from '#components/icon'
import { copyToClipboard } from '#utils/functions'

const { Item } = Form
const { TextArea } = Input

const Main: React.FC = () => {
    const lang = new Language()
    const configState = new Configs().shared()
    const [ loading, setLoading ] = useFetchState(false)
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    const submit = (values: SubstationApplyForFields): void => {
        setLoading(true)
        void substationApplyFor(values).then(
            () => {
                MMessage({
                    message: langDict.substationApplyForSuccess,
                    type: MessageType.success
                })
            }
        ).finally(
            () => {
                setTimeout(
                    () => {
                        setLoading(false)
                    },
                    1000
                )
            }
        )
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'wechatNum',
                        'copySuccess',
                        'webmasterContact',
                        'siteContactQQ',
                        'siteContactWechat',
                        'substationApplyForSuccess',
                        'loginSubmit',
                        'usernamePlaceholder',
                        'substationTitle',
                        'substation',
                        'remarkPlaceholder',
                        'mobileFormRuleMsg',
                        'mobilePlaceholder'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <div className="substation-container">
        {
            configState !== null
                ? <div className="contact">
                    <span className="title">
                        { langDict.webmasterContact }:
                    </span>
                    <p>
                        <span
                            onClick={
                                () => {
                                    copyToClipboard(configState.siteContactQQ, () => {
                                        MMessage({
                                            message: langDict.copySuccess,
                                            type: MessageType.success
                                        })
                                    })
                                }
                            }
                        ><b className="blue">{ configState.siteContactQQ } </b>({ langDict.wechatNum })</span>
                        <span
                            onClick={
                                () => {
                                    copyToClipboard(configState.siteContactWechat, () => {
                                        MMessage({
                                            message: langDict.copySuccess,
                                            type: MessageType.success
                                        })
                                    })
                                }
                            }
                        ><b className="blue">{ configState.siteContactWechat } </b>(QQ)</span>
                    </p>
                </div>
                : ''
        }
        <Form name="basic" onFinish={ submit } className="w100" autoComplete="off">
            <Item<SubstationApplyForFields>
                name="name"
                rules={ [ { required: true, message: langDict.usernamePlaceholder } ] }
                wrapperCol={ wrapperCol }
            >
                <Input
                    allowClear
                    prefix={ <Icon tap><User /></Icon> }
                    placeholder={ langDict.usernamePlaceholder }
                />
            </Item>
            <Item<SubstationApplyForFields>
                name="mobile"
                rules={ mobileRegRule(langDict.mobileFormRuleMsg) }
                wrapperCol={ wrapperCol }
            >
                <Input
                    allowClear
                    prefix={ <Icon tap><SmartPhone /></Icon> }
                    placeholder={ langDict.mobilePlaceholder }
                />
            </Item>
            <Item<SubstationApplyForFields> name="remark" wrapperCol={ wrapperCol }>
                <TextArea
                    allowClear
                    maxLength={ 200 }
                    placeholder={ langDict.remarkPlaceholder }
                    autoSize={ { minRows: 4, maxRows: 7 } }
                />
            </Item>
            <Item wrapperCol={ wrapperCol } className="mb0">
                <Button
                    type="primary"
                    htmlType="submit"
                    className="submit background-button"
                    loading={ loading }
                >{ langDict.loginSubmit }</Button>
            </Item>
        </Form>
        <p className="recharge-title">{ langDict.substationTitle }</p>
        {
            configState !== null
                ? <ul
                    className="recharge"
                    dangerouslySetInnerHTML={
                        {
                            __html: pickLangKey(
                                lang.state,
                                configState.substationInstructions
                            ).split('\n').filter(
                                item => item.trim() !== ''
                            ).map(
                                item => `<li>${ item }</li>`
                            ).join('')
                        }
                    }
                />
                : ''
        }
    </div>
}

export default Main