import type { SortBy as SortByEnum } from '#constants/appoint'

export interface SortBy {
    column: string
    value: SortByEnum
}

export enum Operator {
    eq = '=',
    lt = '<',
    lte = '<=',
    gt = '>',
    gte = '>=',
    neq = '!=',
    in = 'IN',
    notin = 'notin',
    jai = 'ai',
    jac = 'aic',
    jal = 'ail',
    match = 'match',
    matchPhrase = 'match_phrase',
    fulltextBoolMatch = 'fbm'
}

export enum OperatorOuter {
    and = 'and',
    or = 'or'
}

export interface Condition {
    column: string
    value?: any
    values?: any[]
    operator?: Operator
    'outer-opr'?: OperatorOuter
}

export interface Params {
    limit: number
    page: number
    sortBy?: SortBy[]
    conditions?: Condition[]
    ids?: number[]
    keyword?: string
    random?: boolean
}

export interface UpdateItem {
    column: string
    value: string
}