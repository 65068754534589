import React, { type ReactNode, useEffect } from 'react'
import { Divider } from 'antd'
import Loading from '#components/loading'
import Icon from '#components/icon'
import Location from '#components/location'
import { MessageType, MMessage } from '#components/hint'
import { type Lang, type LT, pickLangKey, pickSevLangKey } from '#utils/localization'
import { copyToClipboard, execFunc, isEmpty, timestampFormat } from '#utils/functions'
import { orderRowRoutePath } from '#utils/router'
import { ReactComponent as IconDatabase } from '#assets/svg/database.svg'
import { ReactComponent as ShoppingCart } from '#assets/svg/shopping-cart.svg'
import { ReactComponent as BasketShare } from '#assets/svg/basket-share.svg'
import { ReactComponent as BasketDollar } from '#assets/svg/basket-dollar.svg'
import { ReactComponent as CoinYen } from '#assets/svg/coin-yen.svg'
import { ReactComponent as Gift } from '#assets/svg/gift.svg'
import { dateFormat1 } from '#constants/appoint'
import { activationCodeRow, RechargeType } from '#pages/profile/model'
import { type SevConfLangMaps } from '#repositories/types/lang'
import { Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { type ActivationCodeItem, type ListItem, type StatisticsResp } from '#repositories/apis/wallet'
import Logo from '#components/logo'

export interface CardsProps {
    langDict: LT
    statisticsResp: StatisticsResp
    loading: boolean
}

export const Cards: React.FC<CardsProps> = ({ langDict, statisticsResp, loading }) => <div className="cards">
    <div className="item">
        <div className="money">
            <p>{ statisticsResp.balance } { langDict.gold }</p>
            <p>{ langDict.cardBalance }</p>
        </div>
        <Icon className="i-4x"><IconDatabase /></Icon>
    </div>
    <div className="item">
        <div className="money">
            <p>{ statisticsResp.income } { langDict.gold }</p>
            <p>{ langDict.cardIncomeTitle }</p>
        </div>
        <Icon className="i-4x"><Gift /></Icon>
    </div>
    <div className="item">
        <div className="money">
            <p>{ statisticsResp.withdraw } { langDict.gold }</p>
            <p>{ langDict.cardWithdrawTitle }</p>
        </div>
        <Icon className="i-4x"><BasketShare /></Icon>
    </div>
    { loading ? <Loading /> : '' }
</div>

export interface WalletItemProps {
    item: ListItem
    langDict: LT
    isLast: boolean
    active: boolean
    setCardVisible?: (data: any) => void
    setActiveUniqueId?: (data: any) => void
}

export const WalletItem: React.FC<WalletItemProps> = (
    {
        item,
        langDict,
        isLast,
        active,
        setCardVisible,
        setActiveUniqueId
    }
) => {
    const lang = new Language()

    let icon = <></>
    let amount = 0
    let title = ''
    if (item.recharge > 0) {
        amount = item.recharge
        icon = <BasketDollar />
        title = langDict.cardRechargeTitle
    }

    if (item.outcome > 0) {
        amount = item.outcome
        icon = <ShoppingCart />
        title = langDict.cardOutcomeTitle
    }

    if (item.withdraw > 0) {
        amount = item.withdraw
        icon = <BasketShare />
        title = langDict.cardWithdrawTitle
    }

    if (item.income > 0) {
        amount = item.income
        icon = <Gift />
        title = langDict.cardIncomeTitle
    }

    const inner = (): ReactNode => {
        if (item.rechargeType === RechargeType.card) {
            return <>
                <span>{ langDict.cardNumber }: </span>
                <span
                    className="cursor-pointer blue"
                    onClick={
                        () => {
                            execFunc(setActiveUniqueId, item.orderUniqueId)
                            execFunc(setCardVisible, true)
                        }
                    }
                >{ item.orderUniqueId }</span>
            </>
        }

        if (item.rechargeType === RechargeType.shareAward) {
            return <span>{ langDict.shareCumulativeRewards }</span>
        }

        if (item.rechargeType === RechargeType.backend) {
            return <span>{ langDict.backgroundRecharge }</span>
        }

        return item.orderUniqueId === ''
            ? ''
            : <>
                <span>{ langDict.orderNumber }: </span>
                <Location to={ orderRowRoutePath(item.orderUniqueId) }>
                    { item.orderUniqueId }
                </Location>
            </>
    }

    return <>
        <div className={ `item${ active ? ' active' : '' }` }>
            <Icon className="i-5x">{ icon }</Icon>
            <div className="info">
                <div className={ `money ${ lang.state }` }>{ amount } </div>
                <div className="title">[ { title } ]</div>
                <div className="order">{ inner() }</div>
                <div className="createdAt">{ timestampFormat(item.createdAt, dateFormat1) }</div>
            </div>
        </div>
        { isEmpty(item.remark) ? '' : <p className="wallet-remark">{ item.remark }</p> }
        { isLast ? '' : <Divider className="divider" plain /> }
    </>
}

export interface ActivationCodeCardProps {
    data: ActivationCodeItem | null
    langState: Lang
    langDict: LT
    fetchDataInit?: boolean
    code: string
}

export const ActivationCodeCard: React.FC<ActivationCodeCardProps> = (
    {
        data,
        fetchDataInit,
        langState,
        langDict,
        code
    }
) => {
    const configs = new Configs()
    // num unit maps
    const [ numUnitMaps, setNumUnitMaps ] = useFetchState<SevConfLangMaps>({})
    const [ dataState, setData ] = useFetchState<ActivationCodeItem | null>(data)
    const [ loading, setLoading ] = useFetchState(false)

    useEffect(
        () => {
            const data = configs.state
            if (data === null || isEmpty(data.cardNumUnitTypes)) {
                return
            }

            const maps: SevConfLangMaps = {}
            for (let i = 0; i < data.cardNumUnitTypes.length; i++) {
                const item = data.cardNumUnitTypes[ i ]
                maps[ item.id ] = item
            }
            setNumUnitMaps(maps)
        },
        [ configs.state ]
    )

    useEffect(
        () => {
            setData(data)
            if (fetchDataInit !== true) {
                return
            }

            setLoading(true)
            // 获取卡密
            activationCodeRow({
                code,
                setData,
                setLoading,
                langDict,
                disabledIsUsedError: true
            })
        },
        [ data ]
    )

    return <div className="vip-card-box flex-cc">
        <div className="dot1" />
        <div className="dot2" />
        <div className="vip-card1">
            <Logo />
            { loading ? <Loading /> : '' }
            <div className="wh100 content">
                <p className="title ellipsis-1">
                    { dataState === null || isEmpty(dataState?.title) ? '' : pickLangKey(langState, dataState?.title) }
                </p>
                <p className="brief ellipsis-5">
                    { dataState === null || isEmpty(dataState?.brief) ? '' : pickLangKey(langState, dataState?.brief) }
                </p>
                <p className="state">
                    { <em className="unsupported">{ dataState !== null && dataState?.userid !== 0 ? langDict.activationCardIsUseState : '' }</em> }
                    {
                        dataState === null || isEmpty(dataState?.type)
                            ? ''
                            : <>{
                                dataState.type === 2
                                    ? <>
                                        <span className="t">{ langDict.cardValidity }: </span>
                                        <span className="v">{ dataState.num }{ pickSevLangKey(langState, numUnitMaps[ dataState.numUnit ]) }</span>
                                    </>
                                    : <>
                                        <span className="t">{ langDict.cardFaceValue }: </span>
                                        <span className="v"><Icon className="i-4x"><CoinYen /></Icon>{ dataState.amount }</span>
                                    </>
                            }</>
                    }
                </p>
                <div className="card-number">
                    <span className="nt">{ dataState !== null ? langDict.cardNumber + ':' : '' } </span>
                    <span
                        className={ `num${ dataState !== null && !isEmpty(dataState.uniqueId) ? ' cursor-pointer' : '' }` }
                        onClick={
                            () => {
                                if (dataState !== null && !isEmpty(dataState.uniqueId)) {
                                    copyToClipboard(dataState.uniqueId)
                                    MMessage({
                                        message: langDict.copySuccess,
                                        type: MessageType.success
                                    })
                                }
                            }
                        }
                    >{ dataState?.uniqueId ?? '' }</span>
                </div>
            </div>
        </div>
    </div>
}