import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Divider, Input, Row } from 'antd'
import { Language } from '#repositories/models/recoil-state'
import { activationCodeActivation, type ActivationCodeItem } from '#repositories/apis/wallet'
import useFetchState from '#repositories/models'
import Icon from '#components/icon'
import { MessageType, MMessage } from '#components/hint'
import localization, { type LT } from '#utils/localization'
import { textOnChange } from '#utils/events'
import { isSevUniqueId } from '#utils/patterns'
import { RouteProfile } from '#utils/router'
import { ReactComponent as Code } from '#assets/svg/code.svg'
import { defaultLimit } from '#constants/appoint'
import { type ActivationCodeButton, ActivationCodeButtonType, activationCodeRow } from '#pages/profile/model'
import { ActivationCodeCard } from '#pages/profile/components'

const Main: React.FC = () => {
    const history = useHistory()
    const lang = new Language()
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // 本地化
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // code
    const [ code, setCode ] = useFetchState('')
    // button state
    const [ buttonState, setButtonState ] = useFetchState<ActivationCodeButton>({
        disabled: true,
        title: '',
        type: ActivationCodeButtonType.disabled
    })
    const doSetCode = (code: string): void => {
        setCode(code)
        const state = isSevUniqueId(code)
        setButtonState({
            disabled: !state,
            title: langDict.fetchActivation,
            type: state ? ActivationCodeButtonType.fetch : ActivationCodeButtonType.disabled
        })
    }
    // data
    const [ data, setData ] = useFetchState<ActivationCodeItem | null>(null)
    // 按钮
    const fetchCode = (): void => {
        if (code === '') {
            MMessage({
                message: langDict.activationCardNumEmptyErr,
                type: MessageType.error
            })
            return
        }

        if (!isSevUniqueId(code)) {
            MMessage({
                message: langDict.activationCardNumFormatErr,
                type: MessageType.error
            })
            return
        }

        setLoading(true)
        if (buttonState.type === ActivationCodeButtonType.active) {
            // 激活卡密
            void activationCodeActivation(code).then(
                res => {
                    const id = res.data as number
                    const call = (url: string): void => {
                        MMessage({
                            message: langDict.activationCardActivationSuccess,
                            type: MessageType.success
                        })
                        // 充值卡 跳转充值页面
                        setTimeout(
                            () => {
                                history.push(url)
                            },
                            2000
                        )
                    }
                    if (data?.type === 1) {
                        call(`${ RouteProfile.wallet }/1/${ defaultLimit }/${ id }`)
                        return
                    }

                    // 会员卡
                    call(RouteProfile.membership)
                }
            )
            return
        }

        // 获取卡密
        activationCodeRow({ code, setData, setButtonState, setLoading, langDict })
    }

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'activation',
                    'copySuccess',
                    'activationCardNumEmptyErr',
                    'activationCardIsUseState',
                    'activationCardNumFormatErr',
                    'activationCardNumPlaceholder',
                    'activationCardIsUseStateErr',
                    'activationCardActivationSuccess',
                    'fetchActivation',
                    'cardNumber',
                    'cardValidity',
                    'cardFaceValue',
                    'activationCardNumErr'
                ]
            })
            setLangDict(langDict)
            setButtonState({ ...buttonState, title: langDict.fetchActivation })
        },
        [ lang.state ]
    )

    return <div className="activation-codes-container">
        <Row>
            <Col span="24">
                <div className="vip-card-box flex-cc">
                    <ActivationCodeCard
                        data={ data }
                        langDict={ langDict }
                        langState={ lang.state }
                        code={ code }
                    />
                </div>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col span={ 14 } offset={ 2 }>
                        <Input
                            allowClear
                            prefix={ <Icon><Code /></Icon> }
                            placeholder={ langDict.activationCardNumPlaceholder }
                            onChange={ textOnChange(doSetCode) }
                        />
                    </Col>
                    <Col offset={ 2 } span={ 4 }>
                        <Button
                            disabled={ buttonState.disabled }
                            className={ `submit background-button w100${ buttonState.disabled ? ' disabled' : ' primary' }` }
                            loading={ loading }
                            onClick={ fetchCode }
                        >{ buttonState.title }</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export default Main