import dict from '#utils/localization/dictionary'
import { type LangType, type SevConfLangItem } from '#repositories/types/lang'
import { isEmpty } from '#utils/functions'

export enum Lang {
    zh = 'zh',
    zhTW = 'zh_tw',
    en = 'en'
}

interface LocalizationPropsType {
    lang: Lang
    keys: string[]
}

export type LT = Record<string, string>

const Main = ({ lang, keys }: LocalizationPropsType): LT => {
    const data: LT = {}
    keys.forEach(
        item => {
            data[ item ] = dict[ lang ][ item ]
        }
    )

    return data
}

export default Main

export const pickLangKey = (key: Lang, item: LangType): string => {
    if (key === Lang.en) {
        return item.en
    }

    if (key === Lang.zhTW) {
        return item.zhTraditional
    }

    return item.zhSimplified
}

export const pickSevLangKey = (key: string, item: SevConfLangItem): string => {
    if (isEmpty(item)) {
        return '-'
    }

    if (key === Lang.en) {
        return item.title.en
    }

    if (key === Lang.zhTW) {
        return item.title.mzh
    }

    return item.title.zh
}
