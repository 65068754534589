import { configs, type ConfigsType } from '#repositories/apis/base'
import store from '#utils/store'
import { isObject } from '#utils/functions'

const configCacheKey: string = 'configs'

export function conf(callback: (data: ConfigsType) => void): void {
    const results = store.get(configCacheKey)
    if (isObject(results)) {
        const data = results as ConfigsType
        callback(data)
    }

    void configs<any>().then(
        res => {
            const data = res.data as ConfigsType
            store.set(configCacheKey, data)
            callback(data)
        }
    )
}

export const documentTitle = (title: string = ''): void => {
    document.title = `${ process.env.REACT_APP_TITLE }${ title !== '' ? ' - ' + title : '' }`
}