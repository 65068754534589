import React, { type ReactElement, useEffect } from 'react'
import { Divider, Empty } from 'antd'
import { useHistory } from 'react-router-dom'
import { defaultLimit } from '#constants/appoint'
import { isEmpty, timestampFormat, toNumber } from '#utils/functions'
import { scrollToTop } from '#utils/events'
import { RouteProfile } from '#utils/router'
import localization, { type LT } from '#utils/localization'
import { ReactComponent as CoinYen } from '#assets/svg/coin-yen.svg'
import Loading from '#components/loading'
import Pagination from '#components/page'
import Icon from '#components/icon'
import useFetchState from '#repositories/models'
import { Language, Userinfo } from '#repositories/models/recoil-state'
import { withdrawList, type WithdrawListItem, type WithdrawListResp } from '#repositories/apis/wallet'
import { type FavoritesProps, withdrawState } from '#pages/profile/model'

const Main: React.FC<FavoritesProps> = (props) => {
    const history = useHistory()
    const userinfo = new Userinfo().shared()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    // 页码
    const [ page, setPage ] = useFetchState(
        props.match.params.page !== undefined && !isEmpty(props.match.params.page)
            ? toNumber(props.match.params.page, 1)
            : 1
    )
    // 每页条数
    const [ limit, setLimit ] = useFetchState(
        props.match.params.limit !== undefined && !isEmpty(props.match.params.limit)
            ? toNumber(props.match.params.limit, defaultLimit)
            : defaultLimit
    )
    const [ loading, setLoading ] = useFetchState(false)
    const [ total, setTotal ] = useFetchState(0)
    const [ list, setList ] = useFetchState<WithdrawListItem[]>([])
    const state = (state: number): ReactElement => {
        if (state === withdrawState.done) {
            return <span className="blue state">{ langDict.withdrawStateDone }</span>
        }

        if (state === withdrawState.refused) {
            return <span className="red state">{ langDict.withdrawStateRefused }</span>
        }

        return <span className="state">{ langDict.withdrawStateWaiting }</span>
    }

    useEffect(
        () => {
            void withdrawList({ limit, page, conditions: [] }).then(
                res => {
                    const data = res.data as WithdrawListResp
                    setList(data.list)
                    setTotal(data.total)
                }
            ).then(
                () => {
                    setTimeout(
                        () => {
                            setLoading(false)
                        },
                        1000
                    )
                }
            )
        },
        [ userinfo, page, limit ]
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'withdrawAccount',
                        'withdrawName',
                        'amount',
                        'withdrawStateWaiting',
                        'withdrawStateDone',
                        'withdrawApplicationTime',
                        'withdrawStateRefused'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return loading
        ? <Loading />
        : <div className={ `withdraw-container${ list.length > 0 ? '' : ' empty-box' }` }>
            {
                list.length > 0
                    ? <>
                        <div className="withdraw-list">{
                            list.map(
                                (item, key) => <div className="item" key={ key }>
                                    <div className="content">
                                        <Icon className="i-4x"><CoinYen /></Icon>
                                        <span className="amount">{ langDict.amount }: <em>{ item.amounts }</em></span>
                                        <span className="name">{ langDict.withdrawAccount }: { item.name }</span>
                                        <span className="account">{ langDict.withdrawName }: { item.account }</span>
                                        { state(item.status) }
                                        <span className="created-at">{ langDict.withdrawApplicationTime }: <em>{ timestampFormat(item.createdAt) }</em></span>
                                    </div>
                                    { item.reason !== '' ? <p className="reason red">{ item.reason }</p> : '' }
                                    {
                                        list.length - 1 !== key
                                            ? <Divider className="divider" plain />
                                            : ''
                                    }
                                </div>
                            )
                        }</div>
                        <Pagination
                            className="pagination"
                            total={ total }
                            callback={
                                (current, pageSize) => {
                                    scrollToTop(200, () => {
                                        history.push(`${
                                            RouteProfile.withdraw
                                        }/${ current }/${ pageSize }`)
                                        setPage(current)
                                        setLimit(pageSize)
                                    })
                                }
                            }
                            page={ page }
                            pageSize={ limit }
                        />
                    </>
                    : <Empty />
            }
        </div>
}

export default Main