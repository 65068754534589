import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Layout, Popover, Row } from 'antd'
import Location from '#components/location'
import { Confirm, Modals, type ModalsProps } from '#components/hint'
import Search from '#components/search'
import useFetchState from '#repositories/models'
import { Configs, Language, Profile, SignInPopup, SignInPopupType, SignInPopupVisible, Theme } from '#repositories/models/recoil-state'
import localization, { Lang, type LT } from '#utils/localization'
import { createStyleLink, execFunc, isEmpty } from '#utils/functions'
import Login from '#components/signin/login'
import Register from '#components/signin/register'
import FindPassword from '#components/signin/find-password'
import SetPassword from '#components/signin/set-password'
import Avatar from '#components/avatar'
import { logout } from '#utils/token'
import { Routes } from '#utils/router'
import Icon from '#components/icon'
import Logo from '#components/logo'
import { ReactComponent as Game } from '#assets/svg/game.svg'
import { ReactComponent as Clock } from '#assets/svg/clock.svg'
import { ReactComponent as Vip } from '#assets/svg/vip.svg'
import { ReactComponent as Gift } from '#assets/svg/gift.svg'
import { ReactComponent as Moon } from '#assets/svg/moon.svg'
import { ReactComponent as Sun } from '#assets/svg/sun.svg'
import { ReactComponent as World } from '#assets/svg/world.svg'
import { ReactComponent as IconSearch } from '#assets/svg/search.svg'
import { ReactComponent as User } from '#assets/svg/user.svg'
import { ReactComponent as OrderSearch } from '#assets/svg/order-search.svg'
import { ReactComponent as HelpHexagon } from '#assets/svg/help-hexagon.svg'
import { ReactComponent as RouteAltRight } from '#assets/svg/route-alt-right.svg'
import { ReactComponent as Vip1 } from '#assets/svg/vip1.svg'
// import { ReactComponent as GameBox } from '#assets/svg/game-box.svg'
import { UserLevelType } from '#constants/appoint'

const { Header } = Layout
const dark = '/assets/css/antd.dark.css'
const themeLinkId = 'ant-dark-theme'

const handleTheme = (theme: string): void => {
    if (theme === Theme.dark) {
        createStyleLink(dark, themeLinkId)
    } else {
        const ele = document.getElementById(themeLinkId)
        if (ele !== null) {
            ele.remove()
        }
    }
}

const langOptions = [
    {
        title: '简体中文',
        val: Lang.zh
    },
    {
        title: '繁体中文',
        val: Lang.zhTW
    },
    {
        title: 'English',
        val: Lang.en
    }
]

interface MenusProps {
    langDict: LT
    pathname: string
}

interface MenuItemType {
    active: boolean
    className: string
    to: string
    children: React.ReactNode
}

const menus = ({ langDict, pathname }: MenusProps): MenuItemType[] => [
    {
        active: Routes.home.indexOf(pathname) === 0,
        className: 'menu home' + (Routes.home.indexOf(pathname) === 0 ? ' active' : ''),
        to: Routes.home,
        children: <>{ langDict.headerMenuHome }</>
    },
    {
        active: pathname !== Routes.home && (pathname.indexOf(Routes.products) === 0 || pathname.indexOf(Routes.product) === 0),
        className: 'menu all' + (
            pathname !== Routes.home &&
            (
                pathname.indexOf(Routes.products) === 0 ||
                pathname.indexOf(Routes.product) === 0
            )
                ? ' active'
                : ''
        ),
        to: Routes.products,
        children: <>
            <span className="menu-icon"><Icon><Game /></Icon></span>
            <span>{ langDict.headerMenuAllGames }</span>
        </>
    },
    {
        active: pathname !== Routes.home && pathname.indexOf(Routes.future) === 0,
        className: 'menu future' + (pathname !== Routes.home && pathname.indexOf(Routes.future) === 0 ? ' active' : ''),
        to: Routes.future,
        children: <>
            <span className="menu-icon"><Icon><Clock /></Icon></span>
            <span>{ langDict.headerMenuFuture }</span>
        </>
    },
    {
        active: pathname !== Routes.home && pathname.indexOf(Routes.upgrade) === 0,
        className: 'menu upgrade' + (pathname !== Routes.home && pathname.indexOf(Routes.upgrade) === 0 ? ' active' : ''),
        to: Routes.upgrade,
        children: <>
            <span className="menu-icon"><Icon><Gift /></Icon></span>
            <span>{ langDict.headerMenuUpgrade }</span>
        </>
    },
    // {
    //     active: pathname !== Routes.home && pathname.indexOf(Routes.gameBox) === 0,
    //     className: 'menu game-box' + (pathname !== Routes.home && pathname.indexOf(Routes.gameBox) === 0 ? ' active' : ''),
    //     to: Routes.gameBox,
    //     children: <>
    //         <span className="menu-icon"><Icon className="i-4x"><GameBox /></Icon></span>
    //         <span>{ langDict.headerMenuGameBox }</span>
    //     </>
    // },
    {
        active: pathname !== Routes.home && pathname.indexOf(Routes.orderQuery) === 0,
        className: 'menu order-query' + (pathname !== Routes.home && pathname.indexOf(Routes.orderQuery) === 0 ? ' active' : ''),
        to: Routes.orderQuery,
        children: <>
            <span className="menu-icon"><Icon><OrderSearch /></Icon></span>
            <span>{ langDict.headerMenuOrderQuery }</span>
        </>
    },
    {
        active: pathname !== Routes.home && pathname.indexOf(Routes.baidu) === 0,
        className: 'menu baidu' + (pathname !== Routes.home && pathname.indexOf(Routes.baidu) === 0 ? ' active' : ''),
        to: Routes.baidu,
        children: <>
            <span className="menu-icon"><Icon><Vip /></Icon></span>
            <span>{ langDict.headerMenuBaidu }</span>
        </>
    },
    {
        active: pathname !== Routes.home && pathname.indexOf(Routes.questionsAndAnswers) === 0,
        className: 'menu questionsAndAnswers' + (pathname !== Routes.home && pathname.indexOf(Routes.questionsAndAnswers) === 0 ? ' active' : ''),
        to: Routes.questionsAndAnswers,
        children: <>
            <span className="menu-icon"><Icon><HelpHexagon /></Icon></span>
            <span>{ langDict.headerMenuQuestionsAndAnswers }</span>
        </>
    },
    {
        active: pathname !== Routes.home && pathname.indexOf(Routes.siteSecurity) === 0,
        className: 'menu siteSecurity' + (pathname !== Routes.home && pathname.indexOf(Routes.siteSecurity) === 0 ? ' active' : ''),
        to: Routes.siteSecurity,
        children: <>
            <span className="menu-icon"><Icon><RouteAltRight /></Icon></span>
            <span>{ langDict.siteSecurity }</span>
        </>
    }
]

const Main: React.FC = () => {
    const history = useHistory()
    // 搜索
    const [ searchVisible, setSearchVisible ] = useFetchState(false)
    const searchModalProps: ModalsProps = {
        visible: searchVisible,
        setVisible: setSearchVisible,
        width: 1024,
        title: '搜索',
        content: <Search setVisible={ setSearchVisible } />,
        maskClosable: true,
        centered: false,
        footer: null
    }

    // 设置header高度
    const [ headerChangeState, setHeaderChangeState ] = useFetchState(false)

    // 语言
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ langPopoverVisible, setLangPopoverVisible ] = useFetchState(false)

    // 会员
    const profile = new Profile()
    const [ userPopoverVisible, setUserPopoverVisible ] = useFetchState(false)

    // 登录弹窗
    const signInPopup = new SignInPopup()
    const signInVisible = new SignInPopupVisible()
    const signInModalInnerProps = {
        popup: true,
        callback: (data?: any): void => {
            if (SignInPopupType.login === data) {
                signInVisible.set(true)
            } else {
                signInVisible.set(false)
            }

            execFunc(signInVisible.state.callback, data)
        }
    }
    const signInModalProps: ModalsProps = {
        className: 'sign-in popup',
        visible: signInVisible.shared().visible,
        setVisible: val => {
            signInVisible.set(val, signInVisible.state.callback)
        },
        width: 570,
        content: <>
            { signInPopup.state === SignInPopupType.login ? <Login { ...signInModalInnerProps } /> : '' }
            { signInPopup.state === SignInPopupType.register ? <Register { ...signInModalInnerProps } /> : '' }
            { signInPopup.state === SignInPopupType.findPassword ? <FindPassword { ...signInModalInnerProps } /> : '' }
            { signInPopup.state === SignInPopupType.setPassword ? <SetPassword { ...signInModalInnerProps } /> : '' }
        </>,
        maskClosable: true,
        centered: false,
        footer: null
    }

    // 主题
    const theme = new Theme()
    const doSetTheme = (): void => {
        theme.set(handleTheme)
    }

    // 配置
    const configs = new Configs()
    const configsState = configs.shared()

    const doSetSearch = (): void => {
        setSearchVisible(true)
    }

    useEffect(
        () => {
            handleTheme(theme.state)

            // 滚动监听
            const scrollListener = (): void => {
                // header
                setHeaderChangeState(window.scrollY >= 70)
            }

            window.addEventListener('scroll', scrollListener)
            return () => {
                window.removeEventListener('scroll', scrollListener)
            }
        },
        []
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'headerMenuHome',
                        'headerMenuAllGames',
                        'headerMenuFuture',
                        'headerMenuUpgrade',
                        'headerMenuGameBox',
                        'search',
                        'headerMenuOrderQuery',
                        'profile',
                        'logout',
                        'login',
                        'vip',
                        'membershipRestriction',
                        'logoutConfirm',
                        'headerMenuQuestionsAndAnswers',
                        'siteSecurity',
                        'vipSlogan',
                        'headerMenuBaidu'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <Header className={ 'layer-header' + (headerChangeState ? ' sp' : '') }>
        { searchVisible ? <Modals { ...searchModalProps } /> : '' }
        { signInVisible.shared().visible ? <Modals { ...signInModalProps } /> : '' }
        <Row className="wh100 header">
            <Col span={ 3 } className="flex-center h100">
                <Logo />
            </Col>
            <Col span={ 21 } className="h100 flex-center">
                <ul className="menus">{
                    menus({ langDict, pathname: history.location.pathname }).filter(
                        item => {
                            if (Routes.gameBox === item.to) {
                                if (configsState !== null) {
                                    return configsState.desktopAppWebChannelState
                                }

                                return false
                            }

                            return true
                        }
                    ).map(
                        (item, key) => <li key={ key } className={ item.className }>
                            <Location to={ item.to }>{ item.children }</Location>
                        </li>
                    )
                }</ul>
                <ul className="header-controls">
                    {
                        (!isEmpty(profile.state) && profile.state.level === UserLevelType.free)
                            ? <li>
                                <Location className="vip" to={ Routes.upgrade }>
                                    <Icon className="i-3x" tap>
                                        <Vip1 />
                                    </Icon>
                                    <span>{ langDict.vipSlogan }</span>
                                </Location>
                            </li>
                            : ''
                    }
                    {
                        (!isEmpty(profile.state) && profile.state.level === UserLevelType.pro)
                            ? <li>
                                <a
                                    className="vip"
                                    onClick={
                                        e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }
                                    }
                                >
                                    <Icon className="i-3x" tap>
                                        <Vip1 />
                                    </Icon>
                                    <span>{ langDict.vip }</span>
                                </a>
                            </li>
                            : ''
                    }
                    {
                        (!isEmpty(profile.state) && profile.state.level === UserLevelType.permanent)
                            ? <li>
                                <a
                                    className="vip"
                                    onClick={
                                        e => {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }
                                    }
                                >
                                    <Icon className="i-3x" tap>
                                        <Vip1 />
                                    </Icon>
                                    <span>{ langDict.membershipRestriction }</span>
                                </a>
                            </li>
                            : ''
                    }
                    <Popover
                        content={
                            <div className="language-options">{
                                langOptions.map(
                                    (item, key) => <p
                                        key={ key }
                                        className={ lang.state === item.val ? 'active' : '' }
                                        onClick={
                                            () => {
                                                lang.set(item.val)
                                                setLangPopoverVisible(false)
                                            }
                                        }
                                    >{ item.title }</p>
                                )
                            }</div>
                        }
                        placement="bottomRight"
                        trigger="click"
                        open={ langPopoverVisible }
                        onOpenChange={
                            val => {
                                setLangPopoverVisible(val)
                            }
                        }
                    >
                        <li>
                            <Icon tap className={ `i-3x${ langPopoverVisible ? ' active' : '' }` }>
                                <World />
                            </Icon>
                        </li>
                    </Popover>
                    <li onClick={ doSetTheme }>
                        <Icon tap>
                            { theme.state === Theme.light ? <Sun /> : <Moon /> }
                        </Icon>
                    </li>
                    <li onClick={ doSetSearch } className="header-search">
                        <Icon><IconSearch /></Icon>
                    </li>
                    {
                        profile.state === null
                            ? <li onClick={ () => { signInVisible.set(true) } }>
                                <div className="login-icon">
                                    <Icon className="i-3x" tap><User /></Icon>
                                    <span>{ langDict.login }</span>
                                </div>
                            </li>
                            : <Popover
                                overlayClassName="header-user-popover"
                                trigger="hover"
                                open={ userPopoverVisible }
                                onOpenChange={
                                    val => {
                                        setUserPopoverVisible(val)
                                    }
                                }
                                content={
                                    <div className="user-popover-options">
                                        <Location
                                            to={ Routes.profile }
                                            onClick={
                                                () => {
                                                    setUserPopoverVisible(false)
                                                }
                                            }
                                        ><p>{ langDict.profile }</p></Location>
                                        <p
                                            onClick={
                                                () => {
                                                    setUserPopoverVisible(false)
                                                    Confirm({
                                                        content: <div className="weight">{ langDict.logoutConfirm }</div>,
                                                        success: (): void => {
                                                            logout({ locationSign: false })
                                                            profile.set(null)
                                                            history.push(Routes.home)
                                                        }
                                                    })
                                                }
                                            }
                                        >{ langDict.logout }</p>
                                    </div>
                                }
                            >
                                <li className="flex-center profile">
                                    <div className="avatar flex-center">
                                        <Avatar path={ profile.state.avatar } name={ profile.state.nickname } />
                                    </div>
                                </li>
                            </Popover>
                    }
                </ul>
            </Col>
        </Row>
    </Header>
}

export default Main