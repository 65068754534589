import store from '#utils/store'
import { isEmpty } from '#utils/functions'

export function LastRoute(type = 'get'): string | null {
    const key = 'last-login-route'
    if (type === 'get') {
        const route = store.get(key)
        // 清除缓存
        store.remove(key)

        if (!isEmpty(route) && (route as string) !== '' && route.indexOf('http') === 0) {
            return (new URL(route)).pathname
        }

        return route
    }

    store.set(key, (new URL(window.location.href)).pathname)
    return null
}