import React, { useEffect } from 'react'
import Privilege1 from '#assets/images/privilege/1.png'
import Privilege2 from '#assets/images/privilege/2.png'
import Privilege3 from '#assets/images/privilege/3.png'
import Privilege4 from '#assets/images/privilege/4.png'
import Image from '#components/image'
import BackgroundImage from '#assets/images/game-box-bg-new.png'
import { ReactComponent as Lanzou } from '#assets/svg/lanzou.svg'
import { ReactComponent as Baidu } from '#assets/svg/baidu.svg'
import Location from '#components/location'
import Icon from '#components/icon'
import GameBoxCtl from '#components/game-box-ctl'
import { Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { documentTitle } from '#utils/configs'

const Main: React.FC = () => {
    const configs = new Configs()
    const configsState = configs.shared()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'lanzouCloud',
                    'baiduCode',
                    'goPay',
                    'activationCodePurchase',
                    'download',
                    'orderUserLevelUpgradeTitle',
                    'privilegeTitle1',
                    'privilegeTitle2',
                    'privilegeTitle3',
                    'privilegeTitle4',
                    'privilegeContent1',
                    'privilegeContent2',
                    'privilegeContent3',
                    'privilegeContent4',
                    'headerMenuQuestionsAndAnswers',
                    'friendlyLinkExplain',
                    'vipFooterExplain'
                ]
            })
            setLangDict(langDict)
            documentTitle(langDict.orderUserLevelUpgradeTitle)
        },
        [ lang.state ]
    )

    return <div className="game-box-container">
        <div className="w100 background">
            <div className="impression">
                <Image product={ true } original className="logo fill" src={ BackgroundImage } alt="" />
            </div>
            {
                configsState === null
                    ? ''
                    : <div className="box">
                        <GameBoxCtl configs={ configsState } sp={ true } />
                        <div className="download-btn-con flex-cc">
                            {
                                configsState.desktopAppDownloadUrlLanzou === ''
                                    ? ''
                                    : <div className="download-btn">
                                        <Icon className="i-5x"><Lanzou /></Icon>
                                        <span className="url">游戏盒子</span>
                                        <span className="button" onClick={ () => { window.open(configsState.desktopAppDownloadUrlLanzou) } }>蓝奏云下载</span>
                                    </div>
                            }
                            {
                                configsState.desktopAppDownloadUrlBaidu === ''
                                    ? ''
                                    : <div className="download-btn">
                                        <Icon className="i-5x"><Baidu /></Icon>
                                        <span className="url">游戏盒子</span>
                                        <span className="button" onClick={ () => { window.open(configsState.desktopAppDownloadUrlBaidu) } }>百度云下载</span>
                                    </div>
                            }
                        </div>
                    </div>
            }
            <div className="impression1">
                <p className="example">
                    游戏盒子永久更新，自动显示密码，包含未来资源免费永久更新 <br/>
                    会员尊享特权，资源免费下载，版本更新，包含修改器 <br/>
                    售后服务无忧，永久售后技术服务支持<br/>
                    加入盒子SVIP，尊享特权，永久更新，给你身处游戏世界最好的体验！
                </p>
            </div>
        </div>
        <div className="slogan">
            <h2>{ langDict.gameBoxTitle }</h2>
            <p className="description">{ configsState === null ? '' : pickLangKey(lang.state, configsState.subscriptionDescription) }</p>
        </div>
        <div className="privilege">
            <div className="item">
                <img src={ Privilege1 } alt=""/>
                <p className="title">{ langDict.privilegeTitle1 }</p>
                <p className="content">{ langDict.privilegeContent1 }</p>
            </div>
            <div className="item">
                <img src={ Privilege2 } alt=""/>
                <p className="title">{ langDict.privilegeTitle2 }</p>
                <p className="content">{ langDict.privilegeContent2 }</p>
            </div>
            <div className="item">
                <img src={ Privilege3 } alt=""/>
                <p className="title">{ langDict.privilegeTitle3 }</p>
                <p className="content">{ langDict.privilegeContent3 }</p>
            </div>
            <div className="item">
                <img src={ Privilege4 } alt=""/>
                <p className="title">{ langDict.privilegeTitle4 }</p>
                <p className="content">{ langDict.privilegeContent4 }</p>
            </div>
        </div>
        {
            configsState === null
                ? ''
                : <div className="faq">
                    <p className="title">{ langDict.headerMenuQuestionsAndAnswers }</p>
                    <p className="description">FAQ</p>
                    <div className="list">
                        {
                            configsState.vipFAQ.map(
                                (item, key) => <div key={ key } className="item">
                                    <p className="title">{ pickLangKey(lang.state, item.title) }</p>
                                    <p className="content">{ pickLangKey(lang.state, item.content) }</p>
                                </div>
                            )
                        }
                    </div>
                </div>
        }
        <div className="footer-links">
            <span className="title">{ langDict.friendlyLinkExplain }:</span>
            <ul>{
                configsState?.friendlyLinks.map(
                    (item, key) => <li key={ key }>
                        <Location target="_blank" to={ item.url }>{ pickLangKey(lang.state, item.title) }</Location>
                    </li>
                )
            }</ul>
        </div>
        <div className="footer-copyright">{ langDict.vipFooterExplain }</div>
    </div>
}

export default Main