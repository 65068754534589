import React, { useEffect } from 'react'
import { Col, Row, Divider, Input, Button } from 'antd'
import { Language, Userinfo } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { makeUserItem, update, type UserItem } from '#repositories/apis/user'
import { type UpdateItem } from '#repositories/types/request'
import { ReactComponent as User } from '#assets/svg/user.svg'
import { ReactComponent as SmartPhone } from '#assets/svg/smart-phone.svg'
import { ReactComponent as QQ } from '#assets/svg/brand-qq.svg'
import localization, { type LT } from '#utils/localization'
import { textareaOnChange, textOnChange } from '#utils/events'
import { isEmpty } from '#utils/functions'
import { isPhone, isQQ } from '#utils/patterns'
import { MessageType, MMessage } from '#components/hint'
import Copy from '#components/copy'
import Icon from '#components/icon'

const { TextArea } = Input

const Main: React.FC = () => {
    const userinfo = new Userinfo()
    const lang = new Language()
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // 本地化
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // data
    const [ data, setData ] = useFetchState<UserItem>(makeUserItem())

    const submit = (): void => {
        if (data.qq !== '' && !isQQ(data.qq)) {
            MMessage({
                message: langDict.qqFormRuleMsg,
                type: MessageType.error
            })
            return
        }

        if (data.mobile !== '' && !isPhone(data.mobile)) {
            MMessage({
                message: langDict.mobileFormRuleMsg,
                type: MessageType.error
            })
            return
        }

        setLoading(true)
        void update(
            [
                { column: 'qq', value: data.qq },
                { column: 'mobile', value: data.mobile },
                { column: 'brief', value: data.brief },
                { column: 'nickname', value: data.nickname }
            ] as UpdateItem[]
        ).then(
            () => {
                MMessage({
                    message: langDict.updateSuccessMsg,
                    type: MessageType.success
                })

                userinfo.set(data)
            }
        ).finally(
            () => {
                setTimeout(
                    () => {
                        setLoading(false)
                    },
                    1000
                )
            }
        )
    }

    useEffect(
        () => {
            if (userinfo.state === null) {
                return
            }
            setData(userinfo.state)
        },
        [ userinfo.state ]
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'qqFormRuleMsg',
                        'updateSuccessMsg',
                        'mobileFormRuleMsg',
                        'buttonSave',
                        'nickname',
                        'nicknamePlaceholder',
                        'mobile',
                        'mobilePlaceholder',
                        'brief',
                        'briefPlaceholder',
                        'userid',
                        'qq',
                        'qqPlaceholder'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <div className="information-container">
        <p className="identity">
            { langDict.userid }:
            <Copy text={ (isEmpty(data.id) ? '' : data.id).toString() }>
                <> [ <span>{ data.id }</span> ]</>
            </Copy>
        </p>
        <Row className="form-columns">
            <Col span="24">
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.nickname }: </Col>
                    <Col span={ 15 }>
                        <Input
                            className="nickname"
                            allowClear
                            value={ data.nickname }
                            prefix={ <Icon tap><User /></Icon> }
                            placeholder={ langDict.nicknamePlaceholder }
                            maxLength={ 20 }
                            onChange={
                                textOnChange(value => {
                                    setData({
                                        ...data,
                                        nickname: value.trim()
                                    })
                                })
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.qq }: </Col>
                    <Col span={ 15 }>
                        <Input
                            className="qq"
                            allowClear
                            value={ data.qq }
                            prefix={ <Icon tap><QQ /></Icon> }
                            placeholder={ langDict.qqPlaceholder }
                            onChange={
                                textOnChange(value => {
                                    setData({
                                        ...data,
                                        qq: value.trim()
                                    })
                                })
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.mobile }: </Col>
                    <Col span={ 15 }>
                        <Input
                            className="mobile"
                            allowClear
                            value={ data.mobile }
                            prefix={ <Icon tap><SmartPhone /></Icon> }
                            placeholder={ langDict.mobilePlaceholder }
                            onChange={
                                textOnChange(value => {
                                    setData({
                                        ...data,
                                        mobile: value.trim()
                                    })
                                })
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.brief }: </Col>
                    <Col span={ 15 }>
                        <TextArea
                            className="brief"
                            allowClear
                            showCount
                            value={ data.brief }
                            placeholder={ langDict.briefPlaceholder }
                            autoSize={ { minRows: 4, maxRows: 7 } }
                            maxLength={ 240 }
                            onChange={
                                textareaOnChange(value => {
                                    setData({
                                        ...data,
                                        brief: value
                                    })
                                })
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col offset={ 5 } span={ 15 }>
                        <Button
                            type="primary"
                            className="submit background-button"
                            loading={ loading }
                            onClick={ submit }
                        >{ langDict.buttonSave }</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export default Main