import { useCallback, useEffect, useRef, useState } from 'react'

type FetchState<T> = [ T, (data: T) => void ]

/**
 * useFetchState
 * @param initialState
 */
export default function useFetchState<T>(initialState: T): FetchState<T> {
    const focus = useRef<boolean>(false)
    const [ state, setState ] = useState(initialState)
    useEffect(
        () => {
            focus.current = true
            return () => {
                focus.current = false
            }
        },
        []
    )

    const setFetchState = useCallback(
        (params: any) => {
            if (focus.current) {
                setState(params)
            }

            setState(params)
        },
        []
    )

    return [ state, setFetchState ]
}
