import React, { useEffect } from 'react'
import { Button } from 'antd'
import { Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import localization, { type LT } from '#utils/localization'
import Icon from '#components/icon'
import { ReactComponent as Alipay } from '#assets/svg/alipay.svg'
import { ReactComponent as Balance } from '#assets/svg/balance.svg'
import { PayType } from '#constants/appoint'

interface MainProps {
    // 账户余额
    balance: number
    // 单价
    price: number
    // 支付回调
    completion: (payType: PayType) => void
}

const Main: React.FC<MainProps> = ({ balance, price, completion }) => {
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ payMethod, setPayMethod ] = useFetchState<PayType>(PayType.ali)
    const [ loading, setLoading ] = useFetchState(false)

    const pay = (): void => {
        setLoading(true)
        completion(payMethod)
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'selectPayTypeTitle',
                        'selectPayTypeTitle',
                        'payMethodAlipay',
                        'payMethodBalance',
                        'payAmount',
                        'pay',
                        'paying',
                        'gold'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <div className="pay-type-popup">
        <p className="title">{ langDict.selectPayTypeTitle }</p>
        <p className="price">{ langDict.payAmount }: <b>{ price }</b></p>
        <div className="pay-method">
            <div
                className={ `alipay${ payMethod === PayType.ali ? ' active' : '' }` }
                onClick={ () => { setPayMethod(PayType.ali) } }
            >
                <Icon><Alipay /></Icon>
                <span>{ langDict.payMethodAlipay }</span>
            </div>
            {
                balance > 0
                    ? (
                        price > balance
                            ? <div className="balance cursor-disabled">
                                <Icon><Balance /></Icon>
                                <span className="font">
                                    { langDict.payMethodBalance }
                                    <em>{ balance } { langDict.gold }</em>
                                </span>
                            </div>
                            : <div
                                className={ `balance${ payMethod === PayType.balance ? ' active' : '' }` }
                                onClick={ () => { setPayMethod(PayType.balance) } }
                            >
                                <Icon><Balance /></Icon>
                                <span className="font">
                                    { langDict.payMethodBalance }
                                    <em>{ balance } { langDict.gold }</em>
                                </span>
                            </div>
                    )
                    : ''
            }
        </div>
        <p className="button-box">
            <Button type="primary" loading={ loading } onClick={ pay }>{ loading ? langDict.paying : langDict.pay }</Button>
        </p>
    </div>
}

export default Main