import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Form, Input, Row } from 'antd'
import { type FormInstance } from 'antd/es/form/hooks/useForm'
import localization, { type LT } from '#utils/localization'
import { Countdown } from '#utils/countdown'
import { placeholder } from '#utils/localization/dictionary'
import { execFunc, isEmpty } from '#utils/functions'
import useFetchState from '#repositories/models'
import { Configs, Language, SignInPopup, SignInPopupType } from '#repositories/models/recoil-state'
import { email as captchaEmailApi, CaptchaType } from '#repositories/apis/cptcha'
import { findPassword } from '#repositories/apis/user'
import Location from '#components/location'
import { MessageType, MMessage } from '#components/hint'
import { codeRule, emailRegRule, type FindPasswordFieldType, getConfigs, type SignInProps, wrapperXLCol } from './model'
import Icon from '#components/icon'
import { ReactComponent as Key } from '#assets/svg/key.svg'
import { ReactComponent as Email } from '#assets/svg/email.svg'
import Logo from '#components/logo'

const { Item } = Form

const Main: React.FC<SignInProps> = ({ popup, callback }) => {
    const configs = new Configs()
    const signInPopup = new SignInPopup()
    const history = useHistory()
    const formRef = useRef<FormInstance | null>(null)
    const keyRef = useRef<string>('')
    const lang = new Language()
    const [ LangDict, setLangDict ] = useFetchState<LT>({})
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // 获取验证码加载
    const [ codeLoading, setCodeLoading ] = useFetchState(false)
    // code button text
    const [ codeButtonTips, setCodeButtonTips ] = useFetchState('')
    // code button text
    const [ codeButtonDisabled, setCodeButtonDisabled ] = useFetchState(false)

    const countdownFunc = (init: boolean = false): void => {
        const instance = new Countdown(
            'find-password-countdown',
            (countdown: number): void => {
                if (countdown <= 0) {
                    setCodeButtonTips(LangDict.getCaptchaCode)
                    setCodeButtonDisabled(false)
                    return
                }
                setCodeButtonTips(
                    LangDict.getCaptchaCodeCountdown.replace(
                        placeholder,
                        String(countdown)
                    )
                )

                setCodeButtonDisabled(true)
            }
        )

        if (init && !instance.check()) {
            return
        }

        instance.do()
    }

    const parseUrl = (url: string): string => {
        const parse = (str: string): string => {
            const indexOfSecondSlash = str.indexOf('/', str.indexOf('/') + 1)
            if (indexOfSecondSlash !== -1) {
                const substringToEncode = str.substring(indexOfSecondSlash + 1)
                const encodedSubstring = encodeURIComponent(substringToEncode)

                return str.substring(0, indexOfSecondSlash + 1) + encodedSubstring
            }

            return str
        }

        try {
            const data = new URL(url)
            return data.origin + parse(url.replace(data.origin, ''))
        } catch (e) {
            return parse(url)
        }
    }

    // 获取邮箱验证码
    const getCode = (): void => {
        const { current } = formRef
        if (current === null) {
            return
        }

        setCodeLoading(true)
        void current.validateFields().then(
            data => {
                setCodeLoading(true)
                void captchaEmailApi<any>(data.email, CaptchaType.findPwd).then(
                    res => {
                        MMessage({
                            message: LangDict.emailCaptchaSendSuccess,
                            type: MessageType.success
                        })

                        keyRef.current = res.data.encrypt ?? ''
                        countdownFunc()
                    }
                ).finally(
                    () => {
                        setCodeLoading(false)
                    }
                )
            }
        ).finally(
            () => {
                setCodeLoading(false)
            }
        )
    }

    const submit = ({ email, code }: FindPasswordFieldType): void => {
        setLoading(true)
        void findPassword<any>({ email, key: keyRef.current, code }).then(
            res => {
                MMessage({
                    message: LangDict.findPasswordValidatorSuccess,
                    type: MessageType.success
                })

                execFunc(callback)
                history.push(parseUrl(res.data ?? '/'))
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    useEffect(
        () => {
            getConfigs(configs)
        },
        []
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'confirm',
                        'findPassword',
                        'emailPlaceholder',
                        'emailCaptchaSendSuccess',
                        'getCaptchaCodeCountdown',
                        'getCaptchaCode',
                        'emailFormRuleMsg',
                        'emailExistsFormRuleMsg',
                        'codeFormRuleMsg',
                        'codePlaceholder',
                        'findPasswordValidatorSuccess',
                        'goLoginLinkTips'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            setCodeButtonTips(LangDict.getCaptchaCode)
            if (!isEmpty(LangDict.getCaptchaCodeCountdown)) {
                countdownFunc(true)
            }
        },
        [ LangDict ]
    )

    return <div className={ popup ? 'popup-center-box' : 'wh100 relative' }>
        <div className={ 'fade-in signin-box find-password' + (popup ? '' : ' abs-center') }>
            <Logo />
            <Row>
                <Col span={ 24 } className="header">
                    <Col span={ 22 } className="h1">
                        <h3 className="h1">{ LangDict.findPassword }</h3>
                    </Col>
                </Col>
            </Row>
            <Form
                ref={ formRef }
                name="basic"
                initialValues={ { remember: true } }
                onFinish={ submit }
                autoComplete="off"
            >
                <Item<FindPasswordFieldType>
                    name="email"
                    rules={ emailRegRule(LangDict.emailFormRuleMsg, LangDict.emailExistsFormRuleMsg, true) }
                    wrapperCol={ wrapperXLCol }
                >
                    <Row>
                        <Col span={ 14 } className="flex-end">
                            <Input
                                className="email"
                                allowClear
                                prefix={<Icon><Key /></Icon> }
                                placeholder={ LangDict.emailPlaceholder }
                            />
                        </Col>
                        <Col span={ 9 } offset={ 1 }>
                            <Button
                                htmlType="button"
                                disabled={ codeButtonDisabled }
                                className="submit background-button cursor-pointer"
                                loading={ codeLoading }
                                onClick={ getCode }
                            >{ codeLoading ? '' : codeButtonTips }</Button>
                        </Col>
                    </Row>
                </Item>
                <Item<FindPasswordFieldType>
                    name="code"
                    rules={ codeLoading ? [] : codeRule(LangDict.codePlaceholder) }
                    wrapperCol={ wrapperXLCol }
                >
                    <Input
                        className="code"
                        allowClear
                        prefix={<Icon><Email /></Icon> }
                        placeholder={ LangDict.codePlaceholder }
                    />
                </Item>
                <Item wrapperCol={ wrapperXLCol } className="mb0">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="submit background-button"
                        loading={ loading }
                    >{ LangDict.confirm }</Button>
                </Item>
                <Row className="convenient-ext">
                    <Col { ...wrapperXLCol } className="flex-end">
                        <div className="wh100 flex">{
                            popup
                                ? <span
                                    onClick={
                                        () => {
                                            signInPopup.set(SignInPopupType.login)
                                        }
                                    }
                                    className="link-color cursor-pointer"
                                >{ LangDict.goLoginLinkTips }</span>
                                : <Location to="/login" className="link-color">{ LangDict.goLoginLinkTips }</Location>
                        }</div>
                    </Col>
                </Row>
            </Form>
        </div>
    </div>
}

export default Main