import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Vip2 } from '#assets/svg/vip2.svg'
import { OrderType, PayType } from '#constants/appoint'
import { MessageType, MMessage, Modals, type ModalsProps } from '#components/hint'
import PayTypePopupTmp from '#components/pay-type-popup'
import Icon from '#components/icon'
import { Configs, Language, Profile, SignInPopupVisible, WalletStatistics } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { makeOrder, type MakerOrderResp } from '#repositories/apis/orders'
import { statistics as statisticsApi, type StatisticsResp } from '#repositories/apis/wallet'
import localization, { type LT } from '#utils/localization'
import { documentTitle } from '#utils/configs'
import { throttle } from '#utils/functions'
import type { ConfigsType } from '#repositories/apis/base'
import Location from '#components/location'

interface MainProps {
    configs: ConfigsType
    // 专题页
    sp?: boolean
}

const Main: React.FC<MainProps> = ({ configs, sp }) => {
    const lang = new Language()
    const history = useHistory()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ visible, setVisible ] = useFetchState(false)
    const signInVisible = new SignInPopupVisible()
    const profile = new Profile()
    const walletStatistics = new WalletStatistics()
    const now = Math.ceil((new Date()).valueOf() / 1000)
    const conf = new Configs()
    const externalLinkState = conf.shared()?.externalLinkState ?? false
    const externalLinkGameBox = conf.shared()?.externalLinkGameBox ?? ''

    const doMakeOrder = (payType: PayType): void => {
        void makeOrder({
            payType,
            type: OrderType.appSecretKey,
            uniqueId: '1'
        }).then(
            res => {
                const data = res.data as MakerOrderResp
                window.location.href = data.url
            }
        )
    }

    const goPay = (): void => {
        // 创建订单
        const makeOrderCall = (): void => {
            if (walletStatistics.state !== null && walletStatistics.state.balance > 0) {
                // 显示选择支付
                setVisible(true)
                return
            }

            doMakeOrder(PayType.ali)
        }

        // 检测登录状态
        if (profile.state === null) {
            signInVisible.set(true, _ => {
                MMessage({
                    message: langDict.loginSuccessWidth,
                    type: MessageType.success,
                    duration: 1,
                    onClose: () => {
                        makeOrderCall()
                    }
                })
            })
            return
        }

        makeOrderCall()
    }

    const popupModalProps: ModalsProps = {
        className: 'site-popup',
        visible,
        setVisible,
        width: 400,
        content: <PayTypePopupTmp
            balance={ walletStatistics.state === null ? 0 : walletStatistics.state.balance }
            price={ configs.desktopAppSecretKeyDiscountExpire > now ? configs.desktopAppSecretKeyDiscountPrice : configs.desktopAppSecretKeyPrice }
            completion={ doMakeOrder }
        />,
        maskClosable: true,
        centered: true,
        footer: null
    }

    useEffect(
        () => {
            if (profile.state === null) {
                return
            }

            if (walletStatistics.state !== null) {
                return
            }

            throttle(
                (): void => {
                    void statisticsApi().then(
                        res => {
                            walletStatistics.set(res.data as StatisticsResp)
                        }
                    )
                },
                500,
                'walletStatistics'
            )

            return () => {
                walletStatistics.set(null)
            }
        },
        [ profile.state, history.location.pathname ]
    )

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'loginSuccessWidth',
                    'orderUserLevelUpgradeTitle',
                    'activationCodePurchase',
                    'svipYearLimitBrief',
                    'goPay'
                ]
            })
            setLangDict(langDict)
            documentTitle(langDict.orderUserLevelUpgradeTitle)
        },
        [ lang.state ]
    )

    return <>
        { visible ? <Modals { ...popupModalProps } /> : '' }
        <div className={ `upgrade-item gb ${ sp === undefined ? '' : 'sp' }` }>
            <Icon className="top-vip-icon" tap><Vip2 /></Icon>
            <div className="header">
                <p className="title">{ langDict.activationCodePurchase }</p>
                <p className="price">
                    {
                        configs.desktopAppSecretKeyDiscountExpire > now
                            ? <><span className="discount-price-title">限时</span><span className="p">{ configs.desktopAppSecretKeyDiscountPrice }</span>元</>
                            : <><span>{ configs.desktopAppSecretKeyPrice }</span>元</>
                    }
                </p>
                <p className="thirdly-color">终身畅玩盒子所有游戏</p>
                <div
                    className="cursor-pointer transition-05s subscribe-button"
                    onClick={ () => { goPay() } }
                >{ langDict.goPay }</div>

                {
                    externalLinkState && externalLinkGameBox !== ''
                        ? <Location to={ externalLinkGameBox } className="subscribe-button sp" target="_blank">平台购买</Location>
                        : ''
                }
            </div>
        </div>
    </>
}

export default Main