import React, { useEffect } from 'react'
import { Col, Row } from 'antd'
import Icon from '#components/icon'
import Loading from '#components/loading'
import { ProductItem } from '#components/document/item'
import Location from '#components/location'
import { documentsPopular, type ListItem } from '#repositories/apis/documents'
import useFetchState from '#repositories/models'
import { Language } from '#repositories/models/recoil-state'
import { ReactComponent as Flame } from '#assets/svg/flame.svg'
import localization, { type LT } from '#utils/localization'
import { withSortProductsRoutePath } from '#utils/router'
import { SortType } from '#constants/appoint'

const Main: React.FC = () => {
    // 热门游戏
    const [ popularList, setPopularList ] = useFetchState<ListItem[]>([])
    const [ popularListLoading, setPopularListLoading ] = useFetchState(true)
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'popularGroupGames',
                        'checkMore'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            void documentsPopular().then(
                res => {
                    setPopularList(res.data as ListItem[])
                }
            ).finally(
                () => {
                    setPopularListLoading(false)
                }
            )
        },
        []
    )

    return <div className="popular-widget">
        <p className="widget-title">
            <Icon><Flame /></Icon>
            { langDict.popularGroupGames }
        </p>
        {
            popularListLoading
                ? <Loading />
                : <>
                    <Row>{
                        popularList.map(
                            (item, key) => <Col span={ 24 } key={ key }>
                                <ProductItem key={ key } item={ item } hideFooter={ true } />
                            </Col>
                        )
                    }</Row>
                </>
        }
        <p className="font-center">
            <Location to={ withSortProductsRoutePath(SortType.popularSite) } className="more">{ langDict.checkMore }</Location>
        </p>
    </div>
}

export default Main