import React from 'react'
import { type CSSStyle } from '#repositories/types/foundation'
import { isEmpty } from '#utils/functions'
import { Space } from 'antd'

interface IconProps {
    children: React.ReactNode
    className?: string
    color?: string
    tap?: boolean
    onClick?: (event: React.MouseEvent<HTMLElement>) => void

}

interface IconTextProps {
    icon: React.ReactNode
    text: any
    className?: string
}

export const IconText = ({ icon, text, className }: IconTextProps): React.ReactNode => <Space className={ className }>
    { icon }
    { text }
</Space>

const Main: React.FC<IconProps> = (
    {
        className,
        children,
        color,
        tap,
        onClick
    }
) => {
    let classNames: string[] = [ 'svg-icon' ]
    if (className !== undefined && !isEmpty(className)) {
        classNames = [ className, ...classNames ]
    } else {
        classNames.push('i-2x')
    }

    if (tap !== undefined) {
        classNames = [ ...classNames, 'cursor-pointer' ]
    }

    const style: CSSStyle = {}
    if (color !== undefined && !isEmpty(color)) {
        style.fill = color
    }

    const props = {
        style,
        className: classNames.join(' '),
        onClick
    }

    return <i { ...props }>{ children }</i>
}

export default Main
