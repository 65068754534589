import store from '#utils/store'
import { isEmpty } from '#utils/functions'

export default class Share {
    readonly shareOriginalCacheKey = 'share-original'

    getSecret(): string {
        const cache = store.get(this.shareOriginalCacheKey)
        return isEmpty(cache) ? '' : cache as string
    }

    setSecret(v: string): void {
        store.set(this.shareOriginalCacheKey, v)
    }

    clearSecret(): void {
        store.remove(this.shareOriginalCacheKey)
    }
}