import { type AxiosResponse } from 'axios'
import { service, proxy, route } from '#utils/axios'
import { type Condition, Operator, OperatorOuter, type Params } from '#repositories/types/request'
import { type AppExclusive, DocumentType, SortBy, type UserLevelType } from '#constants/appoint'
import { type NetworkDisk } from '#repositories/apis/orders'

export interface ListItemExtension {
    favorites: number[]
    likes: number[]
    sales: number
    views: number
    diskSpace: string
}

export interface ListItem {
    createdAt: number
    sort: number
    featuredImage: string
    exclusiveType: UserLevelType
    appExclusive: AppExclusive
    images: string[]
    labelIds: number[]
    price: number
    status: number
    summary: string
    title: string
    type: number
    uniqueId: string
    videoCode: string
    extension: ListItemExtension
}

export interface ListResp {
    list: ListItem[]
    total: number
}

export enum PopularGroupsKey {
    thisWeekend = 'thisWeekend',
    thisMonth = 'thisMonth',
    all = 'all'
}

export type GameGroups = Record<number, number>

export type PopularGroups = Record<PopularGroupsKey, ListItem[]>

export interface Extension {
    diskSpace: string
    networkDisk: NetworkDisk[]
    cardUniqueId: string
    subPrice: number
    promoPrice: number
    promoStartDate: number
    promoEndDate: number
    views: number
    sales: number
    shippingType: number
    purchaseExternalLink: string
    favorites: number[]
    likes: number[]
    downloads: number
    guestCheckout: boolean
    gameVersion: string
    gameLang: string
}

export interface RowResp {
    row: Item
    share: string
    userLevel: UserLevelType
    balance: number
    order: number
}

export interface Item {
    uniqueId: string
    userid: number
    status: number
    type: number
    title: string
    content: string
    videoCode: string
    contentTextOnly: string
    summary: string
    labelIds: number[]
    featuredImage: string
    exclusiveType: UserLevelType
    appExclusive: AppExclusive
    images: string[]
    price: number
    inventory: number
    tags: string
    extension: Extension
    featuredItems: boolean
    publish: boolean
    homeRecommendation: boolean
    top: boolean
    isDel: number
    sort: number
    updatedAt: number
    createdAt: number
}

export const makeKeywordCondition = (keyword: string): Condition[] => {
    keyword = keyword.trim()
    if (keyword === '') {
        return []
    }

    return [
        {
            column: 'title',
            value: keyword,
            operator: Operator.match,
            'outer-opr': OperatorOuter.or
        },
        {
            column: 'contentTextOnly',
            value: keyword,
            operator: Operator.match,
            'outer-opr': OperatorOuter.or
        },
        {
            column: 'summary',
            value: keyword,
            operator: Operator.match,
            'outer-opr': OperatorOuter.or
        }
    ]
}

export const list = async <T>(data: Params): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/documents'),
        method: 'post',
        data
    })
}

export const listWithLabelIds = async <T>(labelIds: number[], page: number, limit: number = 6): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/documents-with-total-count'),
        method: 'post',
        data: {
            conditions: [
                { column: 'labelIds', values: labelIds },
                { column: 'type', value: DocumentType.product }
            ],
            sortBy: [
                { column: 'extension.views', value: SortBy.desc },
                { column: 'sort', value: SortBy.desc }
            ],
            limit,
            page
        }
    })
}

export enum DocumentsFeaturedGroupType {
    popular = 'popular',
    latest = 'latest',
    free = 'free'
}

export interface DocumentsFeaturedGroupItem {
    type: DocumentsFeaturedGroupType
    list: ListItem[]
}

export type DocumentsFeaturedGroupResp = DocumentsFeaturedGroupItem[]

export const listWidthTotal = async <T>(data: Params): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/documents-with-total-count'),
        method: 'post',
        data
    })
}

export const groupByGameCategories = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/dictionary/game/groups'),
        method: 'get'
    })
}

export const row = async <T>(uniqueId: string, secret: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/document/${ uniqueId }${ secret === '' ? '' : '/' + secret }`),
        method: 'get'
    })
}

export const articleRow = async <T>(uniqueId: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/article/${ uniqueId }`),
        method: 'get'
    })
}

export const setColumn = async <T>(column: string, val: number, uniqueId: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/document/${ column }/${ val }/${ uniqueId }`),
        method: 'get'
    })
}

export const latest = async <T>(page: number, limit: number): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/document-latest/${ limit }/${ page }`),
        method: 'get'
    })
}

export const popularGroupWithDate = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/documents-popular-groups'),
        method: 'get'
    })
}

export const documentsFeaturedGroup = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/documents-featured-groups'),
        method: 'get'
    })
}

export const documentsPopular = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/documents-popular'),
        method: 'get'
    })
}

export const documentDownloadLimit = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/document-download-limit'),
        method: 'get'
    })
}
