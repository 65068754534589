export const DEV_ENV = 'development'

export const apiBehavior = {
    // 加载进度条
    disabledLoading: false,
    // 抑制错误提示
    disabledErrMsg: false,
    // 消息数量
    msgCount: 0
}

interface Variables {
    loginPopupSign: boolean
    timestamp: number

    // 游戏跳转订单确认id
    orderPreConfirmationId: string
}

export const variables: Variables = {
    // 登录提示 只提示一次
    loginPopupSign: false,
    timestamp: new Date().valueOf() / 1000,
    orderPreConfirmationId: ''
}

/**
 * 默认分页条数
 * @type {number}
 */
export const defaultLimit = 20
export const defaultLimit1 = 16
export const defaultManyLimit = 40

/**
 * 格式化时间
 * @type {string}
 */
export const dateFormat = 'YYYY-MM-DD HH:mm:ss'
export const dateFormatHis = 'HH:mm:ss'
export const dateFormatYmd = 'YYYY-MM-DD'
export const dateFormat1 = 'YYYY/MM/DD HH:mm'
export const dateFormat2 = 'YYYY/MM/DD'
export const dateFormat3 = 'YYYY年MM月DD日'
export const dateFormat4 = 'DD日'
// 显示条数选择
export const pageSizeOptions = [ 10, 16, 20, 30, 40, 50, 80, 100, 200 ]

export enum ProductLabelTypes {
    gameClass = 'gameClass',
    siteGuide = 'siteGuide',
    userLevel = 'userLevel'
}

export enum DocumentType {
    product = 1,
    article = 2
}

export enum SortBy {
    desc = 'desc',
    asc = 'asc'
}

export enum OrderType {
    product = 1, // 商品
    membershipCard = 2, // 会员卡
    recharge = 3, // 充值
    withdraw = 4, // 提现
    userLevelUpgrade = 5, // 会员升级
    userLevelUpgradePriceDifference = 6, // 会员升级补差价
    siteGift = 7, // 网站赞助打赏
    appSecretKey = 8, // 软件激活码
    appSecretKeyActive = 9 // 软件激活码激活(后台生成)
}

export enum PayType {
    apple = 1, // apple
    ali = 2, // 支付宝
    wechat = 3, // 微信
    wechatXcx = 4, // wechat
    wechatH5 = 5, // apple
    wechatApp = 6, // wechat app
    balance = 7 // wechat app
}

export enum SortType {
    updated, // 最近更新
    // popularWeek, // 本周 热度
    // popularMonth, // 本月 热度
    popularSite, // 全站 热度
    favorite, // 收藏量
    like, // 点赞量
    random // 随机
}

export enum FilterPrice {
    all, // 全部
    // pro, // 会员
    permanent, // 限定游戏
    free, // 免费
    paid // 付费
}

export interface FilterPriceItem {
    val: FilterPrice
    title: string
}

// type FilterPriceListType = Array<Record<number, string>>
export type FilterPriceListType = FilterPriceItem[]

export enum PayState {
    wait = 0, // 待支付
    success, // 支付成功
    payCancel, // 取消
    payAbnormal, // 交易异常
    refund, // 退款
    notPay, // 未支付
    closed, // 已关闭
    revoked, // 已撤销（付款码支付）
    userPaying, // 用户支付中（付款码支付）
    payError // 支付失败(其他原因，如银行返回失败)
}

export enum UserLevelType {
    free,
    pro,
    permanent
}

export const pickUserLevelType = (num: UserLevelType): number => {
    if (Object.values(UserLevelType).some(v => num === v)) {
        return num
    }

    return UserLevelType.free
}

export const pickColor = (index: number): string => {
    const list = [ '#2647e0', '#f9c851', '#ff2828', '#0c9550' ]
    return list[ index % list.length ]
}

export enum AppExclusive {
    def,
    windowsApp,
    web
}
