import { trim } from '#utils/functions'
import { FilterPrice, SortType } from '#constants/appoint'

export enum Routes {
    home = '/',
    homeLogin = '/l',
    products = '/products',
    orderQuery = '/order-query',
    product = '/product',
    future = '/future',
    baidu = '/baidu',
    order = '/order/',
    orderConfirmation = '/order-pre-confirmation',
    upgrade = '/upgrade',
    gameBox = '/game-box',
    profile = '/profile',
    questionsAndAnswers = '/questions-and-answers',
    siteSecurity = '/site-security',
    article = '/article/',
    banned = '/banned',
    service = 'https://www.yishuning.com/service'
}

export enum RouteProfile {
    wallet = `${ Routes.profile }/wallet`,
    expenseRecords = `${ Routes.profile }/expense-records`,
    membership = `${ Routes.profile }/membership`,
    gameBox = `${ Routes.profile }/game-box`,
    purchaseHistory = `${ Routes.profile }/purchase-history`,
    favorites = `${ Routes.profile }/favorites`,
    information = `${ Routes.profile }`,
    unbindingCode = `${ Routes.profile }/unbinding-code`,
    accountBinding = `${ Routes.profile }/account-binding`,
    passwordSettings = `${ Routes.profile }/password-settings`,
    withdraw = `${ Routes.profile }/withdraw`,
    substation = `${ Routes.profile }/substation`,
    activationCodes = `${ Routes.profile }/activation-codes`
}

// products/ps=1,dss=1,ds=1,prs=2,sd=2020-03-01,ed=2020-03-01,l=1,c=2,k=3,/1/20
export interface ProductsFilterParams {
    categoryId?: number // c
    sort?: SortType // ps
    exclusiveType?: FilterPrice // et
    keyword?: string // k
}

export const makeProductsFilterParamsInit = (): ProductsFilterParams => ({
    categoryId: 0,
    sort: SortType.updated,
    keyword: '',
    exclusiveType: FilterPrice.all
})

export enum ProductsFilterKeys {
    categoryId = 'c=', // 分类
    sort = 's=', // 排序
    exclusiveType = 'e=', // 价格 限定
    keyword = 'k=' // 价格 限定
}

export const productsFiltersJoinSep = ','

export const withSortProductsRoutePath = (sort: SortType): string => `${ Routes.products }/${ ProductsFilterKeys.sort }${ sort }`
export const withPriceProductsRoutePath = (sort: FilterPrice): string => `${ Routes.products }/${ ProductsFilterKeys.exclusiveType }${ sort }`
export const withCategoryIdProductsRoutePath = (id: number): string => `${ Routes.products }/${ ProductsFilterKeys.categoryId }${ id }`
export const withKeywordProductsRoutePath = (keyword: string, id: number = 0): string => `${ Routes.products }/${ ProductsFilterKeys.categoryId }${ id }${ productsFiltersJoinSep }${ ProductsFilterKeys.keyword }${ keyword }`
export const productDetailRoutePath = (uniqueId: string, secret?: string): string => `${ Routes.product }/${ uniqueId }${ secret !== undefined ? '/' + secret : '' }`
export const articleDetailRoutePath = (uniqueId: string): string => `${ Routes.article }${ uniqueId }`
export const orderConfirmationRoutePath = (uniqueId: string): string => `${ Routes.orderConfirmation }/${ uniqueId }`
export const orderRowRoutePath = (uniqueId: string): string => `${ Routes.order }${ uniqueId }`
export const upgradeRoutePath = (): string => Routes.upgrade

// 生成筛选参数
export const makeProductsParams = (params: ProductsFilterParams): string => {
    const data = []
    for (const key in params) {
        if (key === 'categoryId' && params.categoryId !== undefined && params.categoryId >= 0) {
            data.push(ProductsFilterKeys.categoryId + params.categoryId)
            continue
        }

        if (key === 'sort' && params.sort !== undefined && params.sort >= 0) {
            data.push(ProductsFilterKeys.sort + params.sort)
        }

        if (key === 'exclusiveType' && params.exclusiveType !== undefined && params.exclusiveType >= 0) {
            data.push(ProductsFilterKeys.exclusiveType + params.exclusiveType)
        }

        if (key === 'keyword' && params.keyword !== '') {
            data.push(ProductsFilterKeys.keyword + params.keyword)
        }
    }

    return data.join(productsFiltersJoinSep)
}

// 解析筛选参数
export const parseProductsFilters = (filters: string): ProductsFilterParams => {
    filters = filters.trim()
    const params: ProductsFilterParams = {
        categoryId: 0,
        sort: SortType.updated,
        exclusiveType: FilterPrice.all,
        keyword: ''
    }

    if (
        filters === '' ||
        !Object.values(ProductsFilterKeys).some(str => filters.includes(str))
    ) {
        return params
    }

    const getValue = (prefix: string, data: string): string => {
        return trim(data, new RegExp(prefix), '')
    }

    const list = filters.split(productsFiltersJoinSep)
    for (let i = 0; i < list.length; i++) {
        const item = trim(list[ i ])
        if (item === '') {
            continue
        }

        if (item.indexOf(ProductsFilterKeys.categoryId) === 0) {
            params.categoryId = parseInt(getValue(ProductsFilterKeys.categoryId, item))
            continue
        }

        if (item.indexOf(ProductsFilterKeys.sort) === 0) {
            params.sort = parseInt(getValue(ProductsFilterKeys.sort, item))
            continue
        }

        if (item.indexOf(ProductsFilterKeys.exclusiveType) === 0) {
            params.exclusiveType = parseInt(getValue(ProductsFilterKeys.exclusiveType, item))
        }

        if (item.indexOf(ProductsFilterKeys.keyword) === 0) {
            params.keyword = getValue(ProductsFilterKeys.keyword, item).trim()
        }
    }

    return params
}