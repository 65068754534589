import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Empty } from 'antd'
import localization, { type LT } from '#utils/localization'
import { isEmpty, toNumber } from '#utils/functions'
import { scrollToTop } from '#utils/events'
import { RouteProfile } from '#utils/router'
import { Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { list as listApi, makeStatisticsResp, statistics as statisticsApi, type StatisticsResp, type WalletsResp } from '#repositories/apis/wallet'
import { ActivationCodeCard, Cards, WalletItem } from '#pages/profile/components'
import { type WalletProps } from '#pages/profile/model'
import { defaultLimit, SortBy } from '#constants/appoint'
import Pagination from '#components/page'
import Loading from '#components/loading'
import { Modals, type ModalsProps } from '#components/hint'

const Main: React.FC<WalletProps> = props => {
    const activateId = parseInt(String(props.match.params.id ?? 0))
    const history = useHistory()
    const lang = new Language()
    // 本地化
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 获取钱包统计加载
    const [ statisticsLoading, setStatisticsLoading ] = useFetchState(false)
    // statistics data
    const [ statisticsResp, setStatisticsResp ] = useFetchState(makeStatisticsResp())
    // active uniqueId
    const [ activeUniqueId, setActiveUniqueId ] = useFetchState('')
    // 页码
    const [ page, setPage ] = useFetchState(
        props.match.params.page !== undefined && !isEmpty(props.match.params.page)
            ? toNumber(props.match.params.page, 1)
            : 1
    )
    // 每页条数
    const [ limit, setLimit ] = useFetchState(
        props.match.params.limit !== undefined && !isEmpty(props.match.params.limit)
            ? toNumber(props.match.params.limit, defaultLimit)
            : defaultLimit
    )
    // res
    const [ loading, setLoading ] = useFetchState(false)
    const [ res, setRes ] = useFetchState<WalletsResp>({
        list: [],
        total: 0
    })
    const fetchList = (): void => {
        setLoading(true)
        void listApi({
            limit,
            page,
            conditions: [],
            ids: activateId === 0 ? [] : [ activateId ],
            sortBy: [ { column: 'createdAt', value: SortBy.desc } ]
        }).then(
            res => {
                const data = res.data as WalletsResp
                data.list = data.list.filter(
                    item => item.income > 0 || item.outcome > 0 || item.recharge > 0
                )
                setRes(data)
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    // 搜索
    const [ cardVisible, setCardVisible ] = useFetchState(false)
    const searchModalProps: ModalsProps = {
        visible: cardVisible,
        setVisible: setCardVisible,
        className: 'vip-card-popup',
        width: 830,
        content: <ActivationCodeCard
            data={ null }
            langDict={ langDict }
            langState={ lang.state }
            fetchDataInit={ true }
            code={ activeUniqueId }
        />,
        maskClosable: true,
        centered: false,
        footer: null
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'gold',
                        'updateSuccessMsg',
                        'payAmountPlaceholder',
                        'amountError',
                        'amountLessThen0',
                        'shareCumulativeRewards',
                        'backgroundRecharge',
                        'loginSubmit',
                        'rechargeTitle',
                        'recharge',
                        'cardRechargeTitle',
                        'cardBalance',
                        'cardIncomeTitle',
                        'cardOutcomeTitle',
                        'cardWithdrawTitle',
                        'cardNumber',
                        'activationCardIsUseState',
                        'activationCardIsUseStateErr',
                        'cardValidity',
                        'cardFaceValue',
                        'copySuccess',
                        'orderNumber',
                        'otherAmounts'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            setStatisticsLoading(true)
            void statisticsApi().then(
                res => {
                    setStatisticsResp(res.data as StatisticsResp)
                }
            ).finally(
                () => {
                    setTimeout(
                        () => {
                            setStatisticsLoading(false)
                        },
                        1000
                    )
                }
            )
        },
        []
    )

    useEffect(
        () => {
            fetchList()
        },
        [ page, limit ]
    )

    return <div className="wallet-container">
        { cardVisible ? <Modals { ...searchModalProps } /> : '' }
        <Cards langDict={ langDict } statisticsResp={ statisticsResp } loading={ statisticsLoading } />
        {
            loading
                ? <Loading />
                : <>{
                    res.total <= 0
                        ? <div className="wh100 flex-cc"><Empty /></div>
                        : <div className="wallets">{
                            res.list.map(
                                (item, key) => <WalletItem
                                    key={ key }
                                    item={ item }
                                    langDict={ langDict }
                                    isLast={ key === res.list.length - 1 }
                                    active={ activateId === item.id }
                                    setCardVisible={ setCardVisible }
                                    setActiveUniqueId={ setActiveUniqueId }
                                />
                            )
                        }</div>
                }</>
        }
        <Pagination
            className="pagination"
            total={ res.total }
            callback={
                (current, pageSize) => {
                    scrollToTop(200, () => {
                        history.push(`${
                            RouteProfile.wallet
                        }/${ current }/${ pageSize }`)
                        setPage(current)
                        setLimit(pageSize)
                    })
                }
            }
            page={ page }
            pageSize={ limit }
        />
    </div>
}

export default Main