import { Solar } from 'lunar-typescript'

interface Dates {
    localeDate: string
    lunarDate: string
}

interface HolidayItem {
    title: HolidayTypes
    date: string
}

export enum HolidayTypes {
    h1 = '春节',
    h2 = '清明',
    h3 = '端午',
    h4 = '中秋',
    h5 = '除夕',
    h6 = '劳动节',
    h7 = '国庆节'
}

const toMap = (list: HolidayItem[]): Record<string, HolidayItem> => {
    const data: Record<string, HolidayItem> = {}
    for (let i = 0; i < list.length; i++) {
        const item = list[ i ]
        data[ item.date ] = item
    }

    return data
}

const lunarHolidays: HolidayItem[] = [
    ...Array.from(
        { length: 5 },
        (_, key) => ({
            title: HolidayTypes.h1,
            date: `1-${ key + 1 }`
        })
    ),
    ...Array.from(
        { length: 3 },
        (_, key) => ({
            title: HolidayTypes.h2,
            date: `4-${ key + 4 }`
        })
    ),
    ...Array.from(
        { length: 1 },
        (_, key) => ({
            title: HolidayTypes.h3,
            date: `5-${ key + 5 }`
        })
    ),
    ...Array.from(
        { length: 1 },
        (_, key) => ({
            title: HolidayTypes.h4,
            date: `8-${ key + 15 }`
        })
    ),
    ...Array.from(
        { length: 1 },
        (_, key) => ({
            title: HolidayTypes.h5,
            date: `12-${ key + 30 }`
        })
    )
]

const lunarHolidayMaps = toMap(lunarHolidays)

const holidays: HolidayItem[] = [
    ...Array.from(
        { length: 1 },
        (_, key) => ({
            title: HolidayTypes.h6,
            date: `5-${ key + 1 }`
        })
    ),
    ...Array.from(
        { length: 1 },
        (_, key) => ({
            title: HolidayTypes.h7,
            date: `10-${ key + 1 }`
        })
    )
]

const holidaysMaps = toMap(holidays)

const GetLunarMonthDates = (date: Date): Dates => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const solar = Solar.fromYmd(year, month, day)

    return {
        localeDate: `${ month }-${ day }`,
        lunarDate: `${ solar.getLunar().getMonth() }-${ solar.getLunar().getDay() }`
    }
}

export const HolidayScene = (date: Date): HolidayItem | null => {
    // 获取日期
    const currentDates = GetLunarMonthDates(date)
    const lunarHolidayDates = lunarHolidays.map(
        item => item.date
    )

    // 是否存在农历节日
    if (lunarHolidayDates.some(item => item === currentDates.lunarDate)) {
        return lunarHolidayMaps[ currentDates.lunarDate ]
    }

    const holidayDates = holidays.map(
        item => item.date
    )
    // 是否存在公历节日
    if (holidayDates.some(item => item === currentDates.localeDate)) {
        return holidaysMaps[ currentDates.localeDate ]
    }

    return null
}