import { type AxiosResponse } from 'axios'
import { service, proxy, route } from '#utils/axios'
import { type CoordinatesList } from '#repositories/types/foundation'
import { type UpdateItem } from '#repositories/types/request'

export interface LoginReqType {
    username: string
    password: string
    remember: boolean

    // dots: CoordinatesList
    // key: string
}

export interface RegisterReqType {
    username: string
    password: string
    email: string

    dots: CoordinatesList
    key: string
    shareOriginal: string
}

export interface SetPasswordReqType {
    password: string
    key: string
}

interface LoginUserInfoType {
    avatar: string
    nickname: string
    email: string
    level: number
    id: number
}

export interface LoginRespType {
    token: string
    expires: number // 秒
    userInfo: LoginUserInfoType
}

export enum UserColumns {
    username = 'username',
    password = 'password',
    email = 'email'
}

interface userinfoCompleteReq {
    column: UserColumns.username | UserColumns.email
    val: string
}

interface findPasswordReq {
    email: string
    key: string
    code: string
}

/**
 * 用户名密码登录
 * @param data
 */
export const login = async <T>(data: LoginReqType): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/login'),
        method: 'post',
        data
    })
}

export const register = async <T>(data: RegisterReqType): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/register'),
        method: 'post',
        data: {
            ...data,
            dots: JSON.stringify(data.dots)
        }
    })
}

/**
 * 检查信息是否被注册
 * @param column
 * @param val
 */
export const userinfoComplete = async <T>({ column, val }: userinfoCompleteReq): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/user/complete/${ column }/${ val }`),
        method: 'get'
    })
}

/**
 * 找回密码
 * @param username
 * @param key
 */
export const findPassword = async <T>({ email, key, code }: findPasswordReq): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/find-password'),
        method: 'post',
        data: { email, key, code }
    })
}

export const setPassword = async <T>(data: SetPasswordReqType): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/set-password'),
        method: 'post',
        data
    })
}

export interface UserItem {
    avatar: string
    createdAt: number
    email: string
    qq: string
    id: number
    ip: string
    level: number
    mobile: string
    brief: string
    nickname: string
    remark: string
    state: number
    subscriptionExpire: number
    updatedAt: number
    username: string
}

export const makeUserItem = (): UserItem => ({
    avatar: '',
    createdAt: 0,
    email: '',
    qq: '',
    id: 0,
    ip: '',
    level: 0,
    mobile: '',
    brief: '',
    nickname: '',
    remark: '',
    state: 0,
    subscriptionExpire: 0,
    updatedAt: 0,
    username: ''
})

export interface PasswordUpdateReq {
    password: string
    newPassword: string
    rePassword: string
}

export const makePasswordUpdateReq = (): PasswordUpdateReq => ({
    password: '',
    newPassword: '',
    rePassword: ''
})

export const row = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/user'),
        method: 'get'
    })
}

export const update = async <T>(data: UpdateItem[]): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/user'),
        method: 'put',
        data: { data }
    })
}

export const setPasswordWithOld = async <T>(oldPassword: string, newPassword: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/user/password'),
        method: 'put',
        data: {
            password: oldPassword,
            newPassword
        }
    })
}

export const bindEmail = async <T>(email: string, code: string, encrypt: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/user/bind/email'),
        method: 'post',
        data: {
            email,
            code,
            encrypt
        }
    })
}

export interface SubstationApplyForFields {
    name: string
    mobile: number
    remark: string
}

export const substationApplyFor = async <T>(data: SubstationApplyForFields): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/substation-apply'),
        method: 'post',
        data: { records: [ data ] }
    })
}

// 会员级别差价
export const levelPriceDifference = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/level-price-difference'),
        method: 'get'
    })
}

// 激活码价格差价
export const secretKeyPriceDifference = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/secret-key-price-difference'),
        method: 'get'
    })
}

// 解绑激活码
export const unbind = async <T>(secretKey: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/secret-key/unbind'),
        method: 'post',
        data: { secretKey }
    })
}