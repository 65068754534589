import { type AxiosResponse } from 'axios'
import { proxy, route, service } from '#utils/axios'
import { type Params } from '#repositories/types/request'
import { type LangItem } from '#repositories/types/foundation'

export interface StatisticsResp {
    balance: number
    recharge: number
    income: number
    outcome: number
    withdraw: number
}

export interface ListItem {
    id: number
    userid: number
    orderUniqueId: string
    recharge: number
    income: number
    outcome: number
    withdraw: number
    remark: string
    rechargeType: number
    createdAt: number
    updatedAt: number
}

export interface WalletsResp {
    list: ListItem[]
    total: number
}

export const makeStatisticsResp = (): StatisticsResp => ({
    balance: 0,
    recharge: 0,
    income: 0,
    outcome: 0,
    withdraw: 0
})

export const statistics = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/wallet/statistics'),
        method: 'get'
    })
}

export const list = async <T>(data: Params): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/wallets'),
        method: 'post',
        data
    })
}

export interface WithdrawListResp {
    list: WithdrawListItem[]
    total: number
}

export interface WithdrawListItem {
    id: number
    userid: number
    amounts: number
    status: number
    account: string
    name: string
    address: string
    reason: string
    remark: string
    actionAt: number
    createdAt: number
    updatedAt: number
}

export const withdrawList = async <T>(data: Params): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/wallet/withdraw/list'),
        method: 'post',
        data
    })
}

export interface ActivationCodeItem {
    uniqueId: string
    userid: number
    type: number
    num: number
    numUnit: number
    amount: number
    useDate: number
    remark: string
    isDel: number
    createdAt: number
    updatedAt: number
    title: LangItem
    brief: LangItem
}

// 回去卡密
export const activationCode = async <T>(uniqueId: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/activation-code/${ uniqueId }`),
        method: 'get'
    })
}

// 激活卡密
export const activationCodeActivation = async <T>(uniqueId: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/activation-code-activate/${ uniqueId }`),
        method: 'get'
    })
}