import React, { useEffect } from 'react'
import { Divider } from 'antd'
import { Language, Userinfo, UserLevelConfig } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { placeholder } from '#utils/localization/dictionary'
import localization, { type LT, pickSevLangKey } from '#utils/localization'
import { isEmpty, timestampFormat } from '#utils/functions'
import UpgradeList from '#components/upgrade'
import Loading from '#components/loading'
import { dateFormat3, pickUserLevelType, UserLevelType } from '#constants/appoint'

const Main: React.FC = () => {
    const userLevelConfig = new UserLevelConfig()
    const userinfo = new Userinfo().shared()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'regularUser',
                        'expiryTime',
                        'levelOfMembershipTitle',
                        'membershipInstructionsTitle',
                        'levelOfMembershipExpire',
                        'membershipInstructions'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return userinfo !== null && lang.state !== null
        ? <div className="h100 flex column ms">
            <div className="user-level-box sp">
                <div className="user-level">
                    <p className="title"><span className="simple">{ langDict.levelOfMembershipTitle }:</span> { pickSevLangKey(lang.state, userLevelConfig.state[ pickUserLevelType(userinfo.level) ]) }</p>
                    <p className="expire">{
                        UserLevelType.pro === userinfo.level
                            ? <>{ langDict.expiryTime }: { timestampFormat(userinfo.subscriptionExpire, dateFormat3) }</>
                            : <>{
                                userinfo.subscriptionExpire > 0 && !isEmpty(langDict.levelOfMembershipExpire) && userinfo.level !== UserLevelType.permanent
                                    ? langDict.levelOfMembershipExpire.replace(
                                        placeholder,
                                        timestampFormat(userinfo.subscriptionExpire, dateFormat3)
                                    )
                                    : ''
                            } </>
                    }</p>
                </div>
            </div>
            <UpgradeList hideTitle={ true } hideContent={ true } />
            <Divider plain />
            <div className="user-level-box">
                <p className="membership-instructions-title">{ langDict.membershipInstructionsTitle }</p>
                <ul className="membership-instructions" dangerouslySetInnerHTML={{ __html: langDict.membershipInstructions }} />
            </div>
        </div>
        : <Loading />
}

export default Main