import React, { useEffect } from 'react'
import { Col, Row, Divider, Input, Button } from 'antd'
import { ReactComponent as Lock } from '#assets/svg/lock.svg'
import localization, { type LT } from '#utils/localization'
import { textOnChange } from '#utils/events'
import { MessageType, MMessage } from '#components/hint'
import Icon from '#components/icon'
import { Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { makePasswordUpdateReq, type PasswordUpdateReq, setPasswordWithOld } from '#repositories/apis/user'

const Main: React.FC = () => {
    const lang = new Language()
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // 本地化
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // data
    const [ data, setData ] = useFetchState<PasswordUpdateReq>(makePasswordUpdateReq())

    const submit = (): void => {
        if (data.password === '' || data.newPassword === '' || data.rePassword === '') {
            MMessage({
                message: langDict.formColumnsBad,
                type: MessageType.error
            })
            return
        }

        if (data.newPassword !== data.rePassword) {
            MMessage({
                message: langDict.rePasswordFormRuleMsg,
                type: MessageType.error
            })
            return
        }

        setLoading(true)
        void setPasswordWithOld(data.password, data.newPassword).then(
            () => {
                MMessage({
                    message: langDict.updateSuccessMsg,
                    type: MessageType.success
                })
            }
        ).finally(
            () => {
                setTimeout(
                    () => {
                        setLoading(false)
                    },
                    1000
                )
            }
        )
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'updateSuccessMsg',
                        'formColumnsBad',
                        'password',
                        'oldPwdPlaceholder',
                        'newPwdPlaceholder',
                        'rePwdPlaceholder',
                        'confirmPassword',
                        'newPassword',
                        'confirm',
                        'rePasswordFormRuleMsg'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <div className="password-container">
        <Row className="form-columns">
            <Col span="24">
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.password }: </Col>
                    <Col span={ 15 }>
                        <Input.Password
                            allowClear
                            prefix={ <Icon><Lock /></Icon> }
                            visibilityToggle={ { visible: false } }
                            placeholder={ langDict.oldPwdPlaceholder }
                            onChange={
                                textOnChange(value => {
                                    setData({
                                        ...data,
                                        password: value
                                    })
                                })
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.newPassword }: </Col>
                    <Col span={ 15 }>
                        <Input.Password
                            allowClear
                            prefix={ <Icon><Lock /></Icon> }
                            visibilityToggle={ { visible: false } }
                            placeholder={ langDict.newPwdPlaceholder }
                            onChange={
                                textOnChange(value => {
                                    setData({
                                        ...data,
                                        newPassword: value
                                    })
                                })
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col span={ 5 } className="title">{ langDict.password }: </Col>
                    <Col span={ 15 }>
                        <Input.Password
                            allowClear
                            prefix={ <Icon><Lock /></Icon> }
                            visibilityToggle={ { visible: false } }
                            placeholder={ langDict.rePwdPlaceholder }
                            onChange={
                                textOnChange(value => {
                                    setData({
                                        ...data,
                                        rePassword: value
                                    })
                                })
                            }
                        />

                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col offset={ 5 } span={ 15 }>
                        <Button
                            type="primary"
                            className="submit background-button"
                            loading={ loading }
                            onClick={ submit }
                        >{ langDict.confirm }</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export default Main