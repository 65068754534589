import Products from '#pages/products'
import Product from '#pages/products/row'
import Future from '#pages/products/future'
import Baidu from '#pages/documents/baidu'
import SiteSecurity from '#pages/documents/site-security'
import Documents from '#pages/documents'
import Article from '#pages/documents/article'
import OrderRow from '#pages/orders/row'
import OrderQuery from '#pages/orders/query'
import Upgrade from '#pages/upgrade'
import GameBox from '#pages/game-box'
import OrderConfirmation from '#pages/orders/order-confirmation'
import Home from '#pages/home'
import LoginHome from '#pages/home/login-home'
import Profile from '#pages/profile'
// import Page404 from '#components/404'
import Banned from '#pages/banned'
import { type RouteConfig } from 'react-router-config'
import { Routes } from '#utils/router'

const routes: RouteConfig = [
    {
        path: `${ Routes.products }/:filters?/:page?/:limit?`,
        exact: true,
        component: Products
    },
    {
        path: `${ Routes.product }/:uniqueId/:secret?`,
        exact: true,
        component: Product
    },
    {
        path: `${ Routes.order }:uniqueId`,
        exact: true,
        component: OrderRow
    },
    {
        path: `${ Routes.orderQuery }/:uniqueId?`,
        exact: true,
        component: OrderQuery
    },
    {
        path: `${ Routes.orderConfirmation }/:uniqueId`,
        exact: true,
        component: OrderConfirmation
    },
    {
        path: `${ Routes.upgrade }`,
        exact: true,
        component: Upgrade
    },
    {
        path: Routes.profile,
        component: Profile
    },
    {
        path: Routes.future,
        component: Future
    },
    {
        path: Routes.baidu,
        component: Baidu
    },
    {
        path: Routes.siteSecurity,
        component: SiteSecurity
    },
    {
        path: `${ Routes.questionsAndAnswers }/:page?/:limit?`,
        component: Documents
    },
    {
        path: `${ Routes.article }:uniqueId`,
        component: Article
    },
    {
        path: Routes.home,
        exact: true,
        component: Home
    },
    {
        path: Routes.homeLogin,
        exact: true,
        component: LoginHome
    },
    {
        path: Routes.gameBox,
        exact: true,
        component: GameBox
    },
    {
        path: Routes.banned,
        exact: true,
        component: Banned
    },
    {
        path: '*',
        component: Home
        // component: Page404
    }
]

export default routes
