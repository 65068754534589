import React from 'react'
import { type RouteConfig } from 'react-router-config'
import { PageType } from '#components/signin/model'
import Signin from '#pages/signin'

const routes: RouteConfig = [
    {
        title: 'Login',
        name: 'login',
        path: '/login',
        exact: true,
        render: () => <Signin type={ PageType.login } />
    },
    {
        title: 'Register',
        name: 'register',
        path: '/register',
        exact: true,
        render: () => <Signin type={ PageType.register } />
    },
    {
        title: 'Find Password',
        name: 'find-password',
        path: '/find-password',
        exact: true,
        render: () => <Signin type={ PageType.findPassword } />
    },
    {
        title: 'Set Password',
        name: 'set-password',
        path: '/set-password/:key',
        exact: true,
        render: () => <Signin type={ PageType.setPassword } />
    }
]

export default routes
