import React, { useEffect } from 'react'
import { Row, Col, Layout } from 'antd'
import { QRNormal } from 'react-qrbtf'
import Screen from 'screenfull'
import { Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import Location from '#components/location'
import Logo from '#components/logo'
import Icon from '#components/icon'
import { ReactComponent as Diamond } from '#assets/svg/diamond1.svg'
import { ReactComponent as FullScreen } from '#assets/svg/full-screen.svg'
import { ReactComponent as FullScreenExit } from '#assets/svg/full-screen-exit.svg'
import { ReactComponent as GoTop } from '#assets/svg/go-top.svg'
import { ReactComponent as QQ } from '#assets/svg/qq.svg'
import { ReactComponent as WebsiteBuild } from '#assets/svg/website-build.svg'
import { ReactComponent as Server } from '#assets/svg/server.svg'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { RouteProfile, Routes } from '#utils/router'
import { scrollToTop } from '#utils/events'
import { isEmpty } from '#utils/functions'
import $ from 'jquery'
import { Modals, type ModalsProps } from '#components/hint'

const { Footer } = Layout

const Main: React.FC = () => {
    const configs = new Configs()
    const configsState = configs.shared()
    const lang = new Language()
    // language
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 全屏/退出全屏
    const [ fullScreen, setFullScreen ] = useFetchState(false)
    // 工具条显示隐藏
    const [ visible, setVisible ] = useFetchState(false)
    // 二维码弹窗
    const [ qrCodeUrl, setQrCodeUrl ] = useFetchState('')
    const [ qrCodePopupVisible, setQrCodePopupVisible ] = useFetchState(false)
    const qrCodePopupModalProps: ModalsProps = {
        visible: qrCodePopupVisible,
        setVisible: setQrCodePopupVisible,
        width: 460,
        content: <QRNormal
            value={ qrCodeUrl }
            styles={ { svg: { width: 400 } } }
            type="rand"
            size={ 50 }
            opacity={ 100 }
            posType="planet"
            otherColor="#000000"
            posColor="#135681"
        />,
        maskClosable: true,
        centered: true,
        footer: null
    }
    const doSetQrCodePopupVisible = (url: string): void => {
        setQrCodeUrl(url)
        setQrCodePopupVisible(true)
    }
    // 全屏
    const doSetFullScreen = (): void => {
        const state = !fullScreen
        setFullScreen(state)

        if (state) {
            Screen.request().catch(
                err => {
                    console.log(err)
                }
            )
        } else {
            Screen.exit().catch(
                err => {
                    console.log(err)
                }
            )
        }
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'friendlyLinkExplain',
                        'memberRecharge',
                        'concatUs',
                        'blowUpQrCode',
                        'onlineConsultant',
                        'toolServer',
                        'toolQQGroup',
                        'toolBuildWebsite',
                        'toolFullscreenExit',
                        'toolFullscreen',
                        'headerMenuUpgrade'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            $(document).scroll(function() {
                setVisible(($(document).scrollTop() ?? 0) > 0)
            })
        },
        []
    )

    return <Footer className="layer-footer">
        <div className={ `toolbar${ visible ? '' : ' opacity-0' }` }>
            <div className="tool">
                <Location className="item vip" to={ Routes.upgrade }>
                    <Icon className="i-4x"><Diamond /></Icon>
                    <span className="font-center">SVIP</span>
                </Location>
                {
                    configs.state !== null && !isEmpty(configs.state.serverUrl)
                        ? <Location to={ configs.state.serverUrl } target="_blank" className="item server">
                            <Icon className="i-4x"><Server /></Icon>
                            <span className="font-center">{ langDict.toolServer }</span>
                        </Location>
                        : ''
                }
                {
                    configs.state !== null && !isEmpty(configs.state.qqGroup)
                        ? <Location to={ configs.state.qqGroup } target="_blank" className="item qq">
                            <Icon className="i-4x"><QQ /></Icon>
                            <span className="font-center">{ langDict.toolQQGroup }</span>
                        </Location>
                        : ''
                }
                {
                    configs.state !== null && !isEmpty(configs.state.buildWebsiteUrl)
                        ? <Location to={ configs.state.buildWebsiteUrl } target="_blank" className="item build">
                            <Icon className="i-3x"><WebsiteBuild /></Icon>
                            <span className="font-center">{ langDict.toolBuildWebsite }</span>
                        </Location>
                        : ''
                }
                <div className={ `item full-screen${ fullScreen ? ' active' : '' }` } onClick={ doSetFullScreen }>
                    <Icon className="i-3x">
                        { fullScreen ? <FullScreenExit/> : <FullScreen/> }
                    </Icon>
                    <span className="font-center">
                        { fullScreen ? langDict.toolFullscreenExit : langDict.toolFullscreen }
                    </span>
                </div>
            </div>
            <div className="item go-top flex-cc" onClick={ () => { scrollToTop(200) } }>
                <Icon className="i-4x"><GoTop /></Icon>
            </div>
        </div>
        <Row className="box">
            <Col span={ 14 }>
                {
                    configsState !== null && configsState.friendlyLinks.length > 0
                        ? <Col span={ 24 } className="links">
                            <span>{ langDict.friendlyLinkExplain }: </span>
                            {
                                configsState.friendlyLinks.map(
                                    (item, key) => <Location key={ key } to={ item.url } target="_blank">
                                        { pickLangKey(lang.state, item.title) }
                                    </Location>
                                )
                            }
                        </Col>
                        : ''
                }
                <Col span={ 24 } className="statement-box">
                    <Logo />
                    <div className="maps">
                        <p>
                            <Location to={ RouteProfile.wallet }>{ langDict.memberRecharge }</Location>
                            <span>|</span>
                            <Location to={ Routes.upgrade }>{ langDict.headerMenuUpgrade }</Location>
                            <span>|</span>
                            <Location to={ RouteProfile.substation }>{ langDict.concatUs }</Location>
                        </p>
                        <p className="statement">
                            { configsState !== null ? pickLangKey(lang.state, configsState.footerStatement) : '' }
                        </p>
                    </div>
                </Col>
            </Col>
            <Col span={ 10 } className="flex-end">
                { qrCodePopupVisible ? <Modals { ...qrCodePopupModalProps } /> : '' }
                <div className="codes">{
                    configsState !== null
                        ? configsState.footerCodes.map(
                            (item, key) => <div
                                key={ key }
                                className="item cursor-pointer"
                                onClick={
                                    () => {
                                        doSetQrCodePopupVisible(item.url)
                                    }
                                }
                            >
                                <p>{ pickLangKey(lang.state, item.title) }</p>
                                <div className="code">
                                    <QRNormal
                                        value={ item.url }
                                        styles={ { svg: { width: 80 } } }
                                        type="rand"
                                        size={ 50 }
                                        opacity={ 100 }
                                        posType="planet"
                                        otherColor="#000000"
                                        posColor="#135681"
                                    />
                                </div>
                                <span className="hint">{ langDict.blowUpQrCode }</span>
                            </div>
                        )
                        : ''
                }</div>
            </Col>
        </Row>
    </Footer>
}

export default Main