import { isEmpty } from '#utils/functions'

export const placeholder = '$$'
export const placeholder1 = '##'

export const localization: Record<string, Record<string, string>> = {
    login: {
        zh: '登录',
        zh_tw: '登錄',
        en: 'Login'
    },
    codePlaceholder: {
        zh: '请输入验证码',
        zh_tw: '請輸入驗證碼',
        en: 'Please input code!'
    },
    usernamePlaceholder: {
        zh: '请输入用户名或邮箱',
        zh_tw: '請輸入用戶名或郵箱',
        en: 'Please enter your username or email'
    },
    nicknamePlaceholder: {
        zh: '请输入昵称',
        zh_tw: '請輸入暱稱',
        en: 'Please enter your nickname'
    },
    nickname: {
        zh: '昵称',
        zh_tw: '暱稱',
        en: 'Nickname'
    },
    emailPlaceholder: {
        zh: '请输入邮箱',
        zh_tw: '請輸入郵箱',
        en: 'Please enter email'
    },
    qq: {
        zh: 'QQ',
        zh_tw: 'QQ',
        en: 'QQ Account'
    },
    qqPlaceholder: {
        zh: '请输入QQ',
        zh_tw: '請輸入QQ',
        en: 'Please enter QQ account'
    },
    brief: {
        zh: '简介',
        zh_tw: '簡介',
        en: 'Brief Introduction'
    },
    briefPlaceholder: {
        zh: '请输入简介',
        zh_tw: '請輸入簡介',
        en: 'Please enter Brief Introduction'
    },
    mobile: {
        zh: '手机号码',
        zh_tw: '手機號碼',
        en: 'Phone Number'
    },
    mobilePlaceholder: {
        zh: '请输入联系手机号码',
        zh_tw: '請輸入手機號碼',
        en: 'Please enter phone number'
    },
    pwdPlaceholder: {
        zh: '请输入密码',
        zh_tw: '請輸入密碼',
        en: 'Please enter password'
    },
    oldPwdPlaceholder: {
        zh: '请输入旧密码',
        zh_tw: '請輸入舊密碼',
        en: 'Please enter your old password'
    },
    newPwdPlaceholder: {
        zh: '请输入新密码',
        zh_tw: '請輸入新密碼',
        en: 'Please enter your new password'
    },
    password: {
        zh: '密码',
        zh_tw: '密碼',
        en: 'Password'
    },
    newPassword: {
        zh: '新密码',
        zh_tw: '新密碼',
        en: 'New Password'
    },
    confirmPassword: {
        zh: '确认密码',
        zh_tw: '確認密碼',
        en: 'Confirm The Password'
    },
    rePwdPlaceholder: {
        zh: '请再次确认密码',
        zh_tw: '請再次確認密碼',
        en: 'Please confirm the password again'
    },
    loginRemember: {
        zh: '记住我',
        zh_tw: '記住我',
        en: 'remember me'
    },
    loginSubmit: {
        zh: '提交',
        zh_tw: '提交',
        en: 'Login'
    },
    loginSubmit1: {
        zh: '登录',
        zh_tw: '登錄',
        en: 'Login'
    },
    rechargeSubmit: {
        zh: '充值',
        zh_tw: '充值',
        en: 'Recharge'
    },
    forgetPwd: {
        zh: '忘记密码',
        zh_tw: '忘記密碼',
        en: 'forget the password'
    },
    imageCaptchaTitle: {
        zh: '请在下图依次点击',
        zh_tw: '請在下圖依次點擊',
        en: 'Please click one by one in the image below'
    },
    buttonContent: {
        zh: '确认',
        zh_tw: '確認',
        en: 'verify'
    },
    buttonSave: {
        zh: '保存',
        zh_tw: '保存',
        en: 'save'
    },
    imageCaptchaBad: {
        zh: '请完成验证',
        zh_tw: '請完成驗證',
        en: 'Please complete the verification'
    },
    formColumnsBad: {
        zh: '请填写完所有表单字段',
        zh_tw: '請填寫完所有表單字段',
        en: 'Please complete all form fields'
    },
    registerLink: {
        zh: '点击注册',
        zh_tw: '點擊註冊',
        en: 'Create one'
    },
    registerLinkPrefix: {
        zh: '没有账号?',
        zh_tw: '沒有賬號?',
        en: 'No account? '
    },
    allGames: {
        zh: '所有游戏',
        zh_tw: '所有遊戲',
        en: 'All Games'
    },
    registerSuccess: {
        zh: '注册成功!',
        zh_tw: '註冊成功!',
        en: 'You\'ve successfully registered!'
    },
    register: {
        zh: '注册',
        zh_tw: '註冊',
        en: 'Create an account to DMDW'
    },
    registerJoinUs: {
        zh: '畅玩尽在指尖，立即注册！',
        zh_tw: '暢玩盡在指尖，立即註冊！',
        en: 'Gaming at Your Fingertips, Register Now!'
    },
    buyNow: {
        zh: '立即购买',
        zh_tw: '立即購買',
        en: 'Buy Now'
    },
    upgradeNow: {
        zh: '立即升级',
        zh_tw: '立即升級',
        en: 'Upgrade Now'
    },
    giveAReward: {
        zh: '打赏',
        zh_tw: '打賞',
        en: 'Give a reward'
    },
    privacy: {
        zh: '隐私协议',
        zh_tw: '隱私協議',
        en: 'Privacy Policy.'
    },
    loginLinkTipsPrefix: {
        zh: '已有账号! ',
        zh_tw: '已有賬號! ',
        en: 'Already have an account! '
    },
    loginLinkTips: {
        zh: ' 去登录',
        zh_tw: ' 去登錄',
        en: ' Go to login'
    },
    usernameFormRuleMsg: {
        zh: '请输入用户名!',
        zh_tw: '請輸入用戶名!',
        en: 'Please input your username!'
    },
    usernameFormRuleValidFailedMsg: {
        zh: '请输入合法用户名, 长度不能超过20!',
        zh_tw: '請輸入合法用戶名, 長度不能超過20!',
        en: 'Please enter a valid username, The length cannot exceed 20!'
    },
    usernameCompleteFormRuleMsg: {
        zh: '用户名已被使用,请更换!',
        zh_tw: '用戶名已被使用,請更換!',
        en: 'The user name is already in use. Please change it!'
    },
    passwordFormRuleMsg: {
        zh: '请输入密码!',
        zh_tw: '請輸入密碼!',
        en: 'Please input your password!'
    },
    rePasswordFormRuleMsg: {
        zh: '两次密码不匹配',
        zh_tw: '兩次密碼不匹配',
        en: 'The new password that you entered do not match!'
    },
    emailFormRuleMsg: {
        zh: '请输入正确的邮箱!',
        zh_tw: '請輸入正確的郵箱!',
        en: 'The input is not valid E-mail!'
    },
    qqFormRuleMsg: {
        zh: '请输入正确的QQ!',
        zh_tw: '請輸入正確的QQ!',
        en: 'The input is not valid QQ account!'
    },
    mobileFormRuleMsg: {
        zh: '请输入正确的手机号码!',
        zh_tw: '請輸入正確的手機號碼!',
        en: 'The input is not valid phone number!'
    },
    emailCompleteFormRuleMsg: {
        zh: '邮箱已被使用,请更换!',
        zh_tw: '郵箱已被使用,請更換!',
        en: 'The email is already in use. Please change it!'
    },
    emailExistsFormRuleMsg: {
        zh: '此邮箱未绑定账号!',
        zh_tw: '此郵箱未綁定賬號!',
        en: 'This email address is not bound to an account!'
    },
    codeFormRuleMsg: {
        zh: '请填写验证码!',
        zh_tw: '請填寫驗證碼!',
        en: 'Please fill in the verification code!'
    },
    privacyFormRuleMsg: {
        zh: '请仔细阅读并接受协议!',
        zh_tw: '請仔細閱讀並接受協議!',
        en: 'Should accept agreement!'
    },
    findPassword: {
        zh: '找回密码',
        zh_tw: '找回密碼',
        en: 'Retrieve Password'
    },
    goLoginLinkTips: {
        zh: '去登录!',
        zh_tw: '去登錄!',
        en: 'log in!'
    },
    confirm: {
        zh: '提交',
        zh_tw: '提交',
        en: 'Submit'
    },
    emailCaptchaSendSuccess: {
        zh: '邮箱验证码发送成功, 请前往邮箱查看',
        zh_tw: '郵箱驗證碼發送成功, 請前往郵箱查看',
        en: 'Email verification code sent successfully, please go to the email to check'
    },
    sameEmailMsg: {
        zh: '新绑定邮箱与旧邮箱相同',
        zh_tw: '新綁定郵箱與舊郵箱相同',
        en: 'The new bound mailbox is the same as the old mailbox'
    },
    getCaptchaCode: {
        zh: '获取验证码',
        zh_tw: '獲取驗證碼',
        en: 'get code'
    },
    captchaCode: {
        zh: '验证码',
        zh_tw: '驗證碼',
        en: 'code'
    },
    getCaptchaCodeCountdown: {
        zh: placeholder + '秒后重新获取',
        zh_tw: placeholder + '秒後重新獲取',
        en: 'Retrieve it in $$ seconds'
    },
    findPasswordValidatorSuccess: {
        zh: '证码成功, 请重新设置密码',
        zh_tw: '證碼成功, 請重新設置密碼',
        en: 'Verification successful, please reset password'
    },
    setPassword: {
        zh: '设置密码',
        zh_tw: '設置密碼',
        en: 'Reset Password'
    },
    setPasswordSuccess: {
        zh: '密码更新成功, 请重新登录',
        zh_tw: '密碼更新成功, 請重新登錄',
        en: 'Password updated successfully, please log in again'
    },
    searchInputPlaceholder: {
        zh: '请输入搜索内容',
        zh_tw: '請輸入搜索內容',
        en: 'Please enter your search term'
    },
    headerMenuHome: {
        zh: '主页',
        zh_tw: '主頁',
        en: 'HOME'
    },
    headerMenuAllGames: {
        zh: '全部游戏',
        zh_tw: '全部遊戲',
        en: 'ALL GAMES'
    },
    headerMenuFuture: {
        zh: '更新记录',
        zh_tw: '更新記錄',
        en: 'FUTURE'
    },
    headerMenuUpgrade: {
        zh: '开通会员',
        zh_tw: '開通會員',
        en: 'UPGRADE'
    },
    headerMenuGameBox: {
        zh: '游戏盒子',
        zh_tw: '遊戲盒子',
        en: 'GAMEBOX'
    },
    orderQueryButtonTitle: {
        zh: '查看示例',
        zh_tw: '查看示例',
        en: 'View examples'
    },
    headerMenuOrderQuery: {
        zh: '订单查询',
        zh_tw: '訂單查詢',
        en: 'Order Query'
    },
    queryTitle: {
        zh: '查询',
        zh_tw: '查詢',
        en: 'Query'
    },
    searchOrderInputPlaceholder: {
        zh: '请输入订单号',
        zh_tw: '請輸入訂單號',
        en: 'Please enter your order id'
    },
    searchOrderError: {
        zh: '未查到该订单信息, 请检查订单号是否正确',
        zh_tw: '未查到該訂單信息, 請檢查訂單號是否正確',
        en: 'The order information is not found, please check whether the order number is correct'
    },
    queryExplainTitle: {
        zh: '订单号获取',
        zh_tw: '訂單號獲取',
        en: 'Order number acquisition'
    },
    headerMenuBaidu: {
        zh: '百度云VIP',
        zh_tw: '百度雲VIP',
        en: 'BAIDU VIP'
    },
    popularSearches: {
        zh: '热门搜索',
        zh_tw: '熱門搜索',
        en: 'Popular Searches'
    },
    headerMenuQuestionsAndAnswers: {
        zh: '常见问题',
        zh_tw: '常見問題',
        en: 'Q&A'
    },
    siteSecurity: {
        zh: '网站防丢失',
        zh_tw: '網站防丟失',
        en: 'Site Security'
    },
    search: {
        zh: '搜索',
        zh_tw: '搜索',
        en: 'Searches'
    },
    noMoreData: {
        zh: '数据已全部加载完毕',
        zh_tw: '數據已全部加載完畢',
        en: 'All data has been loaded'
    },
    checkMore: {
        zh: '查看更多 >>',
        zh_tw: '查看更多 >>',
        en: 'More >>'
    },
    checkMore1: {
        zh: '查看更多',
        zh_tw: '查看更多',
        en: 'More'
    },
    weekPopularGamesTitle: {
        zh: '本周热门',
        zh_tw: '本週熱門',
        en: 'Hot This Week'
    },
    monthPopularGamesTitle: {
        zh: '当月最热',
        zh_tw: '當月最熱',
        en: 'Hottest This Month'
    },
    sitePopularGamesTitle: {
        zh: '全站最热',
        zh_tw: '全站最熱',
        en: 'Hottest Site-wide'
    },
    site: {
        zh: '全站',
        zh_tw: '全站',
        en: 'website'
    },
    diskSpaceTitle: {
        zh: '磁盘空间',
        zh_tw: '磁碟空間',
        en: 'Disk Space'
    },
    upgrade: {
        zh: '升级',
        zh_tw: '升級',
        en: 'Upgrade'
    },
    popular: {
        zh: '热度',
        zh_tw: '熱度',
        en: 'Popular'
    },
    exclusive: {
        zh: '限定',
        zh_tw: '限定',
        en: 'Exclusive'
    },
    default: {
        zh: '默认',
        zh_tw: '默認',
        en: 'Default'
    },
    diskSpace: {
        zh: '存储空间',
        zh_tw: '存儲空間',
        en: 'Space'
    },
    sortDesc: {
        zh: '倒序',
        zh_tw: '倒序',
        en: 'Desc'
    },
    sortAsc: {
        zh: '正序',
        zh_tw: '正序',
        en: 'Asc'
    },
    downloads: {
        zh: '下载量',
        zh_tw: '下載量',
        en: 'Downloads'
    },
    downloadGame: {
        zh: '下载游戏',
        zh_tw: '下載遊戲',
        en: 'Download game'
    },
    download: {
        zh: '下载',
        zh_tw: '下載',
        en: 'Download'
    },
    all: {
        zh: '全部',
        zh_tw: '全部',
        en: 'All'
    },
    favorite: {
        zh: '收藏',
        zh_tw: '收藏',
        en: 'Favorite'
    },
    like: {
        zh: '点赞',
        zh_tw: '點贊',
        en: 'Like'
    },
    updatedAt: {
        zh: '更新时间',
        zh_tw: '更新時間',
        en: 'Updated'
    },
    publishTime: {
        zh: '发布时间',
        zh_tw: '发布時間',
        en: 'Published'
    },
    labels: {
        zh: '标签',
        zh_tw: '標籤',
        en: 'Labels'
    },
    price: {
        zh: '单价',
        zh_tw: '單價',
        en: 'Price'
    },
    promoExpire: {
        zh: '限时优惠',
        zh_tw: '限時優惠',
        en: 'Limited Time Offer'
    },
    buy: {
        zh: '立即购买',
        zh_tw: '立即購買',
        en: 'Buy Now'
    },
    loginWithBuy: {
        zh: '点击登录后购买',
        zh_tw: '點擊登錄後購買',
        en: 'Log In and Purchase'
    },
    guestCheckoutWithBuy: {
        zh: '免登录购买',
        zh_tw: '免登錄購買',
        en: 'Sign-in free purchase'
    },
    loginWithFreeGet: {
        zh: '登录后免费获取',
        zh_tw: '登錄後免費獲取',
        en: 'Sign in and get it for free'
    },
    preview: {
        zh: '浏览量',
        zh_tw: '瀏覽量',
        en: 'PV'
    },
    productRowLang: {
        zh: '语言版本',
        zh_tw: '語言版本',
        en: 'Language version'
    },
    productRowLangContent: {
        zh: '中文 英文',
        zh_tw: '中文 英文',
        en: 'Chinese English'
    },
    screenshot: {
        zh: '游戏截图',
        zh_tw: '遊戲截圖',
        en: 'Screenshot'
    },
    videoBrief: {
        zh: '视频简介',
        zh_tw: '視頻簡介',
        en: 'Video Introduction'
    },
    gameContent: {
        zh: '游戏介绍',
        zh_tw: '遊戲介紹',
        en: 'Introduction'
    },
    loginSuccessWidth: {
        zh: '登录成功',
        zh_tw: '登錄成功',
        en: 'login successfully'
    },
    orderConfirm: {
        zh: '订单信息确认',
        zh_tw: '訂單信息確認',
        en: 'Order Preview Confirmation'
    },
    goodsName: {
        zh: '商品名称',
        zh_tw: '商品名稱',
        en: 'Commodity Name'
    },
    amount: {
        zh: '金额',
        zh_tw: '金額',
        en: 'Amount'
    },
    payAmount: {
        zh: '支付金额',
        zh_tw: '支付金額',
        en: 'Payment Amount'
    },
    orderAmount: {
        zh: '订单金额',
        zh_tw: '訂單金額',
        en: 'Order Amount'
    },
    buyExplainTitle: {
        zh: '购买说明',
        zh_tw: '購買說明',
        en: 'Purchase Instructions'
    },
    buyExplainContent: {
        zh: '该商品为虚拟商品，支付成功后，商品将自动发送到您的电子邮箱',
        zh_tw: '該商品爲虛擬商品，支付成功後，商品將自動發送到您的電子郵箱',
        en: 'This item is a virtual product; upon successful payment, it will be automatically delivered to your email address'
    },
    payMethod: {
        zh: '支付方式',
        zh_tw: '支付方式',
        en: 'Payment Method'
    },
    email: {
        zh: '电子邮箱',
        zh_tw: '電子郵箱',
        en: 'Email Address'
    },
    orderEmailPlaceholder: {
        zh: '发货信息接收邮箱',
        zh_tw: '發貨信息接收郵箱',
        en: 'Shipping Information Recipient Email'
    },
    goBack: {
        zh: '返回',
        zh_tw: '返回',
        en: 'Go Back'
    },
    orderDetailTitle: {
        zh: '订单详情',
        zh_tw: '訂單詳情',
        en: 'Order Information'
    },
    detailTitle: {
        zh: '详情',
        zh_tw: '詳情',
        en: 'Information'
    },
    orderDetailList: {
        zh: '订单列表',
        zh_tw: '訂單列表',
        en: 'My Order List'
    },
    orderMakeCreatedTitle: {
        zh: '订单创建于',
        zh_tw: '訂單創建於',
        en: 'Order created on'
    },
    orderPayStateWait: {
        zh: '待支付',
        zh_tw: '待支付',
        en: 'Pending Payment'
    },
    orderPayStateSuccess: {
        zh: '支付成功',
        zh_tw: '支付成功',
        en: 'Payment Successful'
    },
    orderPayStatePayCancel: {
        zh: '取消',
        zh_tw: '取消',
        en: 'Cancel'
    },
    orderPayStatePayAbnormal: {
        zh: '交易异常',
        zh_tw: '交易異常',
        en: 'Transaction Abnormal'
    },
    orderPayStateRefund: {
        zh: '退款',
        zh_tw: '退款',
        en: 'Refund'
    },
    orderPayStateNotPay: {
        zh: '未支付',
        zh_tw: '未支付',
        en: 'Unpaid'
    },
    orderPayStateClosed: {
        zh: '已关闭',
        zh_tw: '已關閉',
        en: 'Closed'
    },
    orderPayStateRevoked: {
        zh: '已撤销',
        zh_tw: '已撤銷',
        en: 'Revoked'
    },
    orderPayStateUserPaying: {
        zh: '用户支付中',
        zh_tw: '用戶支付中',
        en: 'User Payment in Progress'
    },
    orderPayStatePayError: {
        zh: '支付失败',
        zh_tw: '支付失敗',
        en: 'Payment Failed'
    },
    orderPayStateUnknown: {
        zh: '未知状态',
        zh_tw: '未知狀態',
        en: 'Unknown State'
    },
    orderDone: {
        zh: '已完成',
        zh_tw: '已完成',
        en: 'Done'
    },
    orderNumber: {
        zh: '订单号',
        zh_tw: '訂單號',
        en: 'Order Number'
    },
    cardNumber: {
        zh: '卡号',
        zh_tw: '卡號',
        en: 'Card Number'
    },
    shareCumulativeRewards: {
        zh: '分享累计奖励',
        zh_tw: '分享累計獎勵',
        en: 'Share cumulative rewards'
    },
    backgroundRecharge: {
        zh: '后台充值',
        zh_tw: '後臺充值',
        en: 'Background recharge'
    },
    cardValidity: {
        zh: '有效期',
        zh_tw: '有效期',
        en: 'validity'
    },
    cardFaceValue: {
        zh: '面值',
        zh_tw: '面值',
        en: 'Face value'
    },
    networkDiskTitle: {
        zh: '网盘链接',
        zh_tw: '網盤連結',
        en: 'Cloud Link'
    },
    networkCodeTitle: {
        zh: '网盘提取码',
        zh_tw: '網盤提取碼',
        en: 'Cloud Extraction Code'
    },
    networkTitle: {
        zh: '网盘',
        zh_tw: '網盤',
        en: 'Cloud'
    },
    decryptionCodeTitle: {
        zh: '解压码',
        zh_tw: '解壓碼',
        en: 'Decryption Code'
    },
    installDecompressionCodeTitle: {
        zh: '解压密码',
        zh_tw: '解壓密碼',
        en: 'Decompression password'
    },
    userVipExpireTitle: {
        zh: '会员到期时间',
        zh_tw: '會員到期時間',
        en: 'Membership expiration time'
    },
    profile: {
        zh: '个人中心',
        zh_tw: '個人中心',
        en: 'Profile'
    },
    logout: {
        zh: '退出登陆',
        zh_tw: '退出登陸',
        en: 'logout'
    },
    logoutConfirm: {
        zh: '确认退出登陆吗?',
        zh_tw: '確認退出登陸嗎?',
        en: 'Are you sure you\'re logged out?'
    },
    profileBalance: {
        zh: '我的钱包',
        zh_tw: '我的錢包',
        en: 'My Wallet'
    },
    profileMembership: {
        zh: '我的会员',
        zh_tw: '我的會員',
        en: 'My Membership'
    },
    profileExpenseRecords: {
        zh: '收支记录',
        zh_tw: '收支記錄',
        en: 'Expense Records'
    },
    profilePurchaseHistory: {
        zh: '购买记录',
        zh_tw: '購買記錄',
        en: 'Purchase History'
    },
    profileFavorites: {
        zh: '我的收藏',
        zh_tw: '我的收藏',
        en: 'My Favorites'
    },
    profileWithdraw: {
        zh: '提现记录',
        zh_tw: '提現記錄',
        en: 'Withdrawal record'
    },
    profileSubstation: {
        zh: '分站申请',
        zh_tw: '分站申請',
        en: 'Join us'
    },
    activationCodes: {
        zh: '卡密激活',
        zh_tw: '卡密激活',
        en: 'Activation codes'
    },
    activation: {
        zh: '确认激活',
        zh_tw: '確認激活',
        en: 'Confirm activation'
    },
    fetchActivation: {
        zh: '点击获取',
        zh_tw: '點擊獲取',
        en: 'Click to Get'
    },
    activationCardNumErr: {
        zh: '卡号错误',
        zh_tw: '卡號錯誤',
        en: 'Card number error'
    },
    activationCardIsUseStateErr: {
        zh: '卡密已被使用, 请确认卡号后重试',
        zh_tw: '卡密已被使用, 請確認卡號後重試',
        en: 'The card is in use. Please confirm the card number and try again'
    },
    activationCardIsUseState: {
        zh: '已被使用',
        zh_tw: '已被使用',
        en: 'The card is in use'
    },
    activationCardNumEmptyErr: {
        zh: '卡号不能为空',
        zh_tw: '卡號不能爲空',
        en: 'The card number cannot be empty'
    },
    activationCardNumFormatErr: {
        zh: '卡号格式错误',
        zh_tw: '卡號格式錯誤',
        en: 'The card number format is incorrect'
    },
    activationCardActivationSuccess: {
        zh: '激活成功',
        zh_tw: '激活成功',
        en: 'Successful activation'
    },
    activationCardNumPlaceholder: {
        zh: '请输入卡号',
        zh_tw: '請輸入卡號',
        en: 'Please enter the card number'
    },
    profileInformation: {
        zh: '基本资料',
        zh_tw: '基本資料',
        en: 'Basic Information'
    },
    profileEmailBinding: {
        zh: '邮箱绑定',
        zh_tw: '郵箱綁定',
        en: 'Email Binding'
    },
    profileEmailUpdate: {
        zh: '修改邮箱',
        zh_tw: '修改郵箱',
        en: 'Modify email'
    },
    unbindingCode: {
        zh: '游戏盒子解绑',
        zh_tw: '游戏盒子解绑',
        en: 'Unbinding code'
    },
    profilePasswordSettings: {
        zh: '密码设置',
        zh_tw: '密碼設置',
        en: 'Password Settings'
    },
    profileMemberCenter: {
        zh: '会员中心',
        zh_tw: '會員中心',
        en: 'Member Center'
    },
    profileGameBox: {
        zh: '游戏盒子',
        zh_tw: '遊戲盒子',
        en: 'Game Box'
    },
    profileAccountInformation: {
        zh: '账号信息',
        zh_tw: '賬號信息',
        en: 'Account Information'
    },
    membershipInstructionsTitle: {
        zh: '开通会员说明',
        zh_tw: '開通會員說明',
        en: 'Membership Instructions'
    },
    levelOfMembershipTitle: {
        zh: '会员等级',
        zh_tw: '會員等級',
        en: 'Level of membership'
    },
    levelOfMembershipExpire: {
        zh: `您的会员已于${ placeholder }到期`,
        zh_tw: `您的會員已於${ placeholder }到期`,
        en: `Your membership expired in ${ placeholder }`
    },
    membershipInstructions: {
        zh: `<li>本站会员账号权限为虚拟数字资源，开通后不可退款</li>
<li>开通会员后可享有对应会员特权的商品折扣，免费权限</li>
<li>会员特权到期后不享受特权</li>
<li>重复购买特权到期时间累计增加</li>`,
        zh_tw: `<li>會員帳號權限為虛擬數字資源，開通後不可退款。</li>
<li>開通會員後可享有對應會員特權的商品折扣，免費權限。</li>
<li>會員特權到期後不享受特權。</li>
<li>重複購買特權到期時間累計增加。</li>`,
        en: `<li>Member account privileges are virtual digital assets, and once activated, they are non-refundable.</li>
<li>Upon becoming a member, you can enjoy discounts on products corresponding to your membership privileges, as well as access to certain items for free.</li>
<li>After the expiration of membership privileges, you will no longer have access to these benefits.</li>
<li>If you repeatedly purchase privileges, the expiration time will accumulate.</li>`
    },
    rechargeTitle: {
        zh: '充值说明',
        zh_tw: '充值說明',
        en: 'Top-up instructions'
    },
    recharge: {
        zh: `<li>充值最低额度为 1金币</li>
<li>充值汇率为 1金币 = 1RMB</li>
<li>金币永久有效，无时间限制</li>`,
        zh_tw: `<li>充值最低額度爲 1金幣</li>
<li>充值匯率爲 1金幣 = 1RMB</li>
<li>金幣永久有效，無時間限制</li>`,
        en: `<li>The minimum top-up is 1 gold coin</li>
<li>The top-up exchange rate is 1 gold coin = 1RMB</li>
<li>Gold coins are valid forever and have no time limit</li>`
    },
    regularUser: {
        zh: '普通用户',
        zh_tw: '普通用戶',
        en: 'Regular User'
    },
    expiryTime: {
        zh: '到期时间',
        zh_tw: '到期時間',
        en: 'Expiry Time'
    },
    orderProductTitle: {
        zh: '商品',
        zh_tw: '商品',
        en: 'Product'
    },
    orderMembershipCardTitle: {
        zh: '会员卡',
        zh_tw: '會員卡',
        en: 'Membership Card'
    },
    orderRechargeTitle: {
        zh: '充值',
        zh_tw: '充值',
        en: 'Recharge'
    },
    orderWithdrawTitle: {
        zh: '提现',
        zh_tw: '提現',
        en: 'Withdraw'
    },
    orderUserLevelUpgradeTitle: {
        zh: '会员升级',
        zh_tw: '會員升級',
        en: 'Membership Upgrade'
    },
    orderUserLevelUpgradeDiffTitle: {
        zh: '会员升级补差价',
        zh_tw: '會員升級補差價',
        en: 'Membership Upgrade'
    },
    orderGiftTitle: {
        zh: 'CMDW 打赏❤️',
        zh_tw: 'CMDW 打賞❤️',
        en: 'CMDW Give a reward ❤️'
    },
    orderAppCodeTitle: {
        zh: '软件激活码',
        zh_tw: '軟件激活碼',
        en: 'Software Activation code'
    },
    orderTypeTitle: {
        zh: '订单类型',
        zh_tw: '訂單類型',
        en: 'Order Types'
    },
    orderStatusTitle: {
        zh: '订单状态',
        zh_tw: '訂單狀態',
        en: 'Order Status'
    },
    orderPayDateTitle: {
        zh: '支付时间',
        zh_tw: '支付時間',
        en: 'Time Of Payment'
    },
    pay: {
        zh: '支付',
        zh_tw: '支付',
        en: 'PAY'
    },
    paying: {
        zh: '支付中',
        zh_tw: '支付中',
        en: 'Paying'
    },
    orderPayStateTitle: {
        zh: '支付状态',
        zh_tw: '支付狀態',
        en: 'Payment State'
    },
    viewDetails: {
        zh: '查看详情',
        zh_tw: '查看詳情',
        en: 'View Details'
    },
    copySuccess: {
        zh: '复制成功',
        zh_tw: '複製成功',
        en: 'Copy Successful'
    },
    shareCopySuccess: {
        zh: '分享链接复制成功, 快去分享给你的好友吧',
        zh_tw: '分享鏈接複製成功, 快去分享給你的好友吧',
        en: 'Share link copy success, go to share with your friends'
    },
    clickToCopy: {
        zh: '点击复制',
        zh_tw: '點擊複製',
        en: 'Click to copy'
    },
    userid: {
        zh: '用户ID',
        zh_tw: '用戶ID',
        en: 'USERID'
    },
    updateSuccessMsg: {
        zh: '更新成功',
        zh_tw: '更新成功',
        en: 'update successfully'
    },
    currentEmail: {
        zh: '当前邮箱',
        zh_tw: '當前郵箱',
        en: 'Current mailbox'
    },
    otherAmounts: {
        zh: '充值其他金额',
        zh_tw: '充值其他金額',
        en: 'Top up other amounts'
    },
    payAmountPlaceholder: {
        zh: '请输入其他金额',
        zh_tw: '請輸入其他金額',
        en: 'Please input other amounts'
    },
    amountLessThen0: {
        zh: '金额不能小于0',
        zh_tw: '金額不能小於0',
        en: 'The amount cannot be less than 0'
    },
    amountLessThenBalance: {
        zh: '提现金额不能大于余额',
        zh_tw: '提現金額不能大於餘額',
        en: 'The withdrawal amount cannot be greater than the balance'
    },
    withdrawalAmountLimit: {
        zh: '提现金额超过 ',
        zh_tw: '提現金額超過 ',
        en: 'The withdrawal amount exceeds '
    },
    cardRechargeTitle: {
        zh: '充值',
        zh_tw: '充值',
        en: 'recharge'
    },
    cardIncomeTitle: {
        zh: '收入',
        zh_tw: '收入',
        en: 'Income'
    },
    cardOutcomeTitle: {
        zh: '支出',
        zh_tw: '支出',
        en: 'Outcome'
    },
    cardWithdrawTitle: {
        zh: '提现',
        zh_tw: '提現',
        en: 'Withdraw'
    },
    cardBalance: {
        zh: '余额',
        zh_tw: '餘額',
        en: 'Balance'
    },
    withdrawAmountsPlaceholder: {
        zh: '请填写金额',
        zh_tw: '請填寫金額',
        en: 'Please fill amount'
    },
    withdrawAccountPlaceholder: {
        zh: '请填写收款支付宝账户',
        zh_tw: '請填寫收款支付寶賬戶',
        en: 'Please fill collection Alipay account'
    },
    withdrawNamePlaceholder: {
        zh: '请填写收款人姓名',
        zh_tw: '請填寫收款人姓名',
        en: 'Please fill name of payee'
    },
    withdrawAccount: {
        zh: '支付宝账户',
        zh_tw: '收款支付寶賬戶',
        en: 'Collection Alipay account'
    },
    withdrawName: {
        zh: '姓名',
        zh_tw: '收款人姓名',
        en: 'Name of payee'
    },
    withdrawAddressPlaceholder: {
        zh: '请填写开户行地址',
        zh_tw: '請填寫開戶行地址',
        en: 'Please fill account bank address'
    },
    withdrawSubmitSuccess: {
        zh: '提现申请提交成功, 请等待管理员审核',
        zh_tw: '提現申請提交成功, 請等待管理員審覈',
        en: 'The withdrawal application has been successfully submitted, please wait for administrator\'s review'
    },
    memberRecharge: {
        zh: '会员充值',
        zh_tw: '會員充值',
        en: 'Member recharge'
    },
    concatUs: {
        zh: '联系我们',
        zh_tw: '聯繫我們',
        en: 'Concat us'
    },
    withdrawStateWaiting: {
        zh: '待处理',
        zh_tw: '待處理',
        en: 'Waiting'
    },
    withdrawStateDone: {
        zh: '已完成',
        zh_tw: '已完成',
        en: 'Done'
    },
    withdrawStateRefused: {
        zh: '拒绝',
        zh_tw: '拒絕',
        en: 'Refused'
    },
    withdrawApplicationTime: {
        zh: '申请时间',
        zh_tw: '申請時間',
        en: 'application time'
    },
    namePlaceholder: {
        zh: '请输入姓名',
        zh_tw: '請輸入姓名',
        en: 'Please enter your name'
    },
    remarkPlaceholder: {
        zh: '请输入备注',
        zh_tw: '請輸入備註',
        en: 'Please enter remarks'
    },
    substationTitle: {
        zh: '分站说明',
        zh_tw: '分站說明',
        en: 'Top-up instructions'
    },
    substationApplyForSuccess: {
        zh: '申请提交成功, 请保持电话畅通, 我们将尽快与您联系',
        zh_tw: '申請提交成功, 請保持電話暢通, 我們將盡快與您聯繫',
        en: 'Application submitted successfully, please keep the phone open, we will contact you as soon as possible'
    },
    payMethodAlipay: {
        zh: '支付宝',
        zh_tw: '支付寶',
        en: 'Alipay'
    },
    payMethodBalance: {
        zh: '账户余额',
        zh_tw: '賬戶餘額',
        en: 'Balance'
    },
    resetTitle: {
        zh: '重置',
        zh_tw: '重置',
        en: 'Reset'
    },
    share: {
        zh: '分享',
        zh_tw: '分享',
        en: 'Share'
    },
    free: {
        zh: '免费专区',
        zh_tw: '免费专区',
        en: 'FREE'
    },
    vip: {
        zh: 'VIP会员',
        zh_tw: 'VIP會員',
        en: 'VIP'
    },
    permanent: {
        zh: '永久会员',
        zh_tw: '永久會員',
        en: 'PERMANENT'
    },
    vipExclusive: {
        zh: 'VIP限定',
        zh_tw: 'VIP限定',
        en: 'VIP'
    },
    permanentExclusive: {
        zh: '限定游戏',
        zh_tw: '限定遊戲',
        en: 'PERMANENT'
    },
    freeExclusive: {
        zh: '免费',
        zh_tw: '免费',
        en: 'FREE'
    },
    paidExclusive: {
        zh: '付费',
        zh_tw: '付費',
        en: 'paid'
    },
    published: {
        zh: '发布于',
        zh_tw: '发布于',
        en: 'Published'
    },
    close: {
        zh: '关闭',
        zh_tw: '關閉',
        en: 'Close'
    },
    refresh: {
        zh: '刷新',
        zh_tw: '刷新',
        en: 'Refresh'
    },
    onlineConsultant: {
        zh: '在线咨询',
        zh_tw: '在線諮詢',
        en: 'Server'
    },
    gold: {
        zh: '金币',
        zh_tw: '金幣',
        en: 'gold'
    },
    popularGroupGames: {
        zh: '热门游戏',
        zh_tw: '熱門遊戲',
        en: 'Popular game'
    },
    latestGroupGames: {
        zh: '最近更新',
        zh_tw: '最近更新',
        en: 'Last updated'
    },
    freeGroupGames: {
        zh: '免费游戏',
        zh_tw: '免費遊戲',
        en: 'Free game'
    },
    groupUpdateExplain: {
        zh: '我们每天都在更新游戏，持续更新中',
        zh_tw: '我們每天都在更新遊戲，持續更新中',
        en: 'We are constantly updating the game every day'
    },
    groupPopularExplain: {
        zh: '最受欢迎游戏，不要错过',
        zh_tw: '最受歡迎遊戲，不要錯過',
        en: 'The most popular game, don\'t miss'
    },
    categories: {
        zh: '游戏分类',
        zh_tw: '遊戲分類',
        en: 'Game categories'
    },
    filterTitlePrice: {
        zh: '价格',
        zh_tw: '價格',
        en: 'Price'
    },
    filterTitleSort: {
        zh: '排序',
        zh_tw: '排序',
        en: 'Sort'
    },
    filterSortDiskSpace: {
        zh: '空间',
        zh_tw: '空間',
        en: 'Space'
    },
    filterSortRandom: {
        zh: '随机',
        zh_tw: '隨機',
        en: 'Random'
    },
    gameVersion: {
        zh: '游戏版本',
        zh_tw: '遊戲版本',
        en: 'game version'
    },
    gameTags: {
        zh: '游戏标签',
        zh_tw: '遊戲標籤',
        en: 'game tags'
    },
    membershipRestrictionTips: {
        zh: '本商品仅限永久VIP用户购买！',
        zh_tw: '本商品僅限永久VIP用戶購買！',
        en: 'This product is for permanent VIP users only'
    },
    membershipRestriction: {
        zh: '永久VIP',
        zh_tw: '永久VIP',
        en: 'PERMANENT VIP'
    },
    blowUpQrCode: {
        zh: '点我放大二维码',
        zh_tw: '點我放大二維碼',
        en: 'blow up'
    },
    docExt1: {
        zh: '资源信息',
        zh_tw: '資源信息',
        en: 'Resource information'
    },
    docExt2: {
        zh: '普通用户价格',
        zh_tw: '普通用戶價格',
        en: 'Common user price'
    },
    docExt3: {
        zh: 'VIP会员价格',
        zh_tw: 'VIP會員價格',
        en: 'VIP Member Price'
    },
    docExt4: {
        zh: '永久会员价格',
        zh_tw: '永久會員價格',
        en: 'Permanent membership price'
    },
    docExt5: {
        zh: '免费',
        zh_tw: '免費',
        en: 'free'
    },
    docExt6: {
        zh: '推荐',
        zh_tw: '推薦',
        en: 'Recommended'
    },
    docExt7: {
        zh: '其他信息',
        zh_tw: '其他信息',
        en: 'Other information'
    },
    docExt8: {
        zh: '有效期',
        zh_tw: '有效期',
        en: 'validity'
    },
    docExt9: {
        zh: '购买后永久有效',
        zh_tw: '購買後永久有效',
        en: 'Valid forever after purchase'
    },
    docExt10: {
        zh: '下载遇到问题？可联系客服或留言反馈',
        zh_tw: '下載遇到問題？可聯繫客服或留言反饋',
        en: 'Having trouble downloading? You can contact customer service or leave feedback'
    },
    docExt11: {
        zh: '点击下载Windows APP',
        zh_tw: '點擊下載Windows APP',
        en: 'Download windows app'
    },
    contactTitle: {
        zh: '联系我们',
        zh_tw: '聯繫我們',
        en: 'Contact Us'
    },
    contactButton1: {
        zh: '联系客服',
        zh_tw: '聯繫客服',
        en: 'Contact customer service'
    },
    contactButton2: {
        zh: '网盘不限速下载会员',
        zh_tw: '網盤不限速下載會員',
        en: 'Web disk does not limit download members'
    },
    contactButton3: {
        zh: '专属会员QQ群',
        zh_tw: '專屬會員QQ羣',
        en: 'Exclusive membership QQ group'
    },
    contactButton4: {
        zh: '最近更新记录',
        zh_tw: '最近更新記錄',
        en: 'Last updated record'
    },
    vipSlogan: {
        zh: '成为VIP',
        zh_tw: '成爲VIP',
        en: 'VIP'
    },
    toolServer: {
        zh: '客服',
        zh_tw: '客服',
        en: 'Server'
    },
    toolQQGroup: {
        zh: 'Q群',
        zh_tw: 'Q羣',
        en: 'QQ'
    },
    toolBuildWebsite: {
        zh: '建站',
        zh_tw: '建站',
        en: 'Build Website'
    },
    toolFullscreenExit: {
        zh: '退出',
        zh_tw: '退出',
        en: 'fullscreen exit'
    },
    toolFullscreen: {
        zh: '全屏',
        zh_tw: '全屏',
        en: 'fullscreen'
    },
    svipLimitBrief: {
        zh: '终身畅玩网站所有游戏',
        zh_tw: '終身暢玩網站所有遊戲',
        en: 'Play all games for a lifetime'
    },
    svipMonthLimitBrief: {
        zh: '一个月内畅玩所有游戏',
        zh_tw: '一個月內暢玩所有遊戲',
        en: 'Play all games for a month'
    },
    svipYearLimitBrief: {
        zh: '一年内畅玩所有游戏',
        zh_tw: '一年內暢玩所有遊戲',
        en: 'Play all games for a year'
    },
    privilegeTitle1: {
        zh: '会员尊享特权',
        zh_tw: '會員尊享特權',
        en: 'Members enjoy special privileges'
    },
    privilegeTitle2: {
        zh: '终身免费更新',
        zh_tw: '終身免費更新',
        en: 'Lifetime free renewal'
    },
    privilegeTitle3: {
        zh: '售后服务无忧',
        zh_tw: '售後服務無憂',
        en: 'Worry free after sale service'
    },
    privilegeTitle4: {
        zh: '专属Q群 强大赠品',
        zh_tw: '專屬Q羣 強大贈品',
        en: 'Exclusive Q Group powerful giveaway'
    },
    privilegeContent1: {
        zh: '畅玩所有游戏下载',
        zh_tw: '暢玩所有遊戲下載',
        en: 'Play all game downloads'
    },
    privilegeContent2: {
        zh: '所有游戏均享终身免费更新',
        zh_tw: '所有遊戲均享終身免費更新',
        en: 'Free updates for life for all games'
    },
    privilegeContent3: {
        zh: '永久售后技术服务支持',
        zh_tw: '永久售後技術服務支持',
        en: 'Permanent after-sales technical service support'
    },
    privilegeContent4: {
        zh: '千人专属Q群 送强大赠品',
        zh_tw: '專屬Q羣 千人專屬Q羣 送強大贈品',
        en: 'Thousands of exclusive Q group to send powerful gifts'
    },
    vipFooterExplain: {
        zh: '1.本文部分内容转载自其它媒体，但并不代表本站赞同其观点和对其真实性负责。 2.若您需要商业运营或用于其他商业活动，请您购买正版授权并合法使用。 3.如果本站有侵犯、不妥之处的资源，请发送相关证明至网站在线客服。将会第一时间解决！ 4.本站所有内容均由互联网收集整理、网友上传，仅供大家参考、学习，不存在任何商业目的与商业用途。 5.本站提供的所有资源仅供参考学习使用，版权归原著所有，禁止下载本站资源参与商业和非法行为，请在24小时之内自行删除!',
        zh_tw: '1.本文部分內容轉載自其它媒體，但並不代表本站贊同其觀點和對其真實性負責。 2.若您需要商業運營或用於其他商業活動，請您購買正版授權併合法使用。 3.如果本站有侵犯、不妥之處的資源，請發送相關證明至網站在線客服。將會第一時間解決！ 4.本站所有內容均由互聯網收集整理、網友上傳，僅供大家參考、學習，不存在任何商業目的與商業用途。 5.本站提供的所有資源僅供參考學習使用，版權歸原著所有，禁止下載本站資源參與商業和非法行爲，請在24小時之內自行刪除!',
        en: '1. Some of the content of this article is reproduced from other media, but it does not mean that this site agrees with its views and is responsible for its authenticity. 2. If you need to operate or use for other commercial activities, please purchase a legitimate license and use it legally. 3. If there are any infringing or improper resources on this site, please send relevant proof to email (804722013@qq.com). Will be the first time to solve! 4. All the content of this site is collected by the Internet, uploaded by netizens, only for your reference and learning, there is no commercial purpose and commercial use. 5. All resources provided by this site are for reference only, copyright belongs to the original, prohibited to download this site resources to participate in commercial and illegal behavior, please delete within 24 hours!'
    },
    friendlyLinkExplain: {
        zh: '友情链接',
        zh_tw: '友情鏈接',
        en: 'friendly link'
    },
    subscriptionFont1: {
        zh: 'CMDW游戏终身SVIP会员',
        zh_tw: 'CMDW遊戲終身SVIP會員',
        en: 'Lifetime SVIP membership of CMDW Games'
    },
    subscriptionFont2: {
        zh: '极速客服，在线指导',
        zh_tw: '極速客服，在線指導',
        en: 'Top speed customer service, online guidance'
    },
    subscriptionFont3: {
        zh: '一次购买，永久更新',
        zh_tw: '一次購買，永久更新',
        en: 'Once purchased, forever updated'
    },
    subscriptionFont4: {
        zh: '专属Q群，售后无忧',
        zh_tw: '專屬Q羣，售後無憂',
        en: 'Exclusive Q group, no worries after sale'
    },
    subscriptionFont5: {
        zh: '极速下载，不再等待',
        zh_tw: '極速下載，不再等待',
        en: 'Fast download, no more waiting'
    },
    downloadLimit: {
        zh: `${ placeholder }每日最多下载${ placeholder1 }个游戏, 是否继续购买?`,
        zh_tw: `${ placeholder }每日最多下載${ placeholder1 }個遊戲, 是否繼續購買?`,
        en: `${ placeholder }Maximum daily downloads ${ placeholder1 } games, Do you want to continue buying?`
    },
    downloadLimitTitle: {
        zh: '下载次数超限',
        zh_tw: '下載次數超限',
        en: 'The number of downloads exceeded the limit'
    },
    wechatNum: {
        zh: '微信号',
        zh_tw: '微信號',
        en: 'wechat'
    },
    webmasterContact: {
        zh: '站长联系方式',
        zh_tw: '站長聯繫方式',
        en: 'Webmaster contact'
    },
    noticeTitle: {
        zh: '公告',
        zh_tw: '公告',
        en: 'notice'
    },
    gameDeclaration: {
        zh: '<br/><b>声明</b>：<br/>1.本站部分内容转载自其它媒体，但并不代表本站赞同其观点和对其真实性负责。<br/>2.若您需要商业运营或用于其他商业活动，请您购买正版授权并合法使用。<br/>3.如果本站有侵犯、不妥之处的资源，请联系我们。将会第一时间解决！<br/>4.本站部分内容均由互联网收集整理，仅供大家参考、学习，不存在任何商业目的与商业用途。<br/>5.本站提供的所有资源仅供参考学习使用，版权归原著所有，禁止下载本站资源参与任何商业和非法行为，请于24小时之内删除!<br/>',
        zh_tw: '<br/><b>聲明</b>：<br />1.本站部分內容轉載自其它媒體，但並不代表本站贊同其觀點和對其真實性負責。<br />2.若您需要商業運營或用於其他商業活動，請您購買正版授權併合法使用。<br />3.如果本站有侵犯、不妥之處的資源，請聯繫我們。將會第一時間解決！<br />4.本站部分內容均由互聯網收集整理，僅供大家參考、學習，不存在任何商業目的與商業用途。<br />5.本站提供的所有資源僅供參考學習使用，版權歸原著所有，禁止下載本站資源參與任何商業和非法行爲，請於24小時之內刪除!<br />',
        en: '<br/><b>Declaration</b>: <br/> 1. Some content of this site is reproduced from other media, but it does not mean that this site agrees with its views and is responsible for its authenticity. <br/> 2. If you need to operate or use for other commercial activities, please purchase a legitimate license and use it legally. <br/> 3. If the site has infringed, inappropriate resources, please contact us. Will be the first time to solve! <br/> 4. Part of the content of this site is collected by the Internet, for your reference and learning only, there is no commercial purpose and commercial use. <br/> 5. All resources provided by this site are for reference only, copyright belongs to the original, prohibited to download this site resources to participate in any commercial and illegal behavior, please delete within 24 hours! <br/>'
    },
    shareAwardTips: {
        zh: '分享商品可得佣金',
        zh_tw: '分享商品可得佣金',
        en: 'You get a commission for sharing goods'
    },
    selectPayTypeTitle: {
        zh: '选择支付类型',
        zh_tw: '選擇支付類型',
        en: 'Select a payment type'
    },
    gameBoxTitle: {
        zh: 'CMDW游戏盒子',
        zh_tw: 'CMDW遊戲盒子',
        en: 'CMDW game box'
    },
    activationCodePurchase: {
        zh: '游戏盒子激活码',
        zh_tw: '游戏盒子激活码',
        en: 'Software Activation'
    },
    goPay: {
        zh: '点击购买',
        zh_tw: '點擊購買',
        en: 'Go Pay'
    },
    appCode: {
        zh: '软件激活码',
        zh_tw: '軟件激活碼',
        en: 'Software Activation code'
    },
    lanzouCloud: {
        zh: '蓝奏云',
        zh_tw: '藍奏雲',
        en: 'Lanzou'
    },
    baiduCode: {
        zh: '百度云',
        zh_tw: '百度雲',
        en: 'Baidu Cloud'
    }
}

type ResourceType = Record<string, Record<string, string>>

const resource: ResourceType = {}

for (const key in localization) {
    const item = localization[ key ]
    for (const i in item) {
        if (isEmpty(resource[ i ])) {
            resource[ i ] = {}
        }

        resource[ i ][ key ] = item[ i ]
    }
}

export default resource