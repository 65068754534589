import { type AxiosResponse } from 'axios'
import { proxy, route, service } from '#utils/axios'
import { type LangItem } from '#repositories/types/foundation'
import { type ProductLabelTypes } from '#constants/appoint'
import { type ListItem } from '#repositories/apis/documents'

export interface Children {
    id: number
    type: string
    publish: number
    image: string
    strVal: string
    digitalVal: number
    price: number
    target: string
    sort: number
    isDel: number
    createdAt: number
    updatedAt: number
    indexes: number[]
    title: LangItem
    summary: LangItem
    label: string
    pid: number
    children: any[]
}

export const FreeGameCategoryId = 75

export interface DictionaryItem {
    id: number
    type: string
    publish: number
    image: string
    strVal: string
    digitalVal: number
    price: number
    target: string
    isDel: number
    sort: number
    createdAt: number
    updatedAt: number
    indexes: number[]
    title: LangItem
    summary: LangItem
    label: string
    pid: number
    children: Children[]
}

export type Dictionaries = {
    [key in ProductLabelTypes]: DictionaryItem;
}

export interface LabelItemList {
    id: number
    title: LangItem
    list: ListItem[]
    loading: boolean
}

export type LabelMaps = Record<number, LabelItemList>

export const dictionaries = async <T>(groups: string[]): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/dictionaries'),
        method: 'post',
        data: {
            groups
        }
    })
}