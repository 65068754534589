import { type ReactNode } from 'react'
import { type Rule } from 'rc-field-form/lib/interface'
import { execFunc, isEmpty } from '#utils/functions'
import { isPhone } from '#utils/patterns'
import { type LT } from '#utils/localization'
import { activationCode, type ActivationCodeItem } from '#repositories/apis/wallet'
import { MessageType, MMessage } from '#components/hint'
import { type OrderType } from '#constants/appoint'

export interface MenuItem {
    title: string
    path: string
    checkActive?: (path: string, routePath: string) => boolean
    icon?: ReactNode
}

export interface MenuGroup {
    title: string
    children: MenuItem[]
}

export type MenuGroups = MenuGroup[]

export interface OrdersProps {
    orderTypes?: OrderType[]
    hideEmpty?: boolean
    match: {
        params: {
            uniqueId?: string
            page?: number
            limit?: number
        }
    }
}

export interface FavoritesProps {
    match: {
        params: {
            page?: number
            limit?: number
        }
    }
}

export const amounts = [
    5, 10, 20, 50, 100
]

export interface WalletProps {
    match: {
        params: {
            page?: number
            limit?: number
            id?: number
        }
    }
}

export enum withdrawState {
    // 审核状态 0 待处理 1 已完成 2 拒绝
    waiting,
    done,
    refused
}

export const wrapperCol = { offset: 3, span: 18 }

export const mobileRegRule = (message: string): Rule[] => [
    { required: true, message },
    () => ({
        validator: async(_, value) => {
            if (isEmpty(value)) {
                return
            }

            const mobile = value as string
            if (!isPhone(mobile)) {
                return await Promise.reject(new Error(message))
            }
        }
    })
]

export enum ActivationCodeButtonType {
    disabled,
    fetch,
    active
}

export interface ActivationCodeButton {
    disabled: boolean
    title: string
    type: ActivationCodeButtonType
}

export enum RechargeType {
    // 在线支付
    onlinePayment,
    // 卡密充值
    card,
    // 分享奖励
    shareAward,
    // 后台充值
    backend
}

export interface ActivationCodeRowProps {
    code: string
    setData?: (data: any) => void
    setButtonState?: (data: any) => void
    setLoading?: (data: any) => void
    langDict: LT
    disabledIsUsedError?: boolean
}

export const activationCodeRow = (
    {
        code,
        setData,
        setButtonState,
        setLoading,
        langDict,
        disabledIsUsedError
    }: ActivationCodeRowProps
): void => {
    // 获取卡密
    void activationCode(code).then(
        res => {
            const data = res.data as ActivationCodeItem
            execFunc(setData, data)

            if (data.userid !== 0 && disabledIsUsedError !== true) {
                execFunc(setButtonState, {
                    disabled: true,
                    title: langDict.activation,
                    type: ActivationCodeButtonType.active
                })

                MMessage({
                    message: langDict.activationCardIsUseStateErr,
                    type: MessageType.error
                })
                return
            }

            execFunc(setButtonState, {
                disabled: false,
                title: langDict.activation,
                type: ActivationCodeButtonType.active
            })
        }
    ).catch(
        () => {
            MMessage({
                message: langDict.activationCardNumErr,
                type: MessageType.error
            })
            execFunc(setButtonState, {
                disabled: true,
                title: langDict.fetchActivation,
                type: ActivationCodeButtonType.disabled
            })
        }
    ).finally(
        () => {
            execFunc(setLoading, false)
        }
    )
}