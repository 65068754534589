import React, { useEffect } from 'react'
import { Configs, Language, SitePopup } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { type CSSStyle } from '#repositories/types/foundation'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { variables } from '#constants/appoint'
import { HolidayScene, HolidayTypes } from '#utils/festival'
import DragonBoat from '#assets/images/festival/dragon-boat.jpg'
import MidAutumn from '#assets/images/festival/mid-autumn.jpg'
import NationalDay from '#assets/images/festival/national-day.jpg'
import TombSweepingDay from '#assets/images/festival/tomb-sweeping-day.jpg'
import Spring from '#assets/images/festival/spring.jpg'
import LabourDay from '#assets/images/festival/labour-day.jpg'
import { isEmpty } from '#utils/functions'

// import { BGParticles } from '#components/background'
// <BGParticles />

const sceneBackground: Record<any, string> = {}
sceneBackground[ HolidayTypes.h1 ] = Spring // 春节
sceneBackground[ HolidayTypes.h2 ] = TombSweepingDay // 清明
sceneBackground[ HolidayTypes.h3 ] = DragonBoat // 端午
sceneBackground[ HolidayTypes.h4 ] = MidAutumn // 中秋
sceneBackground[ HolidayTypes.h5 ] = Spring // 除夕
sceneBackground[ HolidayTypes.h6 ] = LabourDay // 劳动节
sceneBackground[ HolidayTypes.h7 ] = NationalDay // 国庆

const Main: React.FC = () => {
    const sitePopup = new SitePopup()
    const configs = new Configs()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ style, setStyle ] = useFetchState<any>(null)
    const [ titleStyle, setTitleStyle ] = useFetchState<CSSStyle>(null)
    const [ contentStyle, setContentStyle ] = useFetchState<CSSStyle>(null)
    const [ className, setClassName ] = useFetchState('')

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'noticeTitle'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            sitePopup.set(configs.state.sitePopup.zhSimplified, variables.timestamp)
        },
        []
    )

    useEffect(
        () => {
            if (!isEmpty(configs.state.sitePopupBackgroundImage)) {
                setStyle({
                    backgroundImage: `url('${ configs.state.sitePopupBackgroundImage }')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                })
            } else {
                // 获取日期
                const data = HolidayScene(new Date())
                if (data !== null) {
                    if (data.title === HolidayTypes.h1 || data.title === HolidayTypes.h5 || data.title === HolidayTypes.h7) {
                        setClassName('thin')
                    }
                    setStyle({
                        backgroundImage: `url('${ sceneBackground[ data.title ] }')`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    })
                }
            }

            const titleStyle: CSSStyle = {}
            if (!isEmpty(configs.state.sitePopupTitleColor)) {
                titleStyle.color = configs.state.sitePopupTitleColor.substring(0, 7)
            }

            if (!isEmpty(configs.state.sitePopupTitleSize)) {
                titleStyle.fontSize = configs.state.sitePopupTitleSize
            }
            setTitleStyle(titleStyle)

            const contentStyle: CSSStyle = {}
            if (!isEmpty(configs.state.sitePopupContentColor)) {
                contentStyle.color = configs.state.sitePopupContentColor.substring(0, 7)
            }

            if (!isEmpty(configs.state.sitePopupContentSize)) {
                contentStyle.fontSize = configs.state.sitePopupContentSize
            }
            setContentStyle(contentStyle)
        },
        []
    )

    return <div className={ `site-popup-box${ className === '' ? '' : ` ${ className }` }` }>
        { style !== null ? <div className="wh100 abs-center bg" style={ style } /> : '' }
        <p className="site-popup-title" style={ titleStyle ?? {} }>{ langDict.noticeTitle }</p>
        <div className="site-popup-content" style={ contentStyle ?? {} }>
            <ul
                dangerouslySetInnerHTML={
                    {
                        __html: pickLangKey(
                            lang.state,
                            configs.state.sitePopup
                        ).split('\n').filter(
                            item => item.trim() !== ''
                        ).map(
                            item => `<li>${ item }</li>`
                        ).join('')
                    }
                }
            />
        </div>
    </div>
}

export default Main