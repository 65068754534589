import React, { useEffect, useRef } from 'react'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { OrderType, PayType, UserLevelType } from '#constants/appoint'
import { MessageType, MMessage, Modals, type ModalsProps } from '#components/hint'
import Icon from '#components/icon'
import PayTypePopupTmp from '#components/pay-type-popup'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import {
    Configs,
    Language,
    LevelPriceDifference,
    Profile,
    SignInPopupVisible,
    WalletStatistics
} from '#repositories/models/recoil-state'
import { makeOrder, type MakerOrderResp } from '#repositories/apis/orders'
import useFetchState from '#repositories/models'
import { levelPriceDifference as levelPriceDifferenceApi } from '#repositories/apis/user'
import { type LevelPriceDifferenceResp, LevelPriceDifferenceType } from '#repositories/types/response'
import { ReactComponent as Vip2 } from '#assets/svg/vip2.svg'
import { ReactComponent as Checked } from '#assets/svg/checked.svg'
import { isEmpty, throttle } from '#utils/functions'
import { statistics as statisticsApi, type StatisticsResp } from '#repositories/apis/wallet'
import { useHistory } from 'react-router-dom'
import Location from '#components/location'

interface PropsType {
    item: DictionaryItem
    readonly?: boolean
    active?: boolean
    hideContent?: boolean
    orderView?: boolean
    topIcon?: boolean
}

const Main: React.FC<PropsType> = (
    {
        item,
        readonly,
        hideContent,
        active,
        orderView,
        topIcon
    }
) => {
    const history = useHistory()
    const configs = new Configs()
    const externalLinkState = configs.shared()?.externalLinkState ?? false
    const externalLinkUpgrade = configs.shared()?.externalLinkUpgrade ?? ''
    const orderType = useRef(OrderType.userLevelUpgrade)
    const profile = new Profile()
    const signInVisible = new SignInPopupVisible()
    const lang = new Language()
    const levelPriceDifference = new LevelPriceDifference()
    // 钱包统计
    const walletStatistics = new WalletStatistics()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 差价
    const [ priceDifference, setPriceDifference ] = useFetchState(0)
    // 购买按钮文字
    const [ buttonTitle, setButtonTitle ] = useFetchState('')
    // 显示弹窗
    const [ visible, setVisible ] = useFetchState(false)
    const doMakeOrder = (payType: PayType): void => {
        void makeOrder({
            payType,
            type: orderType.current,
            uniqueId: item.id.toString()
        }).then(
            res => {
                const data = res.data as MakerOrderResp
                window.location.href = data.url
            }
        )
    }
    const popupModalProps: ModalsProps = {
        className: 'site-popup',
        visible,
        setVisible,
        width: 400,
        content: <PayTypePopupTmp
            balance={ walletStatistics.state === null ? 0 : walletStatistics.state.balance }
            price={ priceDifference > 0 ? priceDifference : item.price }
            completion={ doMakeOrder }
        />,
        maskClosable: true,
        centered: true,
        footer: null
    }
    const onUpgrade = (): void => {
        // 创建订单
        const makeOrderCall = (): void => {
            // 购买会员
            let type = OrderType.userLevelUpgrade
            if (profile.state.level === UserLevelType.permanent) {
                // 打赏赞助
                type = OrderType.siteGift
            } else if (priceDifference > 0) {
                // 补差价
                type = OrderType.userLevelUpgradePriceDifference
            }
            orderType.current = type

            if (walletStatistics.state !== null && walletStatistics.state.balance > 0) {
                // 显示选择支付
                setVisible(true)
                return
            }

            doMakeOrder(PayType.ali)
        }

        // 检测登录状态
        if (profile.state === null) {
            signInVisible.set(true, _ => {
                MMessage({
                    message: langDict.loginSuccessWidth,
                    type: MessageType.success,
                    duration: 1,
                    onClose: () => {
                        makeOrderCall()
                    }
                })
            })
            return
        }

        makeOrderCall()
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'giveAReward',
                        'gold',
                        'buyNow',
                        'upgradeNow',
                        'svipMonthLimitBrief',
                        'svipYearLimitBrief',
                        'svipLimitBrief'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            setButtonTitle(langDict.buyNow)

            // if (profile.state.level === UserLevelType.permanent) {
            //     // 永久会员打赏网站
            //     setButtonTitle(langDict.giveAReward)
            //     return
            // }

            if (levelPriceDifference.state === null || isEmpty(levelPriceDifference.state)) {
                return
            }

            if (levelPriceDifference.state.type === OrderType.siteGift) {
                // 永久会员打赏网站
                setButtonTitle(langDict.giveAReward)
                return
            }

            if (item.digitalVal === -1 && levelPriceDifference.state.price[ LevelPriceDifferenceType.permanent ] > 0) {
                // 永久会员差价
                setPriceDifference(levelPriceDifference.state.price[ LevelPriceDifferenceType.permanent ])
                setButtonTitle(langDict.upgradeNow)
                return
            }

            if (item.strVal === LevelPriceDifferenceType.year) {
                // 年卡会员差价
                setPriceDifference(levelPriceDifference.state.price[ LevelPriceDifferenceType.year ])
                setButtonTitle(langDict.upgradeNow)
            }
        },
        [ levelPriceDifference.state, langDict ]
    )

    useEffect(
        () => {
            if (profile.state === null) {
                return
            }

            if (profile.state.level === UserLevelType.free) {
                return
            }

            if (levelPriceDifference.state !== null) {
                return
            }

            throttle(
                (): void => {
                    void levelPriceDifferenceApi().then(
                        res => {
                            levelPriceDifference.set(res.data as LevelPriceDifferenceResp)
                        }
                    )
                },
                1000,
                'usernameComplete'
            )
        },
        [ profile ]
    )

    useEffect(
        () => {
            if (profile.state === null) {
                return
            }

            if (walletStatistics.state !== null) {
                return
            }

            throttle(
                (): void => {
                    void statisticsApi().then(
                        res => {
                            walletStatistics.set(res.data as StatisticsResp)
                        }
                    )
                },
                500,
                'walletStatistics'
            )

            return () => {
                walletStatistics.set(null)
            }
        },
        [ profile.state, history.location.pathname ]
    )

    return <>
        { visible ? <Modals { ...popupModalProps } /> : '' }
        <div className={ `upgrade-item transition-05s${ active !== undefined && active ? ' active' : '' }` }>
            { topIcon === true ? <Icon className="top-vip-icon" tap><Vip2 /></Icon> : '' }
            <div className="header">
                <p className="title">{ pickLangKey(lang.state, item.title) }</p>
                { orderView === undefined || orderView ? '' : <p className="price">{ item.price } { langDict.gold }</p> }
                <p className={ `price${ priceDifference > 0 ? ' diff' : '' }` }>
                    {
                        priceDifference > 0
                            ? <><span>{ priceDifference } <s>{item.price}</s></span>元</>
                            : <><span>{ item.price }</span>元</>
                    }
                </p>
                { item.digitalVal === -1 ? <p className="thirdly-color">{ langDict.svipLimitBrief }</p> : '' }
                { item.strVal === 'month' ? <p className="thirdly-color">{ langDict.svipMonthLimitBrief }</p> : '' }
                { item.strVal === 'year' ? <p className="thirdly-color">{ langDict.svipYearLimitBrief }</p> : '' }
                {
                    readonly !== undefined && readonly
                        ? ''
                        : <div
                            className="cursor-pointer transition-05s subscribe-button"
                            onClick={ () => { onUpgrade() } }
                        >{ buttonTitle }</div>
                }
                {
                    readonly !== undefined && readonly
                        ? ''
                        : (
                            externalLinkState && externalLinkUpgrade !== ''
                                ? <Location to={ externalLinkUpgrade } className="subscribe-button sp" target="_blank">平台购买</Location>
                                : ''
                        )
                }
            </div>
            {
                hideContent !== undefined && hideContent
                    ? ''
                    : <div className="content">{
                        pickLangKey(lang.state, item.summary).trim().split('\n').filter(
                            item => item !== ''
                        ).map(
                            (item, key) => <p key={ key } className="item">
                                <span>{ item }</span>
                                { item.trim() !== '' ? <Icon><Checked /></Icon> : '' }
                            </p>
                        )
                    }</div>
            }
        </div>
    </>
}

export default Main