import React, { useEffect, useRef } from 'react'
import { Divider, Timeline } from 'antd'
import { dateFormat4 } from '#constants/appoint'
import { latest, type ListItem } from '#repositories/apis/documents'
import useFetchState from '#repositories/models'
import { Language } from '#repositories/models/recoil-state'
import Icon from '#components/icon'
import Location from '#components/location'
import { ReactComponent as ChevronCompactDown } from '#assets/svg/chevron-compact-down.svg'
import { documentTitle } from '#utils/configs'
import localization, { type LT } from '#utils/localization'
import { productDetailRoutePath } from '#utils/router'
import { futureListGroupByDate, type FutureListTimelineItem } from '#pages/products/model'
import { timestampFormat } from '#utils/functions'

const Main: React.FC = () => {
    const lang = new Language()
    const pageRef = useRef(1)
    const [ loading, setLoading ] = useFetchState(true)
    const [ initLoading, setInitLoading ] = useFetchState(true)
    const [ hasMore, setHasMore ] = useFetchState(true)
    const [ data, setData ] = useFetchState<ListItem[]>([])
    const [ records, setRecords ] = useFetchState<Record<string, FutureListTimelineItem[]>>({})
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'checkMore1'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    const loadData = (): void => {
        setLoading(true)
        void latest(pageRef.current, 100).then(
            res => {
                const respList = res.data as ListItem[]
                const list = [ ...data, ...respList ]
                setData(list)
                setRecords(futureListGroupByDate(list))
                pageRef.current += 1
                setHasMore(respList.length >= 100)
            }
        ).finally(
            () => {
                setLoading(false)
                setInitLoading(false)
            }
        )

        window.dispatchEvent(new Event('resize'))
    }

    useEffect(
        () => {
            setInitLoading(true)
            loadData()
        },
        []
    )

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'headerMenuFuture'
                ]
            })

            documentTitle(langDict.headerMenuFuture)
        },
        [ lang.state ]
    )

    return <div className="future-container">
        <div className="future-list">
            {
                Object.keys(records).map(
                    (date, key) => <div key={ key } className="future-section">
                        <p className="title">{ date }</p>
                        <Timeline
                            mode={ 'left' }
                            items={
                                records[ date ].map(
                                    value => ({
                                        label: value.label,
                                        children: <Location to={ productDetailRoutePath(value.item.uniqueId) }>
                                            { timestampFormat(value.item.createdAt, dateFormat4) } { value.item.title }
                                        </Location>
                                    })
                                )
                            }
                        />
                        { key !== Object.keys(records).length - 1 ? <Divider plain className="split-line" /> : '' }
                    </div>
                )
            }
            {
                !initLoading && !loading && hasMore
                    ? <p className="load-more">
                        <Icon tap className="i-5x" onClick={ loadData }>
                            <ChevronCompactDown />
                            <em>{ langDict.checkMore1 }</em>
                        </Icon>
                    </p>
                    : ''
            }
        </div>
    </div>
}

export default Main