import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider } from 'antd'
import { AppExclusive, dateFormat3, pickColor, UserLevelType } from '#constants/appoint'
import { Routes, withCategoryIdProductsRoutePath } from '#utils/router'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { copyToClipboard, isEmpty, timestampFormat } from '#utils/functions'
import SHARE from '#utils/share'
import { documentTitle } from '#utils/configs'
import { type Item, row, type RowResp } from '#repositories/apis/documents'
import useFetchState from '#repositories/models'
import { Configs, Dictionary, Language, Profile } from '#repositories/models/recoil-state'
import { type LabelMaps } from '#repositories/apis/dictionaries'
import Loading from '#components/loading'
import Location from '#components/location'
import Image from '#components/image'
import Icon from '#components/icon'
import { BehaviorComponent, ColumnType } from '#components/behavior'
import Popular from '#components/document/popular'
import VideoCodeBox from '#components/video-code-box'
import { MessageType, MMessage } from '#components/hint'
import { ReactComponent as Share } from '#assets/svg/share.svg'
import { ReactComponent as CloudDownload } from '#assets/svg/cloud-download.svg'
import { ReactComponent as MessageChatBot } from '#assets/svg/message-chatbot.svg'
import { type CategoryMaps, ItemToListItem, makeShareUrl, type RowProps } from './model'
import { ProductRowBuyButton } from '#components/document/item'

const Main: React.FC<RowProps> = props => {
    const configs = new Configs()
    const history = useHistory()
    const [ loading, setLoading ] = useFetchState(true)
    const profile = new Profile()
    // 字典配置
    const dictionary = new Dictionary()
    const dictionaryState = dictionary.shared()
    // 分享地址
    const [ share, setShare ] = useFetchState(makeShareUrl(props.match.params.uniqueId))
    // 语言
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ data, setData ] = useFetchState<Item | null>(null)
    const [ userLevel, setUserLevel ] = useFetchState<UserLevelType>(UserLevelType.free)
    const [ orderCnt, setOrderCnt ] = useFetchState(0)
    // 游戏分类
    const [ categoryMaps, setCategoryMaps ] = useFetchState<CategoryMaps>([])
    const [ categoryId, setCategoryId ] = useFetchState(0)
    // 标签 channel
    const [ labelListMaps, setLabelListMaps ] = useFetchState<LabelMaps>({})
    const doShare = (): void => {
        copyToClipboard(share)
        MMessage({
            message: langDict.shareCopySuccess,
            type: MessageType.success
        })
    }

    const realLabels = (labelIds: number[]): number[] => {
        return labelIds.filter(
            item => !isEmpty(labelListMaps[ item ])
        )
    }
    const dictRender = (): void => {
        if (dictionaryState === null) {
            return
        }

        if (dictionaryState.gameClass !== undefined && dictionaryState.gameClass !== null && dictionaryState.gameClass?.children.length >= 0) {
            const categoryMaps: CategoryMaps = {}
            dictionaryState.gameClass.children.forEach(
                item => {
                    if (item.digitalVal === 1) {
                        categoryMaps[ item.id ] = item
                    }
                }
            )

            setCategoryMaps(categoryMaps)
        }

        if (dictionaryState.gameClass !== undefined && dictionaryState.gameClass !== null && dictionaryState.gameClass?.children.length >= 0) {
            const labelListMaps: LabelMaps = {}
            dictionaryState.gameClass.children.forEach(
                item => {
                    labelListMaps[ item.id ] = {
                        id: item.id,
                        title: item.title,
                        list: [],
                        loading: true
                    }
                }
            )
            setLabelListMaps(labelListMaps)
        }
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'headerMenuHome',
                        'publishTime',
                        'categories',
                        'gold',
                        'guestCheckoutWithBuy',
                        'filterTitlePrice',
                        'promoExpire',
                        'like',
                        'share',
                        'buy',
                        'downloadGame',
                        'permanentExclusive',
                        'vipExclusive',
                        'diskSpace',
                        'shareCopySuccess',
                        'preview',
                        'screenshot',
                        'videoBrief',
                        'gameContent',
                        'shareAwardTips',
                        'loginSuccessWidth',
                        'productRowLang',
                        'productRowLangContent',
                        'favorite',
                        'docExt1',
                        'docExt2',
                        'docExt3',
                        'docExt4',
                        'docExt5',
                        'docExt6',
                        'docExt7',
                        'docExt8',
                        'docExt9',
                        'docExt10',
                        'docExt11',
                        'contactTitle',
                        'contactButton1',
                        'contactButton2',
                        'contactButton3',
                        'contactButton4',
                        'gameVersion',
                        'gameTags',
                        'gameDeclaration',
                        'popularGroupGames'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            void row(
                props.match.params.uniqueId,
                ''
            ).then(
                res => {
                    const data = res.data as RowResp
                    data.row.labelIds = data.row.labelIds.filter(
                        item => item !== categoryId
                    )

                    const regex = /(<p><br><\/p>){2,}/g
                    if (data.row.content !== null) {
                        data.row.content = data.row.content.replace(regex, '')
                    }

                    setData(data.row)
                    setOrderCnt(data.order)
                    setUserLevel(data.userLevel)
                    setShare(makeShareUrl(props.match.params.uniqueId, data.share))
                    documentTitle(data.row.title)
                }
            ).catch(
                () => {
                    history.push(Routes.products)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        },
        [ props.match.params.uniqueId, profile.state ]
    )

    useEffect(
        () => {
            if (data !== null) {
                const categoryIds = Object.keys(categoryMaps)
                data.labelIds.forEach(
                    item => {
                        if (categoryIds.some(v => v === item.toString())) {
                            setCategoryId(item)
                        }
                    }
                )
            }
        },
        [ data, categoryMaps ]
    )

    useEffect(
        () => {
            dictRender()
        },
        [ dictionaryState ]
    )

    useEffect(
        () => {
            const val = props.match.params.secret ?? ''
            if (val !== '') {
                new SHARE().setSecret(val)
            }
        },
        [ props.match.params.secret ]
    )

    return <div className="document-row-container">{
        loading || data === null
            ? <Loading />
            : <>
                <div className="header">
                    <ul className="flex nav">
                        <li><Location to={ Routes.home }>{ langDict.headerMenuHome }</Location></li>
                        {
                            categoryId > 0
                                ? <>
                                    <li> / </li>
                                    <li>
                                        <Location
                                            to={ withCategoryIdProductsRoutePath(categoryId) }
                                        >{ pickLangKey(lang.state, categoryMaps[ categoryId ].title) }</Location>
                                    </li>
                                </>
                                : ''
                        }
                        {
                            share !== ''
                                ? <li className="cursor-pointer share">
                                    <div
                                        className="flex cursor-pointer"
                                        onClick={
                                            () => {
                                                doShare()
                                            }
                                        }
                                    >
                                        <span>{ langDict.share }</span>
                                        <Icon><Share /></Icon>
                                    </div>
                                </li>
                                : ''
                        }
                        <li className="cursor-pointer favorite">
                            <BehaviorComponent
                                text={ <span>{ langDict.favorite }</span> }
                                type={ ColumnType.favorites }
                                item={ ItemToListItem(data) }
                                callback={
                                    val => {
                                        setData({
                                            ...data,
                                            extension: { ...data.extension, favorites: val }
                                        })
                                    }
                                }
                            />
                        </li>
                        <li className="cursor-pointer like">
                            <BehaviorComponent
                                text={ <span>{ langDict.like }</span> }
                                type={ ColumnType.likes }
                                item={ ItemToListItem(data) }
                                callback={
                                    val => {
                                        setData({
                                            ...data,
                                            extension: { ...data.extension, likes: val }
                                        })
                                    }
                                }
                            />
                        </li>
                    </ul>
                    <div className="title">{ data.title }</div>
                    <Divider plain className="split-line" />
                    <div className="w100 doc-header">
                        <div className="info">
                            <div className="featured-image">
                                <Image
                                    preview={ true }
                                    product={ true }
                                    className="logo"
                                    src={ data.featuredImage }
                                    alt=""
                                />
                            </div>
                            <div className="goods">
                                {
                                    realLabels(data.labelIds).length <= 0
                                        ? ''
                                        : <div className="goods-ctl labels">
                                            <span className="title">{ langDict.categories }: </span>
                                            <ul className="flex">{
                                                realLabels(data.labelIds).map(
                                                    (item, key) => isEmpty(labelListMaps[ item ])
                                                        ? '-'
                                                        : <li key={ key } style={ { background: pickColor(key) } }>{
                                                            pickLangKey(lang.state, labelListMaps[ item ].title ?? {})
                                                        }</li>
                                                )
                                            }</ul>
                                        </div>
                                }
                                {
                                    data.extension.gameVersion !== ''
                                        ? <div className="goods-ctl disk-space">
                                            <span className="title">{ langDict.gameVersion }: </span>
                                            <span>{ data.extension.gameVersion }</span>
                                        </div>
                                        : ''
                                }
                                {
                                    data.tags !== ''
                                        ? <div className="goods-ctl disk-space">
                                            <span className="title">{ langDict.gameTags }: </span>
                                            <span>{ data.tags }</span>
                                        </div>
                                        : ''
                                }
                                {
                                    data.extension.diskSpace.trim() !== ''
                                        ? <div className="goods-ctl disk-space">
                                            <span className="title">{ langDict.diskSpace }: </span>
                                            <span>{ data.extension.diskSpace === '' ? '-' : `${ data.extension.diskSpace }` }</span>
                                        </div>
                                        : ''
                                }
                                <div className="goods-ctl labels">
                                    <span className="title">{ langDict.productRowLang }: </span>
                                    <ul className="flex">{
                                        data.extension.gameLang.split(' ').filter(
                                            item => item.trim() !== ''
                                        ).map(
                                            (item, key) => <li key={ key } style={ { background: pickColor(key + 1) } }>{ item }</li>
                                        )
                                    }</ul>
                                </div>
                                <div className="goods-ctl labels">
                                    <span className="title">{ langDict.publishTime }: </span>
                                    <span className="content">{ timestampFormat(data.createdAt, dateFormat3) }</span>
                                </div>
                            </div>
                            <span className="line" />
                        </div>
                        <div className="advantage-ext">
                            <p className="widget-title"><Icon><CloudDownload /></Icon>{ langDict.docExt1 }</p>
                            {
                                data.appExclusive === AppExclusive.windowsApp
                                    ? <>
                                        <ul className="pricing-options">
                                            <li>该游戏为CMDW Windows App 独享</li>
                                            <li className="sp">下载软件即可享受</li>
                                        </ul>
                                        <div
                                            className="cursor-pointer font-center buy-button"
                                            onClick={
                                                () => {
                                                    window.open(configs.state.desktopAppDownloadUrlLanzou)
                                                }
                                            }
                                        >{ langDict.docExt11 }</div>
                                    </>
                                    : <>
                                        <ul className="pricing-options">
                                            <li>{ langDict.docExt2 }：<span>{ data.price > 0 ? `${ data.price } ${ langDict.gold }` : langDict.docExt5 }</span></li>
                                            <li>{ langDict.docExt3 }：<span>{ langDict.docExt5 }</span></li>
                                            <li>{ langDict.docExt4 }：<span>{ langDict.docExt5 } <em>{ langDict.docExt6 }</em></span></li>
                                        </ul>
                                        <ProductRowBuyButton
                                            className="cursor-pointer font-center buy-button"
                                            uniqueId={ data.uniqueId }
                                            price={ data.price }
                                            exclusiveType={ data.exclusiveType }
                                            userLevel={ userLevel }
                                            guestCheckout={ data.extension.guestCheckout }
                                            orderCnt={ orderCnt }
                                        />
                                    </>
                            }
                            <h5>{ langDict.docExt7 }</h5>
                            <p className="other-option">
                                <span>{ langDict.docExt8 }:</span>
                                <span>{ langDict.docExt9 }</span>
                            </p>
                            <p className="other-option">{ langDict.docExt10 }</p>
                        </div>
                    </div>
                </div>
                <div className="content-box">
                    <div className="doc-content">
                        {
                            data.videoCode !== ''
                                ? <>
                                    <VideoCodeBox code={ data.videoCode } />
                                    <Divider plain className="split-line" />
                                </>
                                : ''
                        }
                        {
                            configs.state !== null && share !== ''
                                ? <div className="share-award">
                                    { langDict.shareAwardTips } { configs.state.shareAward } { langDict.gold }
                                    <span
                                        className="share-button cursor-pointer"
                                        onClick={
                                            () => {
                                                doShare()
                                            }
                                        }
                                    >点击参与</span>
                                </div>
                                : ''
                        }
                        <div className="content">
                            <span className="title">{ langDict.gameContent }: </span>
                            <div className="html-content-print" dangerouslySetInnerHTML={{ __html: data.content }} />
                            <div className="html-content-print" dangerouslySetInnerHTML={{ __html: langDict.gameDeclaration }} />
                        </div>
                    </div>
                    <div className="doc-ext">
                        <div className="contact">
                            <p className="widget-title">
                                <Icon><MessageChatBot /></Icon>
                                { langDict.contactTitle }
                            </p>
                            <Location
                                to={ configs.state !== null && !isEmpty(configs.state.serverUrl) ? configs.state.serverUrl : '' }
                                target="_blank"
                                className="button-item font-center"
                            >{ langDict.contactButton1 }</Location>
                            <Location to={ Routes.baidu } className="button-item font-center">{ langDict.contactButton2 }</Location>
                            <Location to={ configs.state.qqGroup } className="button-item font-center">{ langDict.contactButton3 }</Location>
                            <Location to={ Routes.future } className="button-item font-center">{ langDict.contactButton4 }</Location>
                        </div>
                        <Popular />
                    </div>
                </div>
            </>
    }</div>
}

export default Main