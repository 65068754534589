import { type AxiosResponse } from 'axios'
import { proxy, route, service } from '#utils/axios'
import { type OrderType, type PayState, type PayType } from '#constants/appoint'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import { type Params } from '#repositories/types/request'
import { type ListItem } from '#repositories/apis/documents'

export interface MakerOrderRequestParams {
    payType: PayType
    type: OrderType
    uniqueId: string
    email?: string
}

export interface MakerOrderResp {
    url: string
    uniqueId: string
    outTradeNo: string
    networkDisk?: NetworkDisk[]
}

export interface OrderItem {
    uniqueId: string
    userid: number
    orderId: string
    appid: string
    relationId: string
    type: number
    payType: PayType
    status: PayState
    payDate: number
    payEnv: number
    price: number
    actualPrice: number
    quantity: number
    amount: number
    unit: number
    remark: string
    email: string
    brief: string
    refundDate: number
    expireDate: number
    createdAt: number
    updatedAt: number
}

export interface NetworkDisk {
    type: number
    url: string
    code: string
    decryption: string
}

export interface ProductItem {
    uniqueId: string
    type: number
    createdAt: number
    title: string
    summary: string
    featuredImage: string
    diskSpace: string
    networkDisk: NetworkDisk[]
    cardUniqueId: string
    shippingType: number
}

export interface UserinfoItem {
    level: number
    expire: number
}

export interface SecretKeyItem {
    secret: string
}

export interface OrderRowResp {
    order: OrderItem
    product: ProductItem
    userinfo: UserinfoItem
    userLevel: DictionaryItem
    secretKeys: SecretKeyItem
}

export const makeOrder = async <T>(params: MakerOrderRequestParams): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/order/${ params.payType }/${ params.type }/${ params.uniqueId }`),
        method: 'post',
        data: {
            email: params.email ?? ''
        }
    })
}

export const makeOrderGuestCheckout = async <T>(params: MakerOrderRequestParams): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/order-visitor/${ params.payType }/${ params.type }/${ params.uniqueId }`),
        method: 'post',
        data: {
            email: params.email ?? ''
        }
    })
}

export const continueToPay = async <T>(uniqueId: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/order-continue-to-pay/${ uniqueId }`),
        method: 'get'
    })
}

export interface WithdrawFormParams {
    amounts: number
    account: string
    name: string
}

export const withdraw = async <T>(data: WithdrawFormParams): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/wallet/withdraw'),
        method: 'post',
        data
    })
}

export const row = async <T>(uniqueId: string): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, `/v1/order/${ uniqueId }`),
        method: 'get'
    })
}

export interface OrderListResp {
    list: OrderItem[]
    total: number
    products: Record<string, ListItem>
}

export const list = async <T>(data: Params): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/orders'),
        method: 'post',
        data
    })
}
