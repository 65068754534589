import React, { type Ref, useEffect, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Divider, Empty, Input, type InputRef } from 'antd'
import { isEmpty, throttle } from '#utils/functions'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { defaultManyLimit, DocumentType, UserLevelType } from '#constants/appoint'
import useFetchState from '#repositories/models'
import { Configs, Language } from '#repositories/models/recoil-state'
import { list as listApi, type ListItem } from '#repositories/apis/documents'
import Loading, { LoadingType } from '#components/loading'
import { ProductItem } from '#components/document/item'
import Icon from '#components/icon'
import { ReactComponent as IconSearch } from '#assets/svg/search.svg'
import { ReactComponent as Reload } from '#assets/svg/reload.svg'
import { type Condition, Operator } from '#repositories/types/request'

interface FetchSearchParamsType {
    keyword: string
    scroll: boolean
}

interface SearchInputProps {
    value: string
    placeholder: string
    onChange: (value: string, finish: boolean) => void
    suffix?: React.ReactNode
    bordered?: boolean
    input?: Ref<InputRef>
}

export const SearchInput: React.FC<SearchInputProps> = (
    {
        value,
        placeholder,
        onChange,
        suffix,
        bordered,
        input
    }
) => {
    const onCompositionEnd = useRef(true)

    return <Input
        allowClear
        value={ value }
        className="input"
        suffix={ suffix }
        prefix={ <Icon><IconSearch /></Icon> }
        placeholder={ placeholder }
        ref={ input }
        bordered={ bordered ?? false }
        onCompositionStart={
            () => {
                onCompositionEnd.current = false
            }
        }
        onCompositionEnd={
            e => {
                onCompositionEnd.current = true
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                onChange(e.target.value, onCompositionEnd.current)
            }
        }
        onChange={
            e => {
                onChange(e.target.value, onCompositionEnd.current)
            }
        }
    />
}

interface MainProps {
    defaultValue?: string
    setVisible?: (val: boolean) => void
}

const Main: React.FC<MainProps> = ({ setVisible, defaultValue }) => {
    const page = useRef(1)
    const configs = new Configs()
    const configsState = configs.shared()
    const lang = new Language()
    const input = useRef<InputRef>(null)
    const [ LangDict, setLangDict ] = useFetchState<LT>({})
    const [ loading, setLoading ] = useFetchState(false)
    const [ list, setList ] = useFetchState<ListItem[]>([])
    const [ keyword, setKeyword ] = useFetchState(defaultValue ?? '')
    const [ hasMore, setHasMore ] = useFetchState(true)

    const fetchSearch = (params: FetchSearchParamsType, immediately: boolean = false): void => {
        if (!params.scroll) {
            setList([])
        }

        if (params.keyword.trim() === '') {
            setLoading(false)
            setList([])
            page.current = 1
            setHasMore(false)
            return
        }

        if (!params.scroll) {
            page.current = 1
        }

        setLoading(true)
        const func = (): void => {
            const conditions: Condition[] = [ { column: 'type', value: DocumentType.product } ]
            // 隐藏免费游戏
            if (configsState !== null ? configsState.hideFreeGames : false) {
                conditions.push({
                    column: 'price',
                    value: 0,
                    operator: Operator.gt
                })
            }
            // 隐藏限定游戏
            if (configsState !== null ? configsState.hideVipGames : false) {
                conditions.push({
                    column: 'exclusiveType',
                    value: UserLevelType.permanent,
                    operator: Operator.neq
                })
            }

            void listApi({
                page: page.current,
                limit: defaultManyLimit,
                conditions,
                keyword: params.keyword
            }).then(
                res => {
                    const data = res.data as ListItem[]
                    setHasMore(data.length >= defaultManyLimit)
                    if (params.scroll) {
                        setList([ ...list, ...data ])
                        return
                    }

                    setList(data)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        }
        if (immediately) {
            func()
            return
        }

        throttle(func, 1000, 'globalSearch')
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'noMoreData',
                        'searchInputPlaceholder',
                        'popularSearches'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            setTimeout(
                () => {
                    if (input.current !== null) {
                        input.current.focus()
                    }
                },
                300
            )
        },
        []
    )

    useEffect(
        () => {
            if (defaultValue === undefined || isEmpty(defaultValue)) {
                return
            }

            fetchSearch({ keyword: defaultValue, scroll: false }, true)
        },
        [ defaultValue ]
    )

    return <div className="wh100 search-popup">
        <SearchInput
            value={ keyword }
            suffix={ loading ? <Icon className="i-lg rotating-ele"><Reload /></Icon> : '' }
            placeholder={ LangDict.searchInputPlaceholder }
            input={ input }
            bordered={ true }
            onChange={
                (value, finish) => {
                    setKeyword(value)
                    if (finish) {
                        fetchSearch({
                            keyword: value,
                            scroll: false
                        })
                    }
                }
            }
        />
        <div className="popular-search">
            { LangDict.popularSearches }
            <div className="items">{
                configsState !== null
                    ? configsState.keywords.map(
                        (item, key) => <span
                            onClick={
                                () => {
                                    const value = pickLangKey(lang.state, item)
                                    setKeyword(value)
                                    fetchSearch({
                                        keyword: value,
                                        scroll: false
                                    })
                                }
                            }
                            key={ key }
                        >{ pickLangKey(lang.state, item) }</span>
                    )
                    : ''
            }</div>
        </div>
        <div id="scrollableBox" className={ 'list' + (list.length <= 0 ? ' empty' : '') }>{
            list.length <= 0
                ? <Empty />
                : <InfiniteScroll
                    className="w100"
                    dataLength={ list.length }
                    next={
                        (): void => {
                            page.current += 1
                            fetchSearch({
                                keyword,
                                scroll: true
                            })
                        }
                    }
                    hasMore={ hasMore }
                    loader={ <Loading type={ LoadingType.bottom } /> }
                    endMessage={ <Divider plain className="no-more-data">{ LangDict.noMoreData }</Divider> }
                    scrollableTarget="scrollableBox"
                >
                    <div className="w100 search-list">
                        {
                            list.map(
                                (item, key) => <ProductItem
                                    key={ key }
                                    className="search"
                                    item={ item }
                                    keyword={ keyword }
                                    setVisible={ setVisible }
                                />
                            )
                        }
                    </div>
                </InfiniteScroll>
        }</div>
    </div>
}

export default Main