import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Breadcrumb, Col, Row, Button } from 'antd'
import localization, { type LT } from '#utils/localization'
import { productDetailRoutePath, RouteProfile } from '#utils/router'
import { copyToClipboard, isEmpty, timestampFormat } from '#utils/functions'
import { documentTitle } from '#utils/configs'
import useFetchState from '#repositories/models'
import { Configs, Language } from '#repositories/models/recoil-state'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import { continueToPay, type MakerOrderResp, type OrderItem, type OrderRowResp, type ProductItem, row, type SecretKeyItem, type UserinfoItem } from '#repositories/apis/orders'
import Loading from '#components/loading'
import Location from '#components/location'
import Image from '#components/image'
import UpgradeItem from '#components/upgrade/item'
import { OrderType, PayState } from '#constants/appoint'
import { makePayState, type RowProps } from './model'
import NetworkDiskTmp from '#pages/orders/network-disk'
import { MessageType, MMessage } from '#components/hint'

const Main: React.FC<RowProps> = props => {
    const fetchState = useRef(false)
    const history = useHistory()
    const lang = new Language()
    const configs = new Configs()
    const configsState = configs.shared()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 获取详情加载
    const [ loading, setLoading ] = useFetchState(true)
    // data
    const [ orderItem, setOrderItem ] = useFetchState<OrderItem | null>(null)
    const [ productItem, setProductItem ] = useFetchState<ProductItem | null>(null)
    const [ userinfoItem, setUserinfoItem ] = useFetchState<UserinfoItem | null>(null)
    const [ userLevel, setUserLevel ] = useFetchState<DictionaryItem | null>(null)
    const [ secretKey, setSecretKey ] = useFetchState<SecretKeyItem | null>(null)
    const [ buyLoading, setBuyLoading ] = useFetchState(false)
    const [ disabled, setDisabled ] = useFetchState(false)

    const goPay = (): void => {
        if (orderItem === null) {
            return
        }

        setBuyLoading(true)
        void continueToPay(orderItem.uniqueId).then(
            res => {
                setDisabled(true)
                const data = res.data as MakerOrderResp
                if (data.url !== '' && !isEmpty(data.url)) {
                    window.location.href = data.url
                }
            }
        ).finally(
            () => {
                setBuyLoading(false)
            }
        )
    }

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'orderDetailList',
                    'orderPayStateTitle',
                    'orderPayDateTitle',
                    'orderMakeCreatedTitle',
                    'orderDetailTitle',
                    'orderPayStateWait',
                    'orderPayStateSuccess',
                    'orderPayStatePayCancel',
                    'orderPayStatePayAbnormal',
                    'orderPayStateRefund',
                    'orderPayStateNotPay',
                    'orderPayStateClosed',
                    'orderPayStateRevoked',
                    'orderPayStateUserPaying',
                    'orderPayStatePayError',
                    'payAmount',
                    'orderAmount',
                    'payMethod',
                    'orderNumber',
                    'gold',
                    'diskSpaceTitle',
                    'networkDiskTitle',
                    'networkCodeTitle',
                    'decryptionCodeTitle',
                    'installDecompressionCodeTitle',
                    'userVipExpireTitle',
                    'networkTitle',
                    'copySuccess',
                    'appCode',
                    'orderDone'
                ]
            })
            setLangDict(langDict)
            documentTitle(langDict.orderDetailTitle)

            if (props.data !== undefined) {
                setLoading(false)
                setOrderItem(props.data.order)
                setProductItem(props.data.product)
                setUserinfoItem(props.data.userinfo)
                setUserLevel(props.data.userLevel)
                return
            }

            if (props.match.params.uniqueId === '') {
                return
            }

            if (fetchState.current) {
                return
            }

            fetchState.current = true
            // 获取订单信息
            void row(props.match.params.uniqueId).then(
                res => {
                    const data = res.data as OrderRowResp
                    setOrderItem(data.order)
                    setProductItem(data.product)
                    setUserinfoItem(data.userinfo)
                    setUserLevel(data.userLevel)
                    setSecretKey(data.secretKeys)
                }
            ).catch(
                () => {
                    history.push(RouteProfile.purchaseHistory)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        },
        [ lang.state, props.match.params.uniqueId ]
    )

    const state = (productItem !== null && !isEmpty(productItem)) || (userLevel !== null && !isEmpty(userLevel))

    return <div className="order-row-container">
        {
            props.header ?? <Breadcrumb
                separator="/"
                items={
                    [
                        {
                            className: 'cursor-pointer blue',
                            title: langDict.orderDetailList,
                            onClick: () => {
                                history.push(RouteProfile.purchaseHistory)
                            }
                        },
                        { title: langDict.orderDetailTitle }
                    ]
                }
            />
        }
        <Divider plain />
        {
            loading
                ? <Loading />
                : <>
                    <Row className="order-detail-box">
                        {
                            state
                                ? <Col span={ 12 }>
                                    {
                                        productItem !== null && !isEmpty(productItem)
                                            ? <Row className="list-item order">
                                                <Col span={ 8 } className="flex-center featured-image">
                                                    <Location className="wh100" to={ productDetailRoutePath(productItem?.uniqueId ?? '') }>
                                                        <Image product={ true } className="logo fill" src={ productItem?.featuredImage ?? '' } alt="" />
                                                    </Location>
                                                </Col>
                                                <Col span={ 16 } className="detail">
                                                    <p className="title">
                                                        <Location className="w100" to={ productDetailRoutePath(productItem?.uniqueId ?? '') } title={ productItem?.title }>
                                                            <b className="ellipsis-1">{ productItem?.title }</b>
                                                        </Location>
                                                    </p>
                                                    <p className="summary">
                                                        <Location className="w100 ellipsis-3" to={ productDetailRoutePath(productItem?.uniqueId ?? '') } title={ productItem?.summary }>
                                                            { productItem?.summary }...
                                                        </Location>
                                                    </p>
                                                    <div className="controls">
                                                        <div>发布于: { timestampFormat(productItem?.createdAt ?? 0) }</div>
                                                    </div>
                                                </Col>
                                                <Col span={ 24 }>
                                                    <div className="divider" />
                                                </Col>
                                            </Row>
                                            : ''
                                    }
                                    {
                                        userLevel !== null && !isEmpty(userLevel)
                                            ? <Row className="list-item upgrade-preview">
                                                <UpgradeItem
                                                    item={ userLevel }
                                                    readonly={ true }
                                                />
                                            </Row>
                                            : ''
                                    }
                                </Col>
                                : ''
                        }
                        <Col span={ state ? 12 : 24 } className="order-info">
                            <div className="item">
                                <span className="title">{ langDict.orderPayStateTitle }:</span>
                                <div>{ makePayState(orderItem?.status ?? 0, langDict) }</div>
                            </div>
                            {
                                orderItem?.status === PayState.success
                                    ? <div className="item">
                                        <span className="title">{ langDict.orderPayDateTitle }:</span>
                                        <div>{ timestampFormat(orderItem?.payDate ?? 0) }</div>
                                    </div>
                                    : ''
                            }
                            <div className="item">
                                <span className="title">{ langDict.payMethod }:</span>
                                <div className="pay-method"><Image src="/assets/images/pay/alibaba.png" /></div>
                            </div>
                            <div className="item">
                                <span className="title">{ langDict.orderAmount }:</span>
                                <span className="money">{ orderItem?.actualPrice } { langDict.gold }</span>
                            </div>
                            <div className="item">
                                <span className="title">{ langDict.payAmount }:</span>
                                <span className="money">{ orderItem?.amount } { langDict.gold }</span>
                            </div>
                            {
                                orderItem?.type === OrderType.appSecretKey && secretKey !== null
                                    ? <>
                                        <div className="item">
                                            <span className="title">{ langDict.appCode }:</span>
                                            <span
                                                className="code cursor-pointer blue"
                                                onClick={
                                                    () => {
                                                        copyToClipboard(secretKey.secret, () => {
                                                            MMessage({
                                                                message: langDict.copySuccess,
                                                                type: MessageType.success
                                                            })
                                                        })
                                                    }
                                                }
                                            >{ secretKey.secret }</span>
                                        </div>
                                        {
                                            configsState === null
                                                ? ''
                                                : <div className="item">
                                                    <span className="title">软件下载地址:</span>
                                                    <span className="cursor-pointer blue button" onClick={ () => { window.open(configsState.desktopAppDownloadUrlLanzou) } }>游戏盒子下载</span>
                                                </div>
                                        }
                                    </>
                                    : ''
                            }
                            {
                                orderItem?.status === PayState.wait
                                    ? <div className="item">
                                        <Button className="go-pay" disabled={ disabled } loading={ buyLoading } onClick={ goPay }>去支付</Button>
                                    </div>
                                    : <div className="item">
                                        <span className="title">{ langDict.orderNumber }:</span>
                                        <span>{ orderItem?.orderId ?? '-' }</span>
                                    </div>
                            }
                        </Col>
                    </Row>
                    {
                        productItem !== null && !isEmpty(productItem)
                            ? <div className="shipments">
                                {
                                    productItem.diskSpace === ''
                                        ? ''
                                        : <Row className="item">
                                            <Col span={ 4 } className="title">{ langDict.diskSpaceTitle }:</Col>
                                            <Col span={ 20 } className="content">{ productItem.diskSpace ?? '-' }</Col>
                                        </Row>
                                }
                                {
                                    productItem.networkDisk.length > 0
                                        ? <>
                                            <Divider plain>{ langDict.networkTitle }</Divider>
                                            <NetworkDiskTmp
                                                lang={ lang }
                                                langDict={ langDict }
                                                networkDisk={ productItem.networkDisk }
                                                title={ productItem.title }
                                                simple={ true }
                                            />
                                        </>
                                        : ''
                                }
                            </div>
                            : ''
                    }
                    {
                        userinfoItem !== null && !isEmpty(userinfoItem)
                            ? <div className="shipments">
                                <Row className="item">
                                    <Col span={ 4 } className="title">{ langDict.userVipExpireTitle }:</Col>
                                    <Col span={ 20 } className="content">{ timestampFormat(userinfoItem.expire) }</Col>
                                </Row>
                            </div>
                            : ''
                    }
                </>
        }
    </div>
}

export default Main