import React, { useEffect } from 'react'
import Upgrade from '#assets/svg/upgrade.svg'
import Privilege1 from '#assets/images/privilege/1.png'
import Privilege2 from '#assets/images/privilege/2.png'
import Privilege3 from '#assets/images/privilege/3.png'
import Privilege4 from '#assets/images/privilege/4.png'
import Image from '#components/image'
import UpgradeList from '#components/upgrade'
import Location from '#components/location'
import { Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { documentTitle } from '#utils/configs'

const Main: React.FC = () => {
    const configs = new Configs()
    const configsState = configs.shared()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'orderUserLevelUpgradeTitle',
                    'privilegeTitle1',
                    'privilegeTitle2',
                    'privilegeTitle3',
                    'privilegeTitle4',
                    'privilegeContent1',
                    'privilegeContent2',
                    'privilegeContent3',
                    'privilegeContent4',
                    'headerMenuQuestionsAndAnswers',
                    'friendlyLinkExplain',
                    'vipFooterExplain'
                ]
            })
            setLangDict(langDict)
            documentTitle(langDict.orderUserLevelUpgradeTitle)
        },
        [ lang.state ]
    )

    return <div className="upgrade-container">
        <div className="w100 background">
            <Image product={ true } original className="logo fill" src={ Upgrade } alt="" />
        </div>
        <UpgradeList />
        <div className="slogan">
            <h2>{ configsState === null ? '' : pickLangKey(lang.state, configsState.subscriptionTitle) }</h2>
            <p className="description">{ configsState === null ? '' : pickLangKey(lang.state, configsState.subscriptionDescription) }</p>
        </div>
        <div className="privilege">
            <div className="item">
                <img src={ Privilege1 } alt=""/>
                <p className="title">{ langDict.privilegeTitle1 }</p>
                <p className="content">{ langDict.privilegeContent1 }</p>
            </div>
            <div className="item">
                <img src={ Privilege2 } alt=""/>
                <p className="title">{ langDict.privilegeTitle2 }</p>
                <p className="content">{ langDict.privilegeContent2 }</p>
            </div>
            <div className="item">
                <img src={ Privilege3 } alt=""/>
                <p className="title">{ langDict.privilegeTitle3 }</p>
                <p className="content">{ langDict.privilegeContent3 }</p>
            </div>
            <div className="item">
                <img src={ Privilege4 } alt=""/>
                <p className="title">{ langDict.privilegeTitle4 }</p>
                <p className="content">{ langDict.privilegeContent4 }</p>
            </div>
        </div>
        {
            configsState === null
                ? ''
                : <div className="faq">
                    <p className="title">{ langDict.headerMenuQuestionsAndAnswers }</p>
                    <p className="description">FAQ</p>
                    <div className="list">
                        {
                            configsState.vipFAQ.map(
                                (item, key) => <div key={ key } className="item">
                                    <p className="title">{ pickLangKey(lang.state, item.title) }</p>
                                    <p className="content">{ pickLangKey(lang.state, item.content) }</p>
                                </div>
                            )
                        }
                    </div>
                </div>
        }
        <div className="footer-links">
            <span className="title">{ langDict.friendlyLinkExplain }:</span>
            <ul>{
                configsState?.friendlyLinks.map(
                    (item, key) => <li key={ key }>
                        <Location target="_blank" to={ item.url }>{ pickLangKey(lang.state, item.title) }</Location>
                    </li>
                )
            }</ul>
        </div>
        <div className="footer-copyright">{ langDict.vipFooterExplain }</div>
    </div>
}

export default Main