import React, { useEffect } from 'react'
import { Breadcrumb, Button, Input } from 'antd'
import Image from '#components/image'
import Icon from '#components/icon'
import { Alert, MessageType, MMessage } from '#components/hint'
import exampleImage from '#assets/images/order-query-example.jpg'
import { ReactComponent as IconSearch } from '#assets/svg/search.svg'
import { Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { type OrderRowResp, row } from '#repositories/apis/orders'
import { type UniqueIdProps } from '#repositories/types/foundation'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { textOnChange } from '#utils/events'
import { apiBehavior } from '#constants/appoint'
import OrderRow from '#pages/orders/row'
import { isEmpty, throttle } from '#utils/functions'

const Main: React.FC<UniqueIdProps> = ({ match: { params: { uniqueId } } }) => {
    const configs = new Configs()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    const [ orderId, setOrderId ] = useFetchState('')
    const [ loading, setLoading ] = useFetchState(false)
    const [ data, setData ] = useFetchState<OrderRowResp | null>(null)

    const submit = (orderId: string, langDict: LT): void => {
        if (orderId === '') {
            MMessage({
                message: langDict.searchOrderInputPlaceholder,
                type: MessageType.warning
            })
            return
        }

        setLoading(true)
        apiBehavior.disabledErrMsg = true
        void row(orderId).then(
            res => {
                setData(res.data as OrderRowResp)
            }
        ).catch(
            () => {
                MMessage({
                    message: langDict.searchOrderError,
                    type: MessageType.warning
                })
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    useEffect(
        () => {
            const langDict = localization({
                lang: lang.state,
                keys: [
                    'queryTitle',
                    'searchOrderInputPlaceholder',
                    'queryExplainTitle',
                    'headerMenuOrderQuery',
                    'orderDetailTitle',
                    'orderQueryButtonTitle',
                    'searchOrderError'
                ]
            })
            setLangDict(langDict)

            if (uniqueId === undefined || isEmpty(uniqueId)) {
                return
            }

            setOrderId(uniqueId)
            throttle(
                () => {
                    submit(uniqueId, langDict)
                },
                200,
                'orderFetch'
            )
        },
        [ lang.state, uniqueId ]
    )

    return data !== null
        ? <OrderRow
            data={ data }
            match={ { params: { uniqueId: '' } } }
            header={
                <Breadcrumb
                    separator="/"
                    items={
                        [
                            {
                                className: 'cursor-pointer blue',
                                title: langDict.headerMenuOrderQuery,
                                onClick: () => {
                                    setData(null)
                                }
                            },
                            { title: langDict.orderDetailTitle }
                        ]
                    }
                />
            }
        />
        : <div className="order-query-container">
            <div className="search-box sp">
                <p className="order-query-example-title">{ langDict.headerMenuOrderQuery }:</p>
                <Input
                    className="input"
                    prefix={ <Icon><IconSearch /></Icon> }
                    placeholder={ langDict.searchOrderInputPlaceholder }
                    onChange={
                        textOnChange(value => {
                            setOrderId(value.trim())
                        })
                    }
                />
                <Button
                    className={ `search${ orderId === '' || orderId.length <= 5 ? ' disabled' : '' }` }
                    loading={ loading }
                    disabled={ orderId === '' || orderId.length <= 5 }
                    onClick={
                        () => {
                            submit(orderId, langDict)
                        }
                    }
                >{ langDict.queryTitle }</Button>
            </div>
            <p className="attention">
                { configs.state === null ? '' : <span>{ pickLangKey(lang.state, configs.state.orderQueryAnnouncements) }</span> }
                <span
                    className="cursor-pointer"
                    onClick={
                        () => {
                            Alert({
                                className: 'query-order-popup',
                                width: 700,
                                message: <div className="order-query-example font-center">
                                    <Image className="fill" src={ exampleImage } />
                                </div>
                            })
                        }
                    }
                >{ langDict.orderQueryButtonTitle }</span>
            </p>
        </div>
}

export default Main