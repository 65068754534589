import store from '#utils/store'
import { execFunc, isEmpty, isObject } from '#utils/functions'
import { type ResponseType } from '#repositories/types/response'
import { type TokenType } from '#repositories/types/local-store'
import { variables } from '#constants/appoint'
import { MessageType, MMessage } from '#components/hint'
import { profileCheck } from '#repositories/models/recoil-state'
import { Routes } from '#utils/router'

const authorizationKey: string = 'user'

/**
 * 存储token
 * @param type
 * @param data
 */
export function authorization(type: string, data?: TokenType): TokenType | null {
    if (type === 'set') {
        store.set(authorizationKey, data)
        return data as TokenType
    }

    const results = store.get(authorizationKey)
    if (!isObject(results) || isEmpty(results)) {
        store.remove(authorizationKey)
        return null
    }

    const res = results as TokenType
    if (new Date().valueOf() / 1000 > (res.expires ?? 0)) {
        store.remove(authorizationKey)
        return null
    }

    return res
}

export function tokenRenewal(data: ResponseType): void {
    const { token, timestamp, logout, code } = data
    if (code === 1000013 || code === 1000014) {
        // ip被封
        if (window.location.pathname !== Routes.banned) {
            window.location.href = Routes.banned
        }
    }

    if (isEmpty(data)) {
        return
    }

    if (logout === true) {
        MMessage({
            message: '账号已在他处登录',
            type: MessageType.warning
        })

        profileCheck.neededUpdate = true
        clearCache()
    }

    variables.timestamp = timestamp
    const results = store.get(authorizationKey)
    if (!isEmpty(results)) {
        return
    }

    if (token !== '' && token !== undefined) {
        const res = results as TokenType
        res.token = token
        void authorization('set', res)
    }
}

interface LogoutProps {
    locationSign: boolean
    callback?: () => void
}

/**
 * 退出登录清除所有缓存
 * @param locationSign
 * @param callback
 * @constructor
 */
export function logout({ locationSign, callback }: LogoutProps): void {
    // 清除缓存
    clearCache()
    if (!locationSign) {
        return
    }

    execFunc(callback)
    // window.location.href = '/login'
    window.location.href = '/l'
}

export function clearCache(): void {
    store.remove(authorizationKey)
}