import React from 'react'

interface MainProps {
    code: string
    className?: string
    autoPlay?: boolean
    hideControls?: boolean
    loop?: boolean
}

const Main: React.FC<MainProps> = (
    {
        code,
        className,
        autoPlay,
        hideControls,
        loop
    }
) => {
    const parseCode = (): string => {
        let data = code.trim()
        if (data.indexOf('http') === 0) {
            data = `<video controls>
  <source src="movie.mp4" type="video/mp4" />
  <source src="${ data }" />
</video>`
        }

        if (autoPlay === true) {
            data = data.replace(
                '<video controls>',
                '<video autoplay muted controls>'
            )
        }

        if (hideControls === true) {
            data = data.replace(
                ' controls>',
                '>'
            )
        }

        if (loop === true) {
            data = data.replace(
                '<video ',
                '<video loop '
            )
        }

        return data
    }

    return <div className={ `video-box${ className !== undefined ? ' ' + className : '' }` }>
        <div className="video">
            <div className="video-html-content-print" dangerouslySetInnerHTML={ { __html: parseCode() } }/>
        </div>
    </div>
}

export default Main