import React, { useEffect } from 'react'
import { Row, Col, Empty } from 'antd'
import { useHistory } from 'react-router-dom'
import { articleDetailRoutePath, Routes } from '#utils/router'
import { isEmpty, throttle, timestampFormat, toNumber } from '#utils/functions'
import localization, { type LT } from '#utils/localization'
import { scrollToTop } from '#utils/events'
import { dateFormatYmd, defaultLimit, DocumentType, SortBy as SortByEnum } from '#constants/appoint'
import useFetchState from '#repositories/models'
import { type ListItem, type ListResp, listWidthTotal } from '#repositories/apis/documents'
import { Language } from '#repositories/models/recoil-state'
import { type PageProps } from '#repositories/types/foundation'
import Loading from '#components/loading'
import Location from '#components/location'
import Image from '#components/image'
import Pagination from '#components/page'
import Popular from '#components/document/popular'
import { Operator } from '#repositories/types/request'

interface MainProps extends PageProps {
    match: {
        params: {
            page?: number
            limit?: number
            filters?: string
        }
    }
}

const Main: React.FC<MainProps> = (props) => {
    const history = useHistory()
    // 页码
    const [ page, setPage ] = useFetchState(
        props.match.params.page !== undefined && !isEmpty(props.match.params.page)
            ? toNumber(props.match.params.page, 1)
            : 1
    )
    // 每页条数
    const [ limit, setLimit ] = useFetchState(
        props.match.params.limit !== undefined && !isEmpty(props.match.params.limit)
            ? toNumber(props.match.params.limit, defaultLimit)
            : defaultLimit
    )

    // 列表数据
    const [ list, setList ] = useFetchState<ListItem[]>([])
    // 列表总数
    const [ total, setTotal ] = useFetchState(0)
    const [ loading, setLoading ] = useFetchState(true)
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'published'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            setLoading(true)
            throttle(
                (): void => {
                    // 获取列表详情
                    void listWidthTotal({
                        limit,
                        page,
                        sortBy: [ { column: 'sort', value: SortByEnum.desc } ],
                        conditions: [
                            { column: 'type', value: DocumentType.article },
                            {
                                column: 'uniqueId',
                                value: [ 'doc112', 'doc111', 'doc110' ],
                                operator: Operator.neq
                            }
                        ]
                    }).then(
                        res => {
                            const data = res.data as ListResp
                            setList(data.list)
                            setTotal(data.total)
                        }
                    ).finally(
                        () => {
                            setLoading(false)
                        }
                    )
                },
                200,
                'games'
            )
        },
        [ page, limit ]
    )

    return <div className="article-container">
        <div className="article-list">
            <Row className="w100 articles">
                {
                    loading
                        ? <Loading />
                        : <>{
                            list.length <= 0
                                ? <Empty description={ false } className="empty-list" />
                                : list.map(
                                    (item, key) => <Col className="list-item" span={ 24 } key={ key }>
                                        <Row className="w100">
                                            <Col span={ 5 } className="flex-center featured-image">
                                                <Location className="wh100" to={ articleDetailRoutePath(item.uniqueId) }>
                                                    <Image product={ true } className="fill" src={ item.featuredImage } alt="" />
                                                </Location>
                                            </Col>
                                            <Col span={ 15 } className="detail">
                                                <p className="title">
                                                    <span className="ellipsis-1">{ item.title }</span>
                                                </p>
                                                <p className="summary">
                                                    <span className="ellipsis-4">{ item.summary }</span>
                                                </p>
                                                <div className="controls">
                                                    <div className="createdAt">{ langDict.published }: { timestampFormat(item.createdAt, dateFormatYmd) }</div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="w100">
                                            <Col span={ 24 }>
                                                <div className="divider" />
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                        }</>
                }
            </Row>
            <Pagination
                className="pagination flex-cc"
                total={ total }
                callback={
                    (current, pageSize) => {
                        scrollToTop(200, () => {
                            history.push(`${ Routes.questionsAndAnswers }/${ current }/${ pageSize }`)
                            setPage(current)
                            setLimit(pageSize)
                        })
                    }
                }
                page={ page }
                pageSize={ limit }
            />
        </div>
        <div className="popular-widget-container"><Popular /></div>
    </div>
}

export default Main