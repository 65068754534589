import React, { useEffect, useRef } from 'react'
import $ from 'jquery'
import { Dictionary, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import localization, { type LT } from '#utils/localization'
import UpgradeItem from '#components/upgrade/item'
import Loading from '#components/loading'
import Icon from '#components/icon'
import { ReactComponent as Star } from '#assets/svg/star.svg'

interface MainProps {
    hideTitle?: boolean
    hideContent?: boolean
    def?: number
}

const Main: React.FC<MainProps> = ({ hideTitle, hideContent, def }) => {
    const subscriptionOwlStageBindMousewheel = useRef(false)
    // 字典
    const dictionary = new Dictionary()
    const dictionaryState = dictionary.shared()
    // 会员套套餐
    const [ userLevels, setUseLevels ] = useFetchState<DictionaryItem[]>([])
    // language
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'loginSuccessWidth',
                        'gold',
                        'subscriptionFont1',
                        'subscriptionFont2',
                        'subscriptionFont3',
                        'subscriptionFont4',
                        'subscriptionFont5',
                        'upgrade'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            if (dictionaryState === null) {
                return
            }

            if (dictionaryState.userLevel !== undefined && dictionaryState.userLevel !== null && dictionaryState.userLevel.children.length > 0) {
                const userLevels: DictionaryItem[] = []
                dictionaryState.userLevel.children.forEach(
                    item => {
                        userLevels.push(item)
                    }
                )
                setUseLevels(userLevels)

                setTimeout(
                    () => {
                        userLevels.forEach(
                            (item, key) => {
                                if (item.id === def) {
                                    $('.subscription-list-box').trigger('to.owl.carousel', [ key, 0, true ])
                                }
                            }
                        )
                    },
                    300
                )

                if (subscriptionOwlStageBindMousewheel.current) {
                    return
                }
                subscriptionOwlStageBindMousewheel.current = true
            }
        },
        [ dictionaryState, def ]
    )

    return <section className="subscription">
        {
            hideTitle !== undefined && hideTitle
                ? ''
                : <>
                    <h1>{ langDict.subscriptionFont1 }</h1>
                    <div className="advantage">
                        <div className="item">
                            <Icon tap><Star /></Icon>
                            <span>{ langDict.subscriptionFont2 }</span>
                        </div>
                        <div className="item">
                            <Icon tap><Star /></Icon>
                            <span>{ langDict.subscriptionFont3 }</span>
                        </div>
                        <div className="item">
                            <Icon tap><Star /></Icon>
                            <span>{ langDict.subscriptionFont4 }</span>
                        </div>
                        <div className="item">
                            <Icon tap><Star /></Icon>
                            <span>{ langDict.subscriptionFont5 }</span>
                        </div>
                    </div>
                </>
        }
        {
            userLevels.length <= 0
                ? <Loading />
                : <div className="subscription-list">
                    <div className="subscription-list-box">{
                        userLevels.map(
                            (item, key) => <UpgradeItem
                                hideContent={ hideContent }
                                topIcon={ true }
                                key={ key }
                                item={ item }
                                active={ def === item.id }
                                readonly={ def === item.id }
                            />
                        )
                    }</div>
                </div>
        }
    </section>
}

export default Main