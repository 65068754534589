import React, { useEffect } from 'react'
import { Button, Col, Divider, Input, Row } from 'antd'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { numberOnChange, textOnChange } from '#utils/events'
import { toNumber } from '#utils/functions'
import { Configs, Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import { makeOrder, type MakerOrderResp, withdraw as withdrawApi, type WithdrawFormParams } from '#repositories/apis/orders'
import { makeStatisticsResp, statistics as statisticsApi, type StatisticsResp } from '#repositories/apis/wallet'
import Icon from '#components/icon'
import { MessageType, MMessage } from '#components/hint'
import { ReactComponent as CoinYen } from '#assets/svg/coin-yen.svg'
import { ReactComponent as User } from '#assets/svg/user.svg'
import { ReactComponent as Checked } from '#assets/svg/checked.svg'
import { ReactComponent as FileInvoice } from '#assets/svg/file-invoice.svg'
import { amounts } from '#pages/profile/model'
import { Cards } from '#pages/profile/components'
import { OrderType, PayType } from '#constants/appoint'
import Location from '#components/location'

const Main: React.FC = () => {
    const configs = new Configs().shared()
    const externalLinkState = configs?.externalLinkState ?? false
    const externalLinkRecharge = configs?.externalLinkUpgrade ?? ''
    const withdrawalStatus = configs?.withdrawalStatus ?? false
    const lang = new Language()
    const [ amount, setAmount ] = useFetchState(100)
    // 本地化
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    const [ withdrawLoading, setWithdrawLoading ] = useFetchState(false)
    // 获取钱包统计加载
    const [ statisticsRespLoading, setStatisticsRespLoading ] = useFetchState(false)
    // disable
    const [ disabled, setDisabled ] = useFetchState(false)
    // 提现表单
    const [ withdrawFormParams, setWithdrawFormParams ] = useFetchState<WithdrawFormParams>({
        amounts: 0,
        account: '',
        name: ''
    })
    // statistics data
    const [ statisticsResp, setStatisticsResp ] = useFetchState(makeStatisticsResp())
    // 充值
    const recharge = (): void => {
        const val = toNumber(amount)
        if (val <= 0) {
            MMessage({
                message: langDict.amountLessThen0,
                type: MessageType.error
            })
            return
        }

        setLoading(true)
        setDisabled(true)
        void makeOrder({
            payType: PayType.ali,
            type: OrderType.recharge,
            uniqueId: val.toString()
        }).then(
            res => {
                const data = res.data as MakerOrderResp
                window.location.href = data.url
            }
        ).catch(
            () => {
                setTimeout(
                    () => {
                        setDisabled(false)
                    },
                    1000
                )
            }
        ).finally(
            () => {
                setTimeout(
                    () => {
                        setLoading(false)
                    },
                    1000
                )
            }
        )
    }
    // 提现
    const withdraw = (): void => {
        withdrawFormParams.amounts = toNumber(withdrawFormParams.amounts)
        if (withdrawFormParams.amounts <= 0) {
            MMessage({
                message: langDict.amountLessThen0,
                type: MessageType.error
            })
            return
        }

        if (withdrawFormParams.amounts > statisticsResp.balance) {
            MMessage({
                message: langDict.amountLessThenBalance,
                type: MessageType.error
            })
            return
        }

        if (configs !== null && configs.withdrawalAmountLimit > 0 && withdrawFormParams.amounts > configs.withdrawalAmountLimit) {
            MMessage({
                message: langDict.withdrawalAmountLimit + configs.withdrawalAmountLimit,
                type: MessageType.error
            })
            return
        }

        if (withdrawFormParams.account === '') {
            MMessage({
                message: langDict.withdrawAccountPlaceholder,
                type: MessageType.error
            })
            return
        }

        if (withdrawFormParams.name === '') {
            MMessage({
                message: langDict.withdrawNamePlaceholder,
                type: MessageType.error
            })
            return
        }

        setWithdrawLoading(true)
        void withdrawApi(withdrawFormParams).then(
            () => {
                MMessage({
                    message: langDict.withdrawSubmitSuccess,
                    type: MessageType.success
                })
            }
        ).finally(
            () => {
                setWithdrawLoading(false)
            }
        )
    }

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'withdrawalAmountLimit',
                        'updateSuccessMsg',
                        'payAmountPlaceholder',
                        'amountError',
                        'amountLessThen0',
                        'amountLessThenBalance',
                        'rechargeTitle',
                        'recharge',
                        'cardRechargeTitle',
                        'cardBalance',
                        'cardIncomeTitle',
                        'cardOutcomeTitle',
                        'cardWithdrawTitle',
                        'rechargeSubmit',
                        'withdrawSubmit',
                        'withdrawAmountsPlaceholder',
                        'withdrawAccountPlaceholder',
                        'withdrawNamePlaceholder',
                        'withdrawAddressPlaceholder',
                        'withdrawSubmitSuccess',
                        'otherAmounts',
                        'gold',
                        'confirm',
                        'orderWithdrawTitle'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            setStatisticsRespLoading(true)
            void statisticsApi().then(
                res => {
                    setStatisticsResp(res.data as StatisticsResp)
                }
            ).finally(
                () => {
                    setStatisticsRespLoading(false)
                }
            )
        },
        []
    )

    return <div className="balance-container">
        <Cards langDict={ langDict } statisticsResp={ statisticsResp } loading={ statisticsRespLoading } />
        <div className="amounts">{
            amounts.map(
                (item, key) => <div
                    key={ key}
                    className={ `flex-center cursor-pointer item${ amount === item ? ' active' : '' }` }
                    onClick={ () => { setAmount(item) } }
                >
                    { amount === item ? <Icon className="i-5x"><Checked /></Icon> : '' }
                    <span>{ item } { langDict.gold }</span>
                </div>
            )
        }</div>
        <div className="other-amounts">
            <Row className="item">
                <Col span={ 5 } className="title">{ langDict.otherAmounts }: </Col>
                <Col span={ 12 }>
                    <Input
                        allowClear
                        value={ amount }
                        prefix={ <Icon tap><CoinYen /></Icon> }
                        placeholder={ langDict.payAmountPlaceholder }
                        maxLength={ 20 }
                        onChange={
                            numberOnChange(value => {
                                setAmount(value)
                            })
                        }
                    />
                </Col>
                {
                    externalLinkState && externalLinkRecharge !== ''
                        ? <>
                            <Col offset={ 1 } span={ 6 } className="flex-end">
                                <Button
                                    disabled={ disabled }
                                    type="primary"
                                    className="submit background-button"
                                    loading={ loading }
                                    onClick={ recharge }
                                >{ langDict.rechargeSubmit }</Button>
                                <Location to={ externalLinkRecharge } className="external-link" target="_blank">平台充值</Location>
                            </Col>
                        </>
                        : <Col offset={ 4 } span={ 3 } className="flex-end">
                            <Button
                                disabled={ disabled }
                                type="primary"
                                className="submit background-button"
                                loading={ loading }
                                onClick={ recharge }
                            >{ langDict.rechargeSubmit }</Button>
                        </Col>
                }
            </Row>
            <Divider className="divider" plain />
            {
                withdrawalStatus
                    ? <Row className="item withdraw-form">
                        <Col span={ 5 } className="title">{ langDict.orderWithdrawTitle }: </Col>
                        <Col span={ 12 }>
                            <Row>
                                <Col span={ 24 } className="item">
                                    <Input
                                        allowClear
                                        value={ withdrawFormParams.amounts }
                                        prefix={ <Icon tap><CoinYen /></Icon> }
                                        placeholder={ langDict.withdrawAmountsPlaceholder }
                                        maxLength={ 20 }
                                        onChange={
                                            numberOnChange(value => {
                                                setWithdrawFormParams({
                                                    ...withdrawFormParams,
                                                    amounts: value
                                                })
                                            })
                                        }
                                    />
                                </Col>
                                <Col span={ 24 } className="item">
                                    <Input
                                        allowClear
                                        value={ withdrawFormParams.account }
                                        prefix={ <Icon tap><User /></Icon> }
                                        placeholder={ langDict.withdrawAccountPlaceholder }
                                        maxLength={ 20 }
                                        onChange={
                                            textOnChange(value => {
                                                setWithdrawFormParams({
                                                    ...withdrawFormParams,
                                                    account: value.trim()
                                                })
                                            })
                                        }
                                    />
                                </Col>
                                <Col span={ 24 } className="item">
                                    <Input
                                        allowClear
                                        value={ withdrawFormParams.name }
                                        prefix={ <Icon tap><FileInvoice /></Icon> }
                                        placeholder={ langDict.withdrawNamePlaceholder }
                                        maxLength={ 20 }
                                        onChange={
                                            textOnChange(value => {
                                                setWithdrawFormParams({
                                                    ...withdrawFormParams,
                                                    name: value.trim()
                                                })
                                            })
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col offset={ 4 } span={ 3 } className="flex-end withdraw-button">
                            <Button
                                disabled={ disabled }
                                type="primary"
                                className="submit background-button"
                                loading={ withdrawLoading }
                                onClick={ withdraw }
                            >{ langDict.confirm }</Button>
                        </Col>
                    </Row>
                    : ''
            }
            <p className="recharge-title">{ langDict.rechargeTitle }</p>
            <ul className="recharge" dangerouslySetInnerHTML={{ __html: langDict.recharge }} />
            {
                configs !== null
                    ? <ul
                        className="recharge"
                        dangerouslySetInnerHTML={
                            {
                                __html: pickLangKey(
                                    lang.state,
                                    configs.rechargeInstructions
                                ).split('\n').filter(
                                    item => item.trim() !== ''
                                ).map(
                                    item => `<li>${ item }</li>`
                                ).join('')
                            }
                        }
                    />
                    : ''
            }
        </div>
    </div>
}

export default Main