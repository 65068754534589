import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Row, Col, Empty } from 'antd'
import { defaultLimit } from '#constants/appoint'
import Loading from '#components/loading'
import Pagination from '#components/page'
import Item from '#components/document/item'
import useFetchState from '#repositories/models'
import { type Params } from '#repositories/types/request'
import { type ListItem, type ListResp, listWidthTotal } from '#repositories/apis/documents'
import { Userinfo } from '#repositories/models/recoil-state'
import { type FavoritesProps } from '#pages/profile/model'
import { isEmpty, toNumber } from '#utils/functions'
import { scrollToTop } from '#utils/events'
import { RouteProfile } from '#utils/router'

const Main: React.FC<FavoritesProps> = (props) => {
    const history = useHistory()
    const userinfo = new Userinfo().shared()
    // 页码
    const [ page, setPage ] = useFetchState(
        props.match.params.page !== undefined && !isEmpty(props.match.params.page)
            ? toNumber(props.match.params.page, 1)
            : 1
    )
    // 每页条数
    const [ limit, setLimit ] = useFetchState(
        props.match.params.limit !== undefined && !isEmpty(props.match.params.limit)
            ? toNumber(props.match.params.limit, defaultLimit)
            : defaultLimit
    )
    const [ loading, setLoading ] = useFetchState(false)
    const [ total, setTotal ] = useFetchState(0)
    const [ list, setList ] = useFetchState<ListItem[]>([])

    useEffect(
        () => {
            const params: Params = { limit, page, conditions: [] }
            if (userinfo === null) {
                return
            }
            params.conditions = [
                { column: 'extension.favorites', value: userinfo.id }
            ]

            void listWidthTotal(params).then(
                res => {
                    const data = res.data as ListResp
                    setList(data.list)
                    setTotal(data.total)
                }
            ).then(
                () => {
                    setTimeout(
                        () => {
                            setLoading(false)
                        },
                        1000
                    )
                }
            )
        },
        [ userinfo, page, limit ]
    )

    return loading
        ? <Loading />
        : <div className={ `favorites-container${ list.length > 0 ? '' : ' empty-box' }` }>
            {
                list.length > 0
                    ? <>
                        <div className="favorites-list">{
                            list.map(
                                (item, key) => <div className="item" key={ key }>
                                    <Row>
                                        <Col span="24">
                                            <Row>
                                                <Item
                                                    className="product"
                                                    item={ item }
                                                    layout={ { left: 6, right: 18 } }
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        list.length - 1 !== key
                                            ? <Divider className="divider" plain />
                                            : ''
                                    }
                                </div>
                            )
                        }</div>
                        <Pagination
                            className="pagination"
                            total={ total }
                            callback={
                                (current, pageSize) => {
                                    scrollToTop(200, () => {
                                        history.push(`${
                                            RouteProfile.favorites
                                        }/${ current }/${ pageSize }`)
                                        setPage(current)
                                        setLimit(pageSize)
                                    })
                                }
                            }
                            page={ page }
                            pageSize={ limit }
                        />
                    </>
                    : <Empty />
            }
        </div>
}

export default Main