import React from 'react'
import ReactDOM from 'react-dom/client'
import { renderRoutes, type RouteConfig } from 'react-router-config'
import { Switch, Route, BrowserRouter } from 'react-router-dom'

import { RecoilRoot } from 'recoil'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'animate.css'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'

import '#assets/css/common.css'
import '#assets/scss/base.scss'

import routes from '#routers'
import reportWebVitals from './reportWebVitals'
import { isChrome87OrEarlier } from '#utils/functions'

console.log(process.env.REACT_APP_ENV)
console.log(process.env.REACT_APP_BUILD_DATE)

if (isChrome87OrEarlier()) {
    alert(`您好，您的浏览器版本过低，无法兼容，请您使用最新版本的火狐浏览器或谷歌浏览器，带给您更加流畅安全的访问体验
请访问 https://www.firefox.com.cn 下载最新版本火狐浏览器或者
访问 https://www.google.cn/chrome 下载最新版本谷歌浏览器`)
}

NProgress.configure({ easing: 'ease', showSpinner: false })

ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
).render(
    <BrowserRouter>
        <Route>
            <RecoilRoot>
                <Switch>{ renderRoutes(routes as RouteConfig[]) }</Switch>
            </RecoilRoot>
        </Route>
    </BrowserRouter>
)

reportWebVitals()
