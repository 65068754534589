import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Checkbox, Form, Input } from 'antd'
import localization, { type LT } from '#utils/localization'
import useFetchState from '#repositories/models'
import { login, type LoginRespType } from '#repositories/apis/user'
import { Configs, Language, Profile, SignInPopup, SignInPopupType } from '#repositories/models/recoil-state'
import Location from '#components/location'
import Icon from '#/components/icon'
import Logo from '#components/logo'
import { getConfigs, loginCall, type LoginFieldType, passwordRule, type SignInProps, usernameRule, wrapperCol } from './model'
import { ReactComponent as Lock } from '#assets/svg/lock.svg'
import { ReactComponent as User } from '#assets/svg/user.svg'

const { Item } = Form

const Main: React.FC<SignInProps> = ({ popup, callback }) => {
    const profile = new Profile()
    const configs = new Configs()
    const signInPopup = new SignInPopup()
    const formRef = useRef<LoginFieldType>()
    const history = useHistory()
    const lang = new Language()
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // 本地化
    const [ LangDict, setLangDict ] = useFetchState<LT>({})

    const submit = (values: LoginFieldType): void => {
        formRef.current = values
        setLoading(true)
        void login<any>({
            username: formRef.current?.username ?? '',
            password: formRef.current?.password ?? '',
            remember: formRef.current?.remember ?? false
        }).then(
            res => {
                const data = res.data as LoginRespType
                loginCall({ history, popup, data, profile, callback })
            }
        ).finally(
            () => {
                setLoading(false)
            }
        )
    }

    useEffect(
        () => {
            getConfigs(configs)
        },
        []
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'login',
                        'usernamePlaceholder',
                        'pwdPlaceholder',
                        'loginRemember',
                        'loginSubmit1',
                        'formColumnsBad',
                        'forgetPwd',
                        'usernameFormRuleMsg',
                        'registerLinkPrefix',
                        'passwordFormRuleMsg',
                        'registerLink'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <div className={ popup ? 'popup-center-box' : 'wh100 relative' }>
        <div className={ 'fade-in signin-box' + (popup ? '' : ' abs-center') }>
            <Logo />
            <Form
                name="basic"
                onFinish={ submit }
                autoComplete="on"
            >
                <h3 className="font-center header">{ LangDict.login }</h3>
                <Item<LoginFieldType>
                    name="username"
                    rules={ usernameRule(LangDict.usernameFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input
                        className="username"
                        allowClear
                        prefix={ <Icon tap><User /></Icon> }
                        placeholder={ LangDict.usernamePlaceholder }
                    />
                </Item>
                <Item<LoginFieldType>
                    name="password"
                    rules={ passwordRule(LangDict.passwordFormRuleMsg) }
                    wrapperCol={ wrapperCol }
                >
                    <Input.Password
                        className="password"
                        prefix={ <Icon><Lock /></Icon> }
                        visibilityToggle={ { visible: false } }
                        placeholder={ LangDict.pwdPlaceholder }
                    />
                </Item>
                <Item wrapperCol={ wrapperCol } className="mb0">
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="submit background-button"
                        loading={ loading }
                    >{ LangDict.loginSubmit1 }</Button>
                </Item>
                <Item<LoginFieldType> name="remember" valuePropName="checked" wrapperCol={ wrapperCol } className="mb0 convenient-ext">
                    <div className="wh100 flex">
                        {
                            popup
                                ? <>
                                    <span
                                        onClick={
                                            () => {
                                                signInPopup.set(SignInPopupType.findPassword)
                                            }
                                        }
                                        className="link-color"
                                    >{ LangDict.forgetPwd }</span>
                                    <span>&nbsp;&nbsp;{ LangDict.registerLinkPrefix }</span>
                                    <span
                                        onClick={
                                            () => {
                                                signInPopup.set(SignInPopupType.register)
                                            }
                                        }
                                        className="link-color blue register cursor-pointer"
                                    >{ LangDict.registerLink }</span>
                                </>
                                : <>
                                    <Location to="/find-password" className="link-color">{ LangDict.forgetPwd }</Location>
                                    <span>&nbsp;&nbsp;{ LangDict.registerLinkPrefix }</span>
                                    <Location to="/register" className="link-color blue register">{ LangDict.registerLink }</Location>
                                </>
                        }
                        <Checkbox
                            className="color-cb remember"
                        >{ LangDict.loginRemember }</Checkbox>
                    </div>
                </Item>
            </Form>
        </div>
    </div>
}

export default Main