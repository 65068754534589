import React, { useEffect } from 'react'
import Loading from '#components/loading'
import { Configs } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import GameBoxCtl from '#components/game-box-ctl'
import { type OrdersProps } from '#pages/profile/model'
import PurchaseHistory from '#pages/profile/purchase-history'
import { OrderType } from '#constants/appoint'

const Main: React.FC<OrdersProps> = (props) => {
    const configs = new Configs()
    const [ loading, setLoading ] = useFetchState(true)

    useEffect(
        () => {
            if (configs.state !== null) {
                setLoading(false)
            }
        },
        [ configs.state ]
    )

    return loading
        ? <Loading />
        : <div className="profile-game-box-container">
            <GameBoxCtl configs={ configs.state } />
            <p className="download">
                <span
                    onClick={
                        () => {
                            window.open(configs.state.desktopAppDownloadUrlLanzou)
                        }
                    }
                >点击下载游戏盒子</span>
            </p>
            <PurchaseHistory { ...props } hideEmpty={ true } orderTypes={ [ OrderType.appSecretKey ] } />
        </div>
}

export default Main