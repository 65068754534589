import { type AxiosResponse } from 'axios'
import { service, proxy, route } from '#utils/axios'
import { type LangType, type SevConfLangItem } from '#repositories/types/lang'
import { type LangItem, type SliderItem } from '#repositories/types/foundation'

export enum SitePopupRule {
    // 每台每台设备只弹一次
    equipment,
    // 每天弹
    day
}

export interface Notice {
    content: LangItem
    url: string
    target: string
}

export interface SiteLogo {
    sm: string
    l: string
    xl: string
    xxl: string
}

export interface FooterCode {
    title: LangItem
    url: string
}

export interface friendlyLink {
    title: LangItem
    url: string
}

export interface VipFAQ {
    title: LangItem
    content: LangItem
}

export interface ConfigsType {
    footerCodes: FooterCode[]
    vipFAQ: VipFAQ[]
    friendlyLinks: friendlyLink[]
    footerStatement: LangItem
    homeSlider: SliderItem[]
    keywords: LangType[]
    subscriptionTitle: LangType
    slogan: LangType
    sloganSub: LangType
    subscriptionDescription: LangType
    orderQueryAnnouncements: LangType
    memberBannedHint: LangType
    substationInstructions: LangType
    rechargeInstructions: LangType
    siteContactQQ: string
    siteContactWechat: string
    notice: Notice[]
    serverUrl: string
    buildWebsiteUrl: string
    qqGroup: string
    siteDescription: string
    siteKeywords: string
    siteLogo: SiteLogo
    siteTitle: string
    homeBanner: string
    homeBannerVideo: string
    productsNumColumns: number
    withdrawalStatus: boolean
    downloadLimitState: boolean
    downloadLimitVip: number
    downloadLimitPermanent: number
    downloadLimitFree: number
    withdrawalAmountLimit: number
    cardNumUnitTypes: SevConfLangItem[]
    userLevelTypes: SevConfLangItem[]
    networkDiskTypes: SevConfLangItem[]
    sitePopup: LangType
    sitePopupRule: SitePopupRule
    sitePopupBackgroundImage: string
    sitePopupTitleColor: string
    sitePopupTitleSize: number
    sitePopupContentColor: string
    sitePopupContentSize: number
    shareAward: number
    hideFreeGames: boolean
    hideVipGames: boolean
    desktopAppSecretKeyPrice: number
    desktopAppSecretKeyDiscountPrice: number
    desktopAppSecretKeyDiscountExpire: number
    desktopAppWebChannelState: boolean
    desktopAppDownloadUrlBaidu: string
    desktopAppDownloadUrlLanzou: string

    // 外链状态
    externalLinkState: boolean
    // 商品外部链接
    externalLinkGoods: string
    // 充值外部链接
    externalLinkRecharge: string
    // 会员外部链接
    externalLinkUpgrade: string
    // 游戏盒子外部链接
    externalLinkGameBox: string
}

export const configs = async <T>(): Promise<AxiosResponse<T>> => {
    return await service({
        url: proxy(route.base, '/v1/configs'),
        method: 'get'
    })
}
