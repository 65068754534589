import { PayState } from '#constants/appoint'
import { type ReactElement } from 'react'
import { type LT } from '#utils/localization'
import { type OrderRowResp } from '#repositories/apis/orders'

export interface RowProps {
    match: {
        params: {
            uniqueId: string
        }
    }
    data?: OrderRowResp
    header?: ReactElement
}

export const makePayState = (state: PayState, langDict: LT): string => {
    switch (state) {
        case PayState.wait:
            return langDict.orderPayStateWait

        case PayState.success:
            return langDict.orderPayStateSuccess

        case PayState.payCancel:
            return langDict.orderPayStatePayCancel

        case PayState.payAbnormal:
            return langDict.orderPayStatePayAbnormal

        case PayState.refund:
            return langDict.orderPayStateRefund

        case PayState.notPay:
            return langDict.orderPayStateNotPay

        case PayState.closed:
            return langDict.orderPayStateClosed

        case PayState.revoked:
            return langDict.orderPayStateRevoked

        case PayState.userPaying:
            return langDict.orderPayStateUserPaying

        case PayState.payError:
            return langDict.orderPayStateUnknown

        default:
            return ''
    }
}