import React, { useEffect, useRef } from 'react'
import { Col, Row, Input } from 'antd'
import { AllGameClass, Configs, Dictionary, GameClass, Language, Profile, SignInPopupVisible } from '#repositories/models/recoil-state'
import { type PopularGroups, popularGroupWithDate, type ListItem, listWithLabelIds, documentsFeaturedGroup, type DocumentsFeaturedGroupResp, DocumentsFeaturedGroupType, PopularGroupsKey, type ListResp } from '#repositories/apis/documents'
import useFetchState from '#repositories/models'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import { type XPagination } from '#repositories/types/foundation'
import localization, { type LT, pickLangKey } from '#utils/localization'
import { isEmpty, throttle } from '#utils/functions'
import { documentTitle } from '#utils/configs'
import { productDetailRoutePath, withCategoryIdProductsRoutePath, withPriceProductsRoutePath, withSortProductsRoutePath } from '#utils/router'
import Carousel, { CategoryTabs } from '#components/carousel'
import Location from '#components/location'
import Loading from '#components/loading'
import Image from '#components/image'
import Notice from '#components/notice'
import Icon from '#components/icon'
import { ProductItem, ProductItemSize } from '#components/document/item'
import VideoCodeBox from '#components/video-code-box'
import Pagination from '#components/page'
import { Modals, type ModalsProps } from '#components/hint'
import Search from '#components/search'
import { ReactComponent as IconSearch } from '#assets/svg/search.svg'
import { ReactComponent as MessageChatBot } from '#assets/svg/message-chatbot.svg'
import { ReactComponent as Diamond } from '#assets/svg/diamond.svg'
import { ReactComponent as CoinYen } from '#assets/svg/coin-yen.svg'
import { ReactComponent as VipLine } from '#assets/svg/vip-line.svg'
import { ReactComponent as Clock } from '#assets/svg/clock.svg'
import { ReactComponent as Flame } from '#assets/svg/flame.svg'
import { ReactComponent as Free } from '#assets/svg/free.svg'
import { FilterPrice, pickColor, SortType } from '#constants/appoint'

const siteGuideIcons: Record<number, React.ReactNode> = {
    1: <MessageChatBot />,
    2: <Diamond />,
    3: <CoinYen />,
    4: <VipLine />
}

interface MainProps {
    loginSign?: boolean
}

const Main: React.FC<MainProps> = ({ loginSign }) => {
    const profile = new Profile()
    const signInVisible = new SignInPopupVisible()
    // 字典
    const dictionary = new Dictionary()
    const dictionaryState = dictionary.shared()
    // 系统配置
    const configs = new Configs()
    const configsState = configs.shared()
    // 游戏分类分组
    const [ tabCategories, setTabCategories ] = useFetchState<DictionaryItem[]>([])
    const [ tabGamesLoading, setTabGamesLoading ] = useFetchState<boolean>(true)
    const [ tabGames, setTabGames ] = useFetchState<ListItem[]>([])
    const [ tabActive, setTabActive ] = useFetchState<DictionaryItem | null>(null)
    const fetchCategoryGroupGames = (item: DictionaryItem): void => {
        setTabGamesLoading(true)
        setTabActive(item)
        // 获取商品
        void listWithLabelIds([ item.id ], groupGamesPage.current, groupGamesPagination.limit).then(
            res => {
                const data = res.data as ListResp
                doSetGroupGamesPagination({ ...groupGamesPagination, page: groupGamesPage.current, total: data.total }, true)
                setTabGames(data.list)
                setTabGamesLoading(false)
            }
        )
    }
    const groupGamesPage = useRef(1)
    const [ groupGamesPagination, setGroupGamesPagination ] = useFetchState<XPagination>({
        limit: 8,
        page: 1,
        total: 0
    })
    const doSetGroupGamesPagination = (data: XPagination, onlySetState = false): void => {
        groupGamesPage.current = data.page
        setGroupGamesPagination(data)

        if (tabActive !== null && !onlySetState) {
            throttle(
                () => {
                    fetchCategoryGroupGames(tabActive)
                },
                300,
                'popular-games'
            )
        }
    }
    // 商品列表
    const [ list, setList ] = useFetchState<DocumentsFeaturedGroupResp>([])
    // 游戏分类
    const gameClass = new GameClass()
    const gameClassMaps = gameClass.shared()
    const allGameClass = new AllGameClass()
    const allGameClassMaps = allGameClass.shared()
    // 引导
    const [ siteGuideUrls, setSiteGuideUrls ] = useFetchState<DictionaryItem[]>([])
    // 列表加载
    const [ listLoading, setListLoading ] = useFetchState(true)
    // 时间分组流行游戏
    const [ popularGroupWithDateLoading, setPopularGroupWithDateLoading ] = useFetchState(true)
    const [ popularGroupActiveKey, setPopularGroupActiveKey ] = useFetchState<PopularGroupsKey>(PopularGroupsKey.all)
    const [ popularGroupWithDateMaps, setPopularGroupWithDateMaps ] = useFetchState<PopularGroups>({
        thisWeekend: [],
        thisMonth: [],
        all: []
    })
    const [ popularGroupWithDateList, setPopularGroupWithDateList ] = useFetchState<ListItem[]>([])
    const doSetPopularGroupWithDateList = (key: PopularGroupsKey): void => {
        setPopularGroupActiveKey(key)
        setPopularGroupWithDateList(popularGroupWithDateMaps[ key ])
    }
    // language
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    // 分组分类标题
    const convDocumentsFeaturedGroupType = (langDict: LT, type: DocumentsFeaturedGroupType): string => {
        if (type === DocumentsFeaturedGroupType.popular) {
            return langDict.popularGroupGames
        }
        if (type === DocumentsFeaturedGroupType.latest) {
            return langDict.latestGroupGames
        }
        if (type === DocumentsFeaturedGroupType.free) {
            return langDict.freeGroupGames
        }

        return ''
    }
    // 首页标签分组
    const getProductsWithLabels = (): void => {
        if (dictionaryState === null) {
            return
        }

        // 获取首页游戏分组
        void documentsFeaturedGroup().then(
            res => {
                setList(res.data as DocumentsFeaturedGroupResp)
            }
        )
    }
    // 热门游戏
    const popularVideoActiveLeaveDiff = useRef(true)
    const popularActiveLeaveDiff = useRef(true)
    const [ popularActiveKey, setPopularActiveKey ] = useFetchState(0)
    const [ popularActiveItem, setPopularActiveItem ] = useFetchState<ListItem | null>(null)
    const realLabels = (labelIds: number[]): number[] => {
        return labelIds.filter(
            item => !isEmpty(gameClassMaps[ item ])
        )
    }
    const onMouseEnter = (item: ListItem, key: number): void => {
        setPopularActiveKey(key)
        popularVideoActiveLeaveDiff.current = false
        popularActiveLeaveDiff.current = false
        throttle(
            () => {
                if (!popularActiveLeaveDiff.current) {
                    if (item.videoCode.trim() === '') {
                        setPopularActiveItem(null)
                        return
                    }

                    setPopularActiveItem(item)
                }
            },
            300,
            'autoPlayVideo'
        )
    }
    const onMouseLeave = (): void => {
        setTimeout(
            () => {
                if (popularVideoActiveLeaveDiff.current) {
                    return
                }

                setPopularActiveItem(null)
                popularActiveLeaveDiff.current = true
                popularVideoActiveLeaveDiff.current = false
            },
            1000
        )
    }
    const [ searchVisible, setSearchVisible ] = useFetchState(false)
    const [ defaultKeyword, setDefaultKeyword ] = useFetchState('')
    const searchModalProps: ModalsProps = {
        visible: searchVisible,
        setVisible: setSearchVisible,
        width: 1024,
        title: '搜索',
        content: <Search defaultValue={ defaultKeyword } setVisible={ setSearchVisible } />,
        maskClosable: true,
        centered: false,
        footer: null
    }

    useEffect(
        () => {
            if (loginSign === true && profile.state === null) {
                signInVisible.set(true)
            }
        },
        [ loginSign ]
    )

    // 游戏分类分组筛选
    useEffect(
        () => {
            if (dictionaryState === null) {
                return
            }

            if (dictionaryState.siteGuide !== undefined && dictionaryState.siteGuide !== null && dictionaryState.siteGuide?.children.length >= 0) {
                const siteGuideUrls: DictionaryItem[] = []
                dictionaryState.siteGuide.children.forEach(
                    item => {
                        if (siteGuideUrls.length < 5) {
                            siteGuideUrls.push(item)
                        }
                    }
                )

                setSiteGuideUrls(siteGuideUrls)
            }

            if (dictionaryState.gameClass !== undefined && dictionaryState.gameClass !== null && dictionaryState.gameClass?.children.length >= 0) {
                const categories = [ ...dictionaryState.gameClass.children ]
                categories.sort((a, b) => b.sort - a.sort)
                setTabCategories(categories)
            }
        },
        [ lang.state, dictionaryState, gameClassMaps ]
    )

    useEffect(
        () => {
            if (dictionaryState !== null) {
                getProductsWithLabels()
                setListLoading(false)
            }
        },
        [ dictionaryState ]
    )

    useEffect(
        () => {
            if (tabCategories !== null && tabCategories.length > 0) {
                fetchCategoryGroupGames(tabCategories[ 0 ])
            }
        },
        [ tabCategories ]
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'checkMore',
                        'gold',
                        'weekPopularGamesTitle',
                        'monthPopularGamesTitle',
                        'sitePopularGamesTitle',
                        'diskSpaceTitle',
                        'popularSearches',
                        'upgrade',
                        'loginSuccessWidth',
                        'popularGroupGames',
                        'site',
                        'groupUpdateExplain',
                        'groupPopularExplain',
                        'latestGroupGames',
                        'freeGroupGames',
                        'searchInputPlaceholder'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    useEffect(
        () => {
            documentTitle()

            void popularGroupWithDate().then(
                res => {
                    const data = res.data as PopularGroups
                    const all = isEmpty(data.all) ? [] : data.all
                    setPopularGroupWithDateMaps({
                        thisWeekend: isEmpty(data.thisWeekend) ? [] : data.thisWeekend,
                        thisMonth: isEmpty(data.thisMonth) ? [] : data.thisMonth,
                        all
                    })
                    setPopularGroupWithDateList(all)
                }
            ).finally(
                () => {
                    setPopularGroupWithDateLoading(false)
                }
            )
        },
        []
    )

    return <div className="home-container">
        { searchVisible ? <Modals { ...searchModalProps } /> : '' }
        {
            configsState === null
                ? ''
                : isEmpty(configsState.homeBanner) && isEmpty(configsState.homeBannerVideo)
                    ? <Carousel list={ configsState.homeSlider } />
                    : <section className="banner hsp">
                        <div className="search-ctl">
                            <h1>{ pickLangKey(lang.state, configsState.slogan) }</h1>
                            <h3>{ pickLangKey(lang.state, configsState.sloganSub) }</h3>
                            <Input
                                className="input"
                                prefix={ <Icon><IconSearch /></Icon> }
                                placeholder={ langDict.searchInputPlaceholder }
                                onClick={
                                    () => {
                                        setDefaultKeyword('')
                                        setSearchVisible(true)
                                    }
                                }
                            />
                            <div className="popular-search">
                                { langDict.popularSearches }:
                                <div className="items">{
                                    configsState.keywords.map(
                                        (item, key) => <span
                                            key={ key }
                                            onClick={
                                                () => {
                                                    setDefaultKeyword(pickLangKey(lang.state, item))
                                                    setSearchVisible(true)
                                                }
                                            }
                                        >
                                            { pickLangKey(lang.state, item) }
                                            { configsState.keywords.length - 1 === key ? '' : '，' }
                                        </span>
                                    )
                                }</div>
                            </div>
                        </div>
                        {
                            isEmpty(configsState.homeBannerVideo)
                                ? <Image src={ configsState.homeBanner } className="fill margin0auto" />
                                : <VideoCodeBox autoPlay={ true } loop={ true } hideControls={ true } className="bv" code={ configsState.homeBannerVideo } />
                        }
                    </section>
        }
        {
            siteGuideUrls !== null && siteGuideUrls.length > 0
                ? <section className="categories site-guide">
                    <div className="box">{
                        siteGuideUrls.map(
                            (item, key) => <Location
                                target={ item.target }
                                to={ item.strVal }
                                key={ key }
                                className="item"
                            >
                                <div className="detail">
                                    <Icon tap className="i-6x">{ siteGuideIcons[ item.digitalVal ] }</Icon>
                                    <div className="font">
                                        <h1 className="ellipsis-1">{ pickLangKey(lang.state, item.title) }</h1>
                                    </div>
                                </div>
                            </Location>
                        )
                    }</div>
                </section>
                : ''
        }
        {
            configsState !== null && !isEmpty(configsState.notice)
                ? <div className="notice-box">
                    <Notice list={ configsState.notice } />
                </div>
                : ''
        }
        {
            tabCategories.length > 0
                ? <section className="group-games">
                    <div className="content">
                        <Row className="category-group-box">
                            <Col span={ 16 }>
                                <div className="category-groups tabs-groups">
                                    <CategoryTabs
                                        callback={ fetchCategoryGroupGames }
                                        list={ tabCategories }
                                    />
                                    <div className="category-groups-content">{
                                        tabGamesLoading
                                            ? <Loading />
                                            : tabGames.map(
                                                (item, key) => <ProductItem
                                                    key={ key }
                                                    item={ item }
                                                    className="home"
                                                    size={ ProductItemSize.small }
                                                />
                                            )
                                    }</div>
                                    <Pagination
                                        className="pagination flex-cc"
                                        total={ groupGamesPagination.total }
                                        callback={
                                            page => {
                                                doSetGroupGamesPagination({
                                                    ...groupGamesPagination,
                                                    page
                                                })
                                            }
                                        }
                                        showSizeChanger={ false }
                                        showQuickJumper={ false }
                                        page={ groupGamesPagination.page }
                                        pageSize={ groupGamesPagination.limit }
                                    />
                                    {
                                        popularActiveItem !== null
                                            ? <div
                                                className="popular-video center-pos"
                                                onMouseEnter={ () => { popularVideoActiveLeaveDiff.current = true } }
                                                onMouseLeave={
                                                    () => {
                                                        popularVideoActiveLeaveDiff.current = false
                                                        onMouseLeave()
                                                    }
                                                }
                                            >
                                                <VideoCodeBox autoPlay={ true } className="pv" code={ popularActiveItem.videoCode } />
                                                <p>{ popularActiveItem.title }</p>
                                                {
                                                    realLabels(popularActiveItem.labelIds).length <= 0
                                                        ? ''
                                                        : <div className="goods-ctl labels">
                                                            <ul className="flex">{
                                                                realLabels(popularActiveItem.labelIds).map(
                                                                    (item, key) => isEmpty(gameClassMaps[ item ])
                                                                        ? ''
                                                                        : <li key={ key } style={ { background: pickColor(key) } }>{
                                                                            pickLangKey(lang.state, gameClassMaps[ item ].title ?? {})
                                                                        }</li>
                                                                )
                                                            }</ul>
                                                        </div>
                                                }
                                            </div>
                                            : ''
                                    }
                                </div>
                            </Col>
                            <Col span={ 8 } className="popular">
                                <ul className="tab-title">
                                    <li
                                        className={ popularGroupActiveKey === PopularGroupsKey.thisWeekend ? 'active' : ''}
                                        onMouseOver={
                                            () => {
                                                doSetPopularGroupWithDateList(PopularGroupsKey.thisWeekend)
                                            }
                                        }
                                    >{ langDict.weekPopularGamesTitle }</li>
                                    <li>/</li>
                                    <li
                                        className={ popularGroupActiveKey === PopularGroupsKey.thisMonth ? 'active' : ''}
                                        onMouseOver={
                                            () => {
                                                doSetPopularGroupWithDateList(PopularGroupsKey.thisMonth)
                                            }
                                        }
                                    >{ langDict.monthPopularGamesTitle }</li>
                                    <li>/</li>
                                    <li
                                        className={ popularGroupActiveKey === PopularGroupsKey.all ? 'active' : ''}
                                        onMouseOver={
                                            () => {
                                                doSetPopularGroupWithDateList(PopularGroupsKey.all)
                                            }
                                        }
                                    >{ langDict.sitePopularGamesTitle }</li>
                                </ul>
                                {
                                    popularGroupWithDateLoading
                                        ? <Loading />
                                        : <ul className="list" onMouseLeave={ () => { onMouseLeave() } }>{
                                            popularGroupWithDateList.map(
                                                (item, key) => <li key={ key } onMouseEnter={ () => { onMouseEnter(item, key) } }>
                                                    <span className="num font-center">{ key + 1 }</span>
                                                    <div className={ 'h100 item' + (popularActiveKey === key ? ' active' : '') }>
                                                        <p className="ellipsis-1 title">
                                                            <Location key={ key } to={ productDetailRoutePath(item.uniqueId) }>
                                                                { item.title }
                                                            </Location>
                                                        </p>
                                                        <div className="extension transition-05s">
                                                            <div className="banner">
                                                                <Location key={ key } to={ productDetailRoutePath(item.uniqueId) }>
                                                                    <Image product={ true } className="fill" src={ item.featuredImage } />
                                                                </Location>
                                                            </div>
                                                            <div className="font">
                                                                <div className="categories-list">{
                                                                    item.labelIds.map(
                                                                        (item, key) => isEmpty(allGameClassMaps[ item ])
                                                                            ? ''
                                                                            : <Location key={ key } to={ withCategoryIdProductsRoutePath(item) }>
                                                                                <em>{
                                                                                    isEmpty(allGameClassMaps[ item ]) ? '' : pickLangKey(lang.state, allGameClassMaps[ item ].title)
                                                                                }</em>
                                                                            </Location>
                                                                    )
                                                                }</div>
                                                                {
                                                                    item.extension.diskSpace !== ''
                                                                        ? <span className="disk">
                                                                            { langDict.diskSpaceTitle }
                                                                            <em>{ item.extension.diskSpace }</em>
                                                                        </span>
                                                                        : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }</ul>
                                }
                            </Col>
                        </Row>
                    </div>
                </section>
                : ''
        }
        <section className="products-groups">{
            listLoading
                ? <Loading />
                : (
                    list === null
                        ? ''
                        : list.map(
                            (item, key) => <div key={ key } className="channel-group">
                                <p className="channel-title">
                                    {
                                        item.type === DocumentsFeaturedGroupType.popular
                                            ? <>
                                                <Icon className="i-5x red"><Flame /></Icon>
                                                { convDocumentsFeaturedGroupType(langDict, item.type) }
                                                <span className="ext">{ langDict.groupPopularExplain }</span>
                                                <Location to={ withSortProductsRoutePath(SortType.popularSite) } className="more">{ langDict.checkMore }</Location>
                                            </>
                                            : ''
                                    }
                                    {
                                        item.type === DocumentsFeaturedGroupType.latest
                                            ? <>
                                                <Icon className="i-5x blue"><Clock /></Icon>
                                                { convDocumentsFeaturedGroupType(langDict, item.type) }
                                                <span className="ext">{ langDict.groupUpdateExplain }</span>
                                                <Location to={ withSortProductsRoutePath(SortType.updated) } className="more">{ langDict.checkMore }</Location>
                                            </>
                                            : ''
                                    }
                                    {
                                        item.type === DocumentsFeaturedGroupType.free
                                            ? <>
                                                <Icon className="i-5x blue"><Free /></Icon>
                                                { convDocumentsFeaturedGroupType(langDict, item.type) }
                                                <Location to={ withPriceProductsRoutePath(FilterPrice.free) } className="more">{ langDict.checkMore }</Location>
                                            </>
                                            : ''
                                    }
                                </p>
                                <Row gutter={ 16 }>{
                                    item.list.map(
                                        (item, key) => <Col span={ 6 } key={ key }>
                                            <ProductItem key={ key } item={ item } />
                                        </Col>
                                    )
                                }</Row>
                            </div>
                        )
                )
        }</section>
    </div>
}

export default Main
