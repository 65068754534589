import { copyToClipboard } from '#utils/functions'
import React, { useEffect } from 'react'
import { Tooltip } from 'antd'
import { MessageType, MMessage } from '#components/hint'
import { Language } from '#repositories/models/recoil-state'
import useFetchState from '#repositories/models'
import localization, { type LT } from '#utils/localization'

interface PropsType {
    text: string
    children: React.ReactNode
}

const Main: React.FC<PropsType> = ({ children, text }) => {
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'copySuccess',
                        'clickToCopy'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return <Tooltip
        title={ langDict.clickToCopy }
        arrow={ true }
    >
        <span className="cursor-pointer" onClick={
            () => {
                copyToClipboard(text)
                MMessage({
                    message: langDict.copySuccess,
                    type: MessageType.success
                })
            }
        }>{ children }</span>
    </Tooltip>
}

export default Main