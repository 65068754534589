import React from 'react'
import axios, { type AxiosResponse, type AxiosError, type AxiosInstance, type InternalAxiosRequestConfig } from 'axios'
import { start, done } from 'nprogress'
import { d, isEmpty, isObject } from '#utils/functions'
import { LastRoute } from '#repositories/cache/location'
import { apiBehavior, variables } from '#constants/appoint'
import { type TokenType } from '#repositories/types/local-store'
import { type ResponseType } from '#repositories/types/response'
import { MMessage, MessageType, Alert } from '#components/hint'
import { authorization, clearCache, logout, tokenRenewal } from './token'
import { BASE_PROXY, TOOL_PROXY } from '#constants'

/**
 * http错误码定义
 * @param status
 * @param url
 */
const httpCode = (status: number, url: string): string => {
    switch (status) {
        case 400:
            return '请求错误'
        case 401:
            return '未授权，请登录'
        case 403:
            return '拒绝访问'
        case 404:
            return `请求地址出错 ${ url }`
        case 408:
            return '请求超时'
        case 500:
            return '服务器内部错误'
        case 501:
            return '服务未实现'
        case 502:
            return '网关错误'
        case 503:
            return '服务不可用'
        case 504:
            return '网关超时'
        case 505:
            return 'HTTP版本不受支持'
        default:
            return `连接出错(${ status })!`
    }
}

const service: AxiosInstance = axios.create({
    baseURL: '',
    timeout: 100000
})

const { interceptors } = service
const { request, response } = interceptors

// 请求拦截器
request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig<any> => {
        !apiBehavior.disabledLoading && start()
        apiBehavior.disabledLoading = false
        const res = authorization('get')
        let token = ''
        if (!isEmpty(res)) {
            token = (res as TokenType).token
        }

        config.headers.Authorization = token
        config.headers.Version = process.env.REACT_APP_HEADER_VERSION
        config.headers.Language = 'zh'
        if (d()) {
            config.headers[ process.env.REACT_APP_HEADER_DEV_KEY ] = process.env.REACT_APP_HEADER_DEV_VAL
        }

        return config
    },
    (error: any): any => {
        done()
        return Promise.reject(error)
    }
)

// 响应拦截器
response.use(
    ({ data }: AxiosResponse) => {
        done()

        tokenRenewal(data)
        return data
    },
    (error: AxiosError): any => {
        done()

        const { response } = error
        if (isEmpty(response) || response === undefined) {
            return Promise.reject(new Error('parse response error'))
        }

        if (isObject(response.data)) {
            const res = response.data as ResponseType
            tokenRenewal(res)
            if (!variables.loginPopupSign) {
                if (response.status === 403 || response.status === 401) {
                    variables.loginPopupSign = true

                    clearCache()
                    const message = res.message !== '' ? res.message : '登录超时！请重新登录'
                    Alert({
                        message: <div className="weight">{ message }</div>,
                        success: (): void => {
                            logout({
                                locationSign: true,
                                callback: () => LastRoute('set')
                            })
                        }
                    })

                    return Promise.reject(response.data)
                }

                if (!apiBehavior.disabledErrMsg) {
                    if (isObject(response.data)) {
                        MMessage({
                            message: res.message ?? '',
                            type: MessageType.error
                        })
                    } else {
                        MMessage({
                            message: httpCode(response.status, response.config.url ?? ''),
                            type: MessageType.error
                        })
                    }
                }
            }
        } else {
            MMessage({
                message: httpCode(response.status, response.config.url ?? ''),
                type: MessageType.error
            })
        }

        apiBehavior.disabledErrMsg = false
        return Promise.reject(response.data)
    }
)

enum route {
    base,
    tool
}

/**
 * proxy
 * @param type
 * @param dir
 */
const proxy = (type: route, dir: string): string => {
    switch (type) {
        case route.base:
            return `/${ BASE_PROXY }/${ dir.replace(/^\//, '') }`
        case route.tool:
            return `/${ TOOL_PROXY }/${ dir.replace(/^\//, '') }`
        default :
            return dir
    }
}

export { service, proxy, route }
