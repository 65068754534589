import React, { type ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Divider, Row, Col, Empty } from 'antd'
import Loading from '#components/loading'
import Pagination from '#components/page'
import Item from '#components/document/item'
import UpgradeItem from '#components/upgrade/item'
import Location from '#components/location'
import PayType from '#components/pay-type'
import useFetchState from '#repositories/models'
import { list as listApi, type OrderItem, type OrderListResp } from '#repositories/apis/orders'
import { Operator, OperatorOuter, type Params } from '#repositories/types/request'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import { Dictionary, Language } from '#repositories/models/recoil-state'
import { isArray, isEmpty, timestampFormat, toNumber } from '#utils/functions'
import { defaultLimit, OrderType, PayState, SortBy } from '#constants/appoint'
import { scrollToTop } from '#utils/events'
import { orderRowRoutePath, RouteProfile } from '#utils/router'
import localization, { type LT } from '#utils/localization'
import { makePayState } from '#pages/orders/model'
import { type OrdersProps } from '#pages/profile/model'

const Main: React.FC<OrdersProps> = (props) => {
    const history = useHistory()
    const lang = new Language()
    const [ langDict, setLangDict ] = useFetchState<LT>({})
    let uniqueId = ''
    if (props.match.params.uniqueId !== undefined) {
        const _uniqueId = props.match.params.uniqueId.trim()
        if (_uniqueId !== '') {
            uniqueId = _uniqueId
        }
    }
    // 字典
    const dictionary = new Dictionary()
    const dictionaryState = dictionary.shared()
    const [ userLevels, setUseLevels ] = useFetchState<Record<number, DictionaryItem>>({})
    // 页码
    const [ page, setPage ] = useFetchState(
        props.match.params.page !== undefined && !isEmpty(props.match.params.page)
            ? toNumber(props.match.params.page, 1)
            : 1
    )
    // 每页条数
    const [ limit, setLimit ] = useFetchState(
        props.match.params.limit !== undefined && !isEmpty(props.match.params.limit)
            ? toNumber(props.match.params.limit, defaultLimit)
            : defaultLimit
    )
    const [ loading, setLoading ] = useFetchState(false)
    const [ res, setRes ] = useFetchState<OrderListResp>({
        list: [], total: 0, products: {}
    })
    // 订单类型
    const orderTypeTitle = (type: number): ReactElement => {
        if (OrderType.product === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderProductTitle }</p>
        }

        if (OrderType.membershipCard === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderMembershipCardTitle }</p>
        }

        if (OrderType.recharge === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderRechargeTitle }</p>
        }

        if (OrderType.withdraw === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderWithdrawTitle }</p>
        }

        if (OrderType.userLevelUpgrade === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderUserLevelUpgradeTitle }</p>
        }

        if (OrderType.userLevelUpgradePriceDifference === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderUserLevelUpgradeDiffTitle }</p>
        }

        if (OrderType.siteGift === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderGiftTitle }</p>
        }

        if (OrderType.appSecretKey === type) {
            return <p className="order-type-title"><span>{ langDict.orderTypeTitle }: </span>{ langDict.orderAppCodeTitle }</p>
        }

        return <></>
    }
    // 订单详情
    const orderDetail = (price: number, orderItem: OrderItem): ReactElement => <>
        <p><span>{ langDict.price }:</span><span>{ price }</span></p>
        <p><span>{ langDict.payAmount }:</span><span>{ orderItem.actualPrice }</span></p>
        <p><span>{ langDict.orderPayDateTitle }:</span><span>{ orderItem.payDate }</span></p>
        <p><span>{ langDict.payMethod }:</span><span><PayType type={ orderItem.payType } /></span></p>
        <p><span>{ langDict.orderNumber }:</span><span>{ orderItem.orderId }</span></p>
        <p><span>{ langDict.email }:</span><span>{ orderItem.email }</span></p>
    </>

    useEffect(
        () => {
            const params: Params = {
                limit,
                page,
                conditions: [],
                sortBy: [
                    {
                        column: 'createdAt',
                        value: SortBy.desc
                    }
                ]
            }
            if (props.match.params.uniqueId !== undefined) {
                if (uniqueId !== '') {
                    params.conditions = [
                        { column: 'uniqueId', value: uniqueId, 'outer-opr': OperatorOuter.or },
                        { column: 'orderId', value: uniqueId }
                    ]
                }
            }

            if (props.orderTypes !== undefined && isArray(props.orderTypes) && props.orderTypes.length > 0) {
                if (params.conditions === undefined) {
                    params.conditions = []
                }

                params.conditions.push({ column: 'type', values: props.orderTypes })
            }

            params.conditions?.push({ column: 'type', operator: Operator.notin, values: [ 10, 11 ] })
            void listApi(params).then(
                res => {
                    setRes(res.data as OrderListResp)
                }
            ).then(
                () => {
                    setLoading(false)
                }
            )
        },
        [ props.match.params.limit, props.match.params.page, props.match.params.uniqueId ]
    )

    useEffect(
        () => {
            if (dictionaryState === null) {
                return
            }

            if (dictionaryState.userLevel !== undefined && dictionaryState.userLevel !== null && dictionaryState.userLevel.children.length > 0) {
                const userLevels: Record<number, DictionaryItem> = {}
                dictionaryState.userLevel.children.forEach(
                    item => {
                        userLevels[ item.id ] = item
                    }
                )
                setUseLevels(userLevels)
            }
        },
        [ dictionaryState ]
    )

    useEffect(
        () => {
            setLangDict(
                localization({
                    lang: lang.state,
                    keys: [
                        'price',
                        'viewDetails',
                        'email',
                        'gold',
                        'payMethod',
                        'orderNumber',
                        'orderPayDateTitle',
                        'payAmount',
                        'profileMemberCenter',
                        'profileAccountInformation',
                        'profileBalance',
                        'profileMembership',
                        'profilePurchaseHistory',
                        'profileFavorites',
                        'profileInformation',
                        'profileEmailBinding',
                        'orderProductTitle',
                        'orderMembershipCardTitle',
                        'orderRechargeTitle',
                        'orderWithdrawTitle',
                        'orderAppCodeTitle',
                        'orderUserLevelUpgradeDiffTitle',
                        'orderGiftTitle',
                        'orderUserLevelUpgradeTitle',
                        'orderTypeTitle',
                        'orderPayStateWait',
                        'orderPayStateSuccess',
                        'orderPayStatePayCancel',
                        'orderPayStatePayAbnormal',
                        'orderPayStateRefund',
                        'orderPayStateNotPay',
                        'orderPayStateClosed',
                        'orderPayStateRevoked',
                        'orderPayStateUserPaying',
                        'orderPayStatePayError',
                        'orderNumber',
                        'orderMakeCreatedTitle',
                        'orderStatusTitle',
                        'profilePasswordSettings'
                    ]
                })
            )
        },
        [ lang.state ]
    )

    return loading
        ? <Loading />
        : <div className="order-container">
            {
                res.total <= 0
                    ? (props.hideEmpty === true ? '' : <div className="wh100 flex-cc"><Empty /></div>)
                    : <div className="order-list">{
                        res.list.map(
                            (item, key) => <div className="item" key={ key }>
                                <Row>
                                    <Col span="12">
                                        { orderTypeTitle(item.type) }
                                        {
                                            (item.type === OrderType.product || item.type === OrderType.membershipCard) && !isEmpty(res.products[ item.relationId ])
                                                ? <Row>
                                                    <Item
                                                        className="product"
                                                        hideDate={ true }
                                                        item={ res.products[ item.relationId ] }
                                                        layout={ { left: 10, right: 14 } }
                                                    />
                                                </Row>
                                                : ''
                                        }
                                        {
                                            item.type === OrderType.userLevelUpgrade && !isEmpty(userLevels[ toNumber(item.relationId) ])
                                                ? <UpgradeItem
                                                    readonly={ true }
                                                    orderView={ true }
                                                    hideContent={ true }
                                                    item={ userLevels[ toNumber(item.relationId) ] }
                                                />
                                                : ''
                                        }
                                    </Col>
                                    <Col span="12" className="order-item-box">
                                        {
                                            ((item.type === OrderType.product || item.type === OrderType.membershipCard) && !isEmpty(res.products[ item.relationId ])) ||
                                            (item.type === OrderType.userLevelUpgrade && userLevels[ toNumber(item.relationId) ] !== null)
                                                ? <div className="order-item">
                                                    {
                                                        (item.type === OrderType.product || item.type === OrderType.membershipCard) && !isEmpty(res.products[ item.relationId ])
                                                            ? orderDetail(isEmpty(res.products[ item.relationId ]) ? 0 : res.products[ item.relationId ].price, item)
                                                            : ''
                                                    }
                                                    {
                                                        item.type === OrderType.userLevelUpgrade && userLevels[ toNumber(item.relationId) ] !== null
                                                            ? orderDetail(isEmpty(userLevels[ toNumber(item.relationId) ]) ? 0 : userLevels[ toNumber(item.relationId) ].price, item)
                                                            : ''
                                                    }
                                                </div>
                                                : ''
                                        }
                                    </Col>
                                    <Col span="24" className="order-footer">
                                        <p><span>{ langDict.orderNumber }:</span><span>{ item.uniqueId }</span></p>
                                        <p><span>{ langDict.orderMakeCreatedTitle }:</span><span>{ timestampFormat(item.createdAt) }</span></p>
                                        <p>
                                            <span>{ langDict.orderStatusTitle }:</span>
                                            <span className={ item.status === PayState.success ? 'success' : (item.status === PayState.wait ? 'wait' : '') }>
                                                { makePayState(item.status, langDict) }
                                            </span>
                                        </p>
                                        <Location to={ orderRowRoutePath(item.uniqueId) } className="view-details">
                                            { langDict.viewDetails } &gt;&gt;
                                        </Location>
                                    </Col>
                                </Row>
                                {
                                    res.list.length - 1 !== key
                                        ? <Divider className="divider" plain />
                                        : ''
                                }
                            </div>
                        )
                    }</div>
            }
            <Pagination
                className="pagination"
                total={ res.total }
                callback={
                    (current, pageSize) => {
                        scrollToTop(200, () => {
                            history.push(`${
                                RouteProfile.purchaseHistory
                            }/${ current }/${ pageSize }${ uniqueId === '' ? '' : '/' + uniqueId }`)
                            setPage(current)
                            setLimit(pageSize)
                        })
                    }
                }
                page={ page }
                pageSize={ limit }
            />
        </div>
}

export default Main