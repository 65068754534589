import { GetYearMonth, isEmpty, throttle } from '#utils/functions'
import { productDetailRoutePath, type ProductsFilterParams } from '#utils/router'
import { localization as localizationDef } from '#utils/localization/dictionary'
import { type Condition, Operator, type SortBy } from '#repositories/types/request'
import { type Item, type ListItem, type ListResp, listWidthTotal } from '#repositories/apis/documents'
import { type PageProps } from '#repositories/types/foundation'
import { type DictionaryItem } from '#repositories/apis/dictionaries'
import { FreeGameCategoryId } from '#repositories/apis/dictionaries'
import { DocumentType, FilterPrice, ProductLabelTypes, SortBy as SortByEnum, SortType, UserLevelType } from '#constants/appoint'

export interface MainProps extends PageProps {
    match: {
        params: {
            page?: number
            limit?: number
            filters?: string
        }
    }
}

export interface RowProps {
    match: {
        params: {
            uniqueId: string
            secret?: string
        }
    }
}

interface FetchProducts {
    routerFilterParams: ProductsFilterParams
    setLoading: (data: boolean) => void
    setList: (data: ListItem[]) => void
    setTotal: (data: number) => void
    hideFreeGames: boolean
    hideVipGames: boolean

    limit: number
    page: number
}

export const fetchProducts = (
    {
        routerFilterParams,
        setLoading,
        setList,
        setTotal,
        limit,
        page,
        hideFreeGames,
        hideVipGames
    }: FetchProducts
): void => {
    setLoading(true)
    throttle(
        (): void => {
            const conditions: Condition[] = [ { column: 'type', value: DocumentType.product } ]
            const sortBy: SortBy[] = []
            // 分类筛选
            if (routerFilterParams.categoryId !== undefined && routerFilterParams.categoryId > 0) {
                if (!(hideFreeGames && routerFilterParams.categoryId === FreeGameCategoryId)) {
                    conditions.push({
                        column: 'labelIds',
                        value: routerFilterParams.categoryId
                    })
                }
            }
            // 价格
            if (routerFilterParams.exclusiveType !== undefined && routerFilterParams.exclusiveType > 0) {
                if (routerFilterParams.exclusiveType === FilterPrice.free && !hideFreeGames) {
                    // 免费专区
                    conditions.push({
                        column: 'price',
                        value: 0
                    })
                    conditions.push({
                        column: 'exclusiveType',
                        value: UserLevelType.free
                    })
                } else if (routerFilterParams.exclusiveType === FilterPrice.permanent && !hideVipGames) {
                    // vip限定
                    conditions.push({
                        column: 'exclusiveType',
                        value: UserLevelType.permanent
                    })
                } else if (routerFilterParams.exclusiveType === FilterPrice.paid) {
                    // 付费游戏
                    conditions.push({
                        column: 'price',
                        value: 0,
                        operator: Operator.gt
                    })
                }
            }

            // 排序
            let random = false
            if (routerFilterParams.sort === SortType.updated) {
                sortBy.push({ column: 'createdAt', value: SortByEnum.desc })
            } else if (routerFilterParams.sort === SortType.popularSite) {
                sortBy.push({ column: 'extension.views', value: SortByEnum.desc })
            } else if (routerFilterParams.sort === SortType.favorite) {
                sortBy.push({ column: 'extension.favoriteCount', value: SortByEnum.desc })
            } else if (routerFilterParams.sort === SortType.like) {
                sortBy.push({ column: 'extension.likes', value: SortByEnum.desc })
            } else if (routerFilterParams.sort === SortType.random) {
                random = true
            }

            // 隐藏免费游戏
            if (hideFreeGames) {
                conditions.push({
                    column: 'price',
                    value: 0,
                    operator: Operator.gt
                })
            }
            // 隐藏限定游戏
            if (hideVipGames) {
                conditions.push({
                    column: 'exclusiveType',
                    value: UserLevelType.permanent,
                    operator: Operator.neq
                })
            }

            // 获取列表详情
            void listWidthTotal({ limit, page, sortBy, conditions, random, keyword: routerFilterParams.keyword }).then(
                res => {
                    const data = res.data as ListResp
                    setList(data.list)
                    setTotal(data.total)
                }
            ).finally(
                () => {
                    setLoading(false)
                }
            )
        },
        200,
        'games'
    )
}

export const defCategory: DictionaryItem = {
    id: 0,
    type: ProductLabelTypes.gameClass,
    publish: 1,
    image: '',
    strVal: '',
    digitalVal: 0,
    sort: 0,
    price: 0,
    target: '',
    isDel: 0,
    createdAt: 0,
    updatedAt: 0,
    indexes: [],
    title: {
        zhSimplified: localizationDef.all.zh,
        zhTraditional: localizationDef.all.zh_tw,
        en: localizationDef.all.en
    },
    summary: {
        zhSimplified: '',
        zhTraditional: '',
        en: ''
    },
    label: '',
    pid: 0,
    children: []
}

export type CategoryMaps = Record<number, DictionaryItem>

export const ItemToListItem = (data: Item): ListItem => ({
    createdAt: data.createdAt,
    featuredImage: data.featuredImage,
    exclusiveType: data.exclusiveType,
    appExclusive: data.appExclusive,
    images: data.images,
    labelIds: data.labelIds,
    price: data.price,
    status: data.status,
    summary: data.summary,
    title: data.title,
    type: data.type,
    videoCode: data.videoCode,
    uniqueId: data.uniqueId,
    sort: data.sort,
    extension: {
        favorites: data.extension.favorites,
        likes: data.extension.likes,
        sales: data.extension.sales,
        views: data.extension.views,
        diskSpace: data.extension.diskSpace
    }
})

export interface FutureListTimelineItem {
    label: string
    item: ListItem
}

// export const futureListTimeline = (data: ListItem[]): FutureListTimelineItem[] => {
//     let start = 0
//     const list: FutureListTimelineItem[] = []
//     const section = 24 * 3600 * 3
//     for (let i = 0; i < data.length; i++) {
//         const item = data[ i ]
//         let label = ''
//         if (start <= 0) {
//             start = item.createdAt
//             label = timestampFormat(item.createdAt, dateFormat2)
//         } else {
//             if (Math.abs(item.createdAt - start) > section) {
//                 start = item.createdAt
//                 label = timestampFormat(item.createdAt, dateFormat2)
//             }
//         }
//
//         list.push({ label, item })
//     }
//
//     return list
// }

export const futureListTimeline = (data: ListItem[]): FutureListTimelineItem[] => {
    const list: FutureListTimelineItem[] = []
    for (let i = 0; i < data.length; i++) {
        const item = data[ i ]
        list.push({ label: '', item })
    }

    return list
}

export const futureListGroupByDate = (data: ListItem[]): Record<string, FutureListTimelineItem[]> => {
    const maps: Record<string, ListItem[]> = {}
    for (let i = 0; i < data.length; i++) {
        const item = data[ i ]
        const date = GetYearMonth(item.createdAt, '年', '月')
        if (isEmpty(maps[ date ])) {
            maps[ date ] = []
        }

        maps[ date ].push(item)
    }

    const records: Record<string, FutureListTimelineItem[]> = {}
    for (const key in maps) {
        records[ key ] = futureListTimeline(maps[ key ])
    }

    return records
}

export const makeShareUrl = (uniqueId: string, secret?: string): string => {
    if (secret === undefined || isEmpty(secret)) {
        return window.location.origin + productDetailRoutePath(uniqueId)
    }

    return window.location.origin + productDetailRoutePath(uniqueId, secret)
}
