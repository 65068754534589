import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { type CSSStyle } from '#repositories/types/foundation'
import { execFunc } from '#utils/functions'

interface LocationProps {
    to: string
    target?: string
    className?: string
    style?: CSSStyle
    children: React.ReactNode
    title?: string
    onClick?: () => void
}

const Location: React.FC<LocationProps> = (
    {
        to,
        target,
        className,
        children,
        style,
        title,
        onClick
    }
) => {
    const history = useHistory()
    if (to.indexOf('http') === 0) {
        return <a
            target={ target }
            href={ to }
            style={ style ?? {} }
            className={ className }
            title={ title }
            onClick={
                e => {
                    const url = history.location.pathname + history.location.search + history.location.hash
                    if (url === to) {
                        e.preventDefault()
                    }

                    execFunc(onClick, e)
                }
            }
        >{ children }</a>
    }

    return <Link
        target={ target }
        to={ to }
        style={ style ?? {} }
        className={ className }
        title={ title }
        onClick={
            e => {
                const url = history.location.pathname + history.location.search + history.location.hash
                if (url === to) {
                    e.preventDefault()
                }

                execFunc(onClick, e)
            }
        }
    >{ children }</Link>
}

export default Location
