import React from 'react'
import { Col, Row, Divider, Input, Button } from 'antd'
import useFetchState from '#repositories/models'
import { unbind } from '#repositories/apis/user'
import { ReactComponent as Message } from '#assets/svg/message.svg'
import Icon from '#components/icon'
import { MessageType, MMessage } from '#components/hint'

const Main: React.FC = () => {
    // 提交按钮加载
    const [ loading, setLoading ] = useFetchState(false)
    // code
    const [ code, setCode ] = useFetchState('')

    const submit = (): void => {
        setLoading(true)
        void unbind(code).then(
            () => {
                MMessage({
                    message: '解绑成功',
                    type: MessageType.success
                })
            }
        ).finally(
            () => {
                setTimeout(
                    () => {
                        setLoading(false)
                    },
                    1000
                )
            }
        )
    }

    return <div className="bind-container">
        <Row className="form-columns">
            <Col span="24">
                <Row className="item">
                    <Col span={ 3 } className="title">激活码: </Col>
                    <Col span={ 10 }>
                        <Input
                            allowClear
                            value={ code }
                            prefix={ <Icon tap><Message /></Icon> }
                            placeholder="请输入游戏盒子激活码"
                            onChange={
                                e => {
                                    setCode(e.target.value.trim())
                                }
                            }
                        />
                    </Col>
                </Row>
                <Divider className="divider" plain />
                <Row className="item">
                    <Col offset={ 3 } span={ 15 }>
                        <Button
                            type="primary"
                            className="submit background-button"
                            loading={ loading }
                            onClick={ submit }
                        >解绑</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}

export default Main